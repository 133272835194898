import * as THREE from "three";
import {CSS3DObject} from "three/examples/jsm/renderers/CSS3DRenderer";
import GameManager from "../game/GameManager";

export interface RangeListener {
    id?: string;
    distanceThreshold: number;
    callback: () => void;
}

class RangeDetector {
    private player: THREE.Object3D | null = null;
    private target: THREE.Object3D | null = null;
    private textMesh?: CSS3DObject;
    gameManager: GameManager;

    constructor(gameManager: GameManager) {
        this.gameManager = gameManager;
    }

    setPlayer(player: THREE.Object3D) {
        this.player = player;
    }

    addTargets(arg: THREE.Object3D) {
        this.target = arg;
    }

    update() {
        if (!this.player || !this.target) return;

        const distance = this.player!.position.distanceTo(this.target.position);
        if (distance <= 5) {
            if (!this.textMesh) {
                this.createText();
            } else {
                this.textMesh.visible = true;
                this.updateTextPosition();
            }
        } else {
            if (this.textMesh) {
                this.textMesh.visible = false;
            }
        }
    }

    createText() {
        if (this.textMesh) {
            return;
        }

        // Main div
        const div = document.createElement("div");
        div.style.position = "absolute";
        div.style.color = "white";
        div.style.fontSize = "14px";
        div.style.background = "rgba(0, 0, 0, 0.5)";
        div.style.padding = "5px";
        div.style.borderRadius = "5px";

        //Text container
        const textContainer = document.createElement("span");
        textContainer.innerHTML = "To Interact";
        div.appendChild(textContainer);

        // E key element
        const keyContainer = document.createElement("span");
        keyContainer.innerHTML = "E";
        keyContainer.style.color = "white";
        keyContainer.style.background = "#18181b";
        keyContainer.style.padding = "3px 6px";
        keyContainer.style.borderRadius = "4px";
        keyContainer.style.marginRight = "4px";
        textContainer.insertBefore(keyContainer, textContainer.firstChild);

        const object = new CSS3DObject(div);
        object.position.set(0, 0, 0);
        object.scale.set(0.02, 0.02, 0.02);
        this.gameManager.scene!.add(object);
        this.textMesh = object;
    }

    updateTextPosition() {
        if (!this.target || !this.textMesh || !this.gameManager.camera) return;

        const targetPosition = new THREE.Vector3();
        this.target.getWorldPosition(targetPosition);

        // const offsetY = 1.5;
        this.textMesh.position.copy(targetPosition);
        // this.textMesh.position.y += offsetY;

        this.textMesh.lookAt(this.gameManager.camera.position);
    }
}

export default RangeDetector;
