import styled from "styled-components";
import {flexCenter, regularFont} from "../../../../assets/style";

export const LEFT_COLUMN_WIDTH = "340px";

export const LeftColumn = styled.div`
    width: ${LEFT_COLUMN_WIDTH};
    padding-top: 20px;
    padding-left: 20px;
`;

export const FollowingRow = styled.div`
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    column-gap: 16px;
`;

export const FollowingData = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    row-gap: 8px;

    .label {
        ${regularFont("xs")};
        color: var(--theme-homepage-placeholder-color);
    }

    .value {
        ${regularFont("xs")};
        color: #fff;
        font-weight: var(--theme-font-bold);
    }
`;

export const AvatarContainer = styled.div`
    width: 100%;
    height: 569px;
    border-radius: 40px;
    background: linear-gradient(360deg, var(--theme-homepage-grey-bg-primary) 0%, rgba(39, 39, 42, 0) 100%);
    margin-bottom: 20px;
`;

export const UsernameRow = styled.div`
    ${flexCenter};
    justify-content: space-between;
    ${regularFont("l")};
    font-weight: var(--theme-font-bold);

    .plus-icon {
        width: 16px;
    }
`;
