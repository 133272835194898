import React, {useEffect, useState} from "react";
import {useNavigate} from "react-router";
import {Oval} from "react-loader-spinner";
import {toast} from "react-toastify";

import {formatNumber} from "../../../services";
import {ROUTES} from "../../../../../AppRouter";
import {addLikedGame} from "../../../../../api/updateUser";
import {useAuthorizationContext} from "../../../../../context";
import {IBasicGameInterface} from "../../../types";

import playIcon from "../../assets/playIcon.png";
import remixIcon from "../../assets/remix.png";
import {HeartIcon} from "../HeartIcon";
import {StyledStats} from "./Stats.style";

interface Props {
    selectedGameData: IBasicGameInterface | undefined;
    setSelectedGameData: React.Dispatch<React.SetStateAction<IBasicGameInterface | undefined>>;
}

export const Stats = ({selectedGameData, setSelectedGameData}: Props) => {
    const {setDbUser, handleGetLikedGames, likedGamesIds} = useAuthorizationContext();
    const navigate = useNavigate();

    const [userLikedGame, setUserLikedGame] = useState(false);
    const [loadingLike, setLoadingLike] = useState(false);

    useEffect(() => {
        if (likedGamesIds && selectedGameData) {
            const likedGameIndex = likedGamesIds.findIndex(id => id === selectedGameData.ID);
            setUserLikedGame(likedGameIndex !== -1);
        }
    }, [likedGamesIds, selectedGameData]);

    const handleUpdateLikes = async (gameId: string) => {
        setLoadingLike(true);
        const res = await addLikedGame(gameId, setDbUser, () =>
            navigate(ROUTES.LOGIN, {state: {from: location.pathname}}),
        );
        await handleGetLikedGames();
        if (!selectedGameData) {
            toast.error("Something went wrong.");
        } else {
            if (res && selectedGameData) {
                const {playCount, likes} = res;
                setSelectedGameData(prev => ({...prev!, PlayCount: playCount, Likes: likes}));
            }
        }
        setLoadingLike(false);
    };

    return (
        <StyledStats>
            <button className="data playButton reset-css">
                <img src={playIcon} alt="number of players" className="icon" />
                <span>{selectedGameData ? formatNumber(selectedGameData?.PlayCount || 0) : "..."}</span>
            </button>
            <button
                className="data likeButton reset-css"
                onClick={() => (selectedGameData ? handleUpdateLikes(selectedGameData.ID) : undefined)}>
                {loadingLike ? (
                    <Oval
                        visible
                        height="16"
                        width="16"
                        strokeWidth={4}
                        color="#0284c7"
                        secondaryColor="#333"
                        ariaLabel="oval-loading"
                        wrapperStyle={{}}
                        wrapperClass="loaderWrapper"
                    />
                ) : (
                    <HeartIcon userLikedGame={userLikedGame} />
                )}
                {userLikedGame}
                <span>{selectedGameData ? selectedGameData?.Likes || 0 : "..."}</span>
            </button>
            <button className="data reset-css" disabled>
                <img src={remixIcon} alt="remin" className="icon" />
                <span>Remix</span>
            </button>
        </StyledStats>
    );
};
