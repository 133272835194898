import styled from "styled-components";

export const StyledSection = styled.section`
    display: grid;
    grid-template-columns: minmax(0, 2fr) minmax(0, 1fr) minmax(0, 1fr);
    grid-template-rows: minmax(0, 1fr) minmax(0, 1fr);
    grid-gap: 20px;
    align-items: start;
    justify-items: start;
    width: calc(100% - 20px);
    max-height: 50vh;

    @media only screen and (max-width: 1023px) {
        grid-template-columns: minmax(0, 2fr) minmax(0, 1fr);
        grid-template-rows: auto;
    }

    @media only screen and (max-width: 767px) {
        grid-template-columns: minmax(0, 1fr);
        grid-template-rows: auto;
        gap: 0;
    }
`;

export const MainGame = styled.section`
    grid-column: 1 / 2;
    grid-row: 1 / 3;
    width: 100%;
    height: 100%;

    @media only screen and (max-width: 767px) {
        grid-column: 1;
        grid-row: 1;
    }

    .heroGameWrapper {
        width: 100%;
        height: 100%;
        .game {
            height: 100%;
        }
    }
`;
