import {getAuth, signInWithCustomToken} from "firebase/auth";
import {doc, setDoc, getDoc} from "firebase/firestore";
import {db} from "../../firebase";
import moment from "moment";
import {toast} from "react-toastify";
import {IDiscordUser} from "../../ai16z/discord/utils";

export const loginWithDiscord = async (userAccessToken: string, discordUser: IDiscordUser) => {
    try {
        const response = await fetch("/api/Discord/Login", {
            method: "POST",
            body: new URLSearchParams({
                access_token: userAccessToken,
                discord_user_id: discordUser.id,
            }),
        });

        if (!response.ok) {
            throw new Error("Failed to log in with Discord");
        }

        const data = await response.json();

        if (data.Code === 200) {
            console.log("Custom token generated successfully!");

            const customToken = data.Data.custom_token;

            const auth = getAuth();
            await signInWithCustomToken(auth, customToken);
            const authUser = auth.currentUser;
            if (!authUser) {
                toast.error("Something went wrong when logging in");
                console.log("Cannot create user, Firebase authentication data is missing");
                return;
            }

            const userDocRef = doc(db, "users", authUser.uid);
            const userDoc = await getDoc(userDocRef);

            if (userDoc.exists()) {
                console.log("User already exists in Firestore. Skipping user creation.");
            } else {
                const user = {
                    id: authUser.uid,
                    discordId: discordUser.id,
                    name: discordUser.displayName,
                    username: discordUser.username,
                    email: discordUser.email,
                    avatar: discordUser.avatarUrl,
                    memberSince: moment().unix(),
                };

                await setDoc(userDocRef, user);
                console.log("New user data saved to Firestore");
            }
        } else {
            console.error("Error logging in:", data);
        }
    } catch (error) {
        console.error("An error occurred during the login process:", error);
    }
};
