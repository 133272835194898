/*
 * Copyright 2017-2020 The ShadowEditor Authors. All rights reserved.
 *
 * Use of this source code is governed by a MIT-style
 * license that can be found in the LICENSE file.
 *
 * For more information, please visit: https://github.com/tengge1/ShadowEditor
 * You can also visit: https://gitee.com/tengge1/ShadowEditor
 */
/**
 * 场景序列化信息
 *
 */
const Metadata = {
    generator: "ShadowEditor",
    type: "Object",
    version: "0.0.1",
};

export default Metadata;
