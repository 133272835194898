import BaseBehaviorConverter from "./BaseBehaviorConverter";
import * as THREE from "three";
import {BehaviorUpdater} from "../../behaviors/BehaviorManager";
import EnemyBehaviorUpdater from "./EnemyBehaviorUpdater";
import {ENEMY_TYPES, EnemyBehaviorInterface, OBJECT_TYPES, WEAPON_TYPES} from "../../types/editor";

class EnemyBehaviorConverter extends BaseBehaviorConverter<EnemyBehaviorInterface> {
    public static DEFAULT = new EnemyBehaviorConverter(null);
    constructor(obj: any) {
        super(obj);
    }

    convert(target: THREE.Object3D, behavior: EnemyBehaviorInterface): BehaviorUpdater {
        return new EnemyBehaviorUpdater(target, behavior);
    }

    reset(): void {}

    getBehavior(target: THREE.Object3D, id: string): EnemyBehaviorInterface | null {
        if (!target) return null;

        let behavior = super.findBehavior(target, id) as EnemyBehaviorInterface;
        if (!behavior) {
            console.warn("Object doesn't have ENEMY behavior: ", target);
            return null;
        }

        return behavior;
    }

    getDefaultBehavior(id: string): EnemyBehaviorInterface {
        return {
            enabled: true,
            id,
            type: OBJECT_TYPES.ENEMY,
            enemyType: ENEMY_TYPES.AGGRESIVE,
            enemyEnabled: true,
            health: 100,
            movementSpeed: 0.04,
            attackDamage: 1,
            attackDistance: 5,
            attackSpeed: 0.06,
            respawnAmount: 1,
            roamDistance: 5,
            showRoamArea: false,
            rotationSpeed: 5,
            fightDistance: 1,
            directionDuration: 2,
            animationClipName: "",
            fallDelay: 1,
            weapon: WEAPON_TYPES.KNIFE,
            animationClips: [],
        };
    }
}

export default EnemyBehaviorConverter;
