import {collection, doc, getDocs, limit, query, setDoc} from "firebase/firestore";
import {MathUtils} from "three";
import I18n from "i18next";
import {db} from "../firebase";
import {IBasicGameInterface, IUserProfileData, SEARCH_GAME_QUERY} from "../v2/pages/types";
import {getInitProfileData} from "./helpers/mockedData";
import {backendUrlFromPath} from "../utils/UrlUtils";
import Ajax from "../utils/Ajax";
import global from "../global";
import Application from "../Application";
import {getGameUrl} from "../v2/pages/services";
import {toast} from "react-toastify";

export interface IStats {
    playCount: number;
    likes: number;
}

export const getGames = async (userID?: string) => {
    try {
        if (!global?.app) return;
        const url = userID
            ? backendUrlFromPath(`/api/Scene/Public?userID=${userID}`)
            : backendUrlFromPath(`/api/Scene/Public`);
        const response = await Ajax.get({url: url, needAuthorization: false});
        const obj = response?.data;
        if (obj.Code !== 200) {
            (global.app as Application).toast(I18n.t(obj.Msg), "warn");
            return;
        }
        const publicScenes = obj.Data;

        const communityGamesData: IBasicGameInterface[] = [];
        publicScenes.forEach((data: IBasicGameInterface) => {
            const scene = {
                ...data,
                GameURL: getGameUrl(data.ID),
                Tags: data.Tags ? JSON.parse(data.Tags as unknown as string) : [],
            } as IBasicGameInterface;
            communityGamesData.push(scene);
        });

        communityGamesData.sort(
            (a: any, b: any) => new Date(b.UpdateTime).getTime() - new Date(a.UpdateTime).getTime(),
        );

        return communityGamesData;
    } catch (error) {
        toast("Couldn't fetch games.");
        console.log(`Error from fetching games document: ${error}`);
    }
};

export interface QUERY_PARAMS {
    [SEARCH_GAME_QUERY.GAME_NAME]?: string;
    [SEARCH_GAME_QUERY.GAME_AUTHOR]?: string;
    [SEARCH_GAME_QUERY.GAME_TAGS]?: string;
    [SEARCH_GAME_QUERY.PAGE]?: string;
    [SEARCH_GAME_QUERY.LIMIT]?: string;
}
export const getGamesByQuery = async (args: QUERY_PARAMS) => {
    const {tags, name, page, limit} = args;
    try {
        if (!global?.app) return;

        // Construct query parameters
        const params: URLSearchParams = new URLSearchParams();
        if (tags) {
            params.append(SEARCH_GAME_QUERY.GAME_TAGS, tags);
        }
        if (name) {
            params.append(SEARCH_GAME_QUERY.GAME_NAME, name);
        }
        if (limit) {
            params.append(SEARCH_GAME_QUERY.LIMIT, limit);
        }
        if (page) {
            params.append(SEARCH_GAME_QUERY.PAGE, page);
        }
        // if (userID) {
        //     params.append(SEARCH_GAME_QUERY.GAME_AUTHOR, userID);
        // }

        // Create the full URL with query parameters
        const url = backendUrlFromPath(`/api/Scene/Public?${params.toString()}`);
        const response = await Ajax.get({url, needAuthorization: false});

        const obj = response?.data;
        if (obj.Code !== 200) {
            (global.app as Application).toast(I18n.t(obj.Msg), "warn");
            return;
        }
        const publicScenes = obj.Data;

        const communityGamesData: IBasicGameInterface[] = [];
        publicScenes.forEach((data: IBasicGameInterface) => {
            const scene = {
                ...data,
                GameURL: getGameUrl(data.ID),
                Tags: data.Tags ? JSON.parse(data.Tags as unknown as string) : [],
            } as IBasicGameInterface;
            communityGamesData.push(scene);
        });

        // Sort the games by the last update time
        communityGamesData.sort(
            (a: any, b: any) => new Date(b.UpdateTime).getTime() - new Date(a.UpdateTime).getTime(),
        );

        return communityGamesData;
    } catch (error) {
        console.log(`Error from fetching games document: ${error}`);
    }
};

export const getProfileData = async () => {
    try {
        const gamesCollection = collection(db, "profileData");

        const q = query(gamesCollection, limit(1));

        const querySnapshot = await getDocs(q);

        if (!querySnapshot.empty) {
            const doc = querySnapshot.docs[0];
            const data = doc.data();
            return data as IUserProfileData;
        } else {
            console.log("No documents found in the 'profileData' collection.");
            await setDoc(doc(db, "profileData", MathUtils.generateUUID()), getInitProfileData());
            return getInitProfileData();
        }
        // const docRef = doc(db, 'games', someId);
        //   const docSnap = await getDoc(docRef);
        //   console.log('docSnap',docSnap);
        //   if (docSnap.exists()) {
        //     return docSnap.data();
        //   } else {
        //     console.error('Collection not found');
        //   }
    } catch (error) {
        console.log(`Error from fetching profileData document: ${error}`);
    }
};

export const updatePlayCount = async (sceneID: string): Promise<IStats | null> => {
    try {
        if (!global?.app) return null;
        const response = await Ajax.post({
            url: backendUrlFromPath(`/api/Scene/IncrementPlayCount`),
            needAuthorization: false,
            data: {
                ID: sceneID,
            },
        });
        const obj = response?.data;
        if (obj.Code !== 200) {
            (global.app as Application).toast(I18n.t(obj.Msg), "warn");
            return null;
        }
        return {playCount: obj.Data.PlayCount, likes: obj.Data.Likes};
    } catch (error) {
        console.log(`Error from updating playCount: ${error}`);
        return null;
    }
};

export enum LIKES_ACTION {
    INCREMENT = "increment",
    DECREMENT = "decrement",
}
export const updateLikesCount = async (sceneID: string, action: LIKES_ACTION): Promise<IStats | null> => {
    try {
        if (!global?.app) return null;
        const response = await Ajax.post({
            url: backendUrlFromPath(`/api/Scene/Likes`),
            needAuthorization: false,
            data: {
                ID: sceneID,
                action,
            },
        });
        const obj = response?.data;
        if (obj.Code !== 200) {
            (global.app as Application).toast(I18n.t(obj.Msg), "warn");
            return null;
        }
        return {playCount: obj.Data.PlayCount, likes: obj.Data.Likes};
    } catch (error) {
        console.log(`Error from updating likes: ${error}`);
        return null;
    }
};
