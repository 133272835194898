import {useEffect, useState} from "react";
import {matchPath, useLocation} from "react-router-dom";

import {getGamesByQuery} from "../../../api/getGames";
import {IBasicGameInterface, SEARCH_GAME_QUERY} from "../types";
import {getQueryParams} from "../services";
import {ROUTES} from "../../../AppRouter";

import {SingleGame} from "../Home/SingleGame/SingleGame";
import {LoadMore} from "../../common/LoadMore/LoadMore";
import {SearchRow} from "../../common/SearchRow/SearchRow";
import {Header} from "../../Header/Header";
import {Footer} from "../../Footer/Footer";

import {InnerPadding, SearchContainer, TitleRow} from "./SearchResults.style";
import {RecentSearches} from "./RecentSearches/RecentSearches";
import {GamesWrapper} from "../../common/GamesWrapper/GamesWrapper.style";
import {FilterSelect} from "./FilterSelect/FilterSelect";

const RESULTS_VISIBILITY_COUNTER = 20;

export const SearchResults = () => {
    const [results, setResults] = useState<IBasicGameInterface[]>([]);
    const [initSearchValue, setInitSearchValue] = useState("");
    const [queryParams, setQueryParams] = useState<Partial<Record<SEARCH_GAME_QUERY, string>>>();

    const location = useLocation();
    const isSearchResultsMatch = matchPath(location.pathname, ROUTES.SEARCH_RESULTS);

    useEffect(() => {
        setQueryParams(getQueryParams());
    }, [location.search]);

    useEffect(() => {
        const getResults = async () => {
            if (!queryParams) return console.error("Missing query params");
            isSearchResultsMatch && setInitSearchValue(queryParams.name || "");
            const gamesResults = await getGamesByQuery(queryParams);
            if (gamesResults) {
                setResults(gamesResults);
            } else {
                setResults([]);
            }
        };
        queryParams && getResults();
    }, [queryParams]);

    return (
        <SearchContainer>
            <Header />
            <InnerPadding>
                {!isSearchResultsMatch ? (
                    <TitleRow>
                        {queryParams?.tags ? `${queryParams?.tags} Games` : "Top Games"}
                        <FilterSelect results={results} setResults={setResults} />
                    </TitleRow>
                ) : (
                    <>
                        <SearchRow initValue={initSearchValue} />
                        <RecentSearches queryParams={queryParams} results={results} setResults={setResults} />
                    </>
                )}

                <GamesWrapper>
                    {results?.length === 0 ? (
                        <div className="norResults">No games found.</div>
                    ) : (
                        results?.map(game => <SingleGame item={game} key={game.ID} />)
                    )}
                    <LoadMore
                        visibilityCounter={RESULTS_VISIBILITY_COUNTER}
                        itemsToLoad={results}
                        setVisibleResults={setResults}
                    />
                </GamesWrapper>
            </InnerPadding>
            <Footer />
        </SearchContainer>
    );
};
