import BaseSerializer from "../BaseSerializer";
import ImageUtils from "../../utils/ImageUtils";
import * as THREE from "three";

class GifTextureSerializer extends BaseSerializer {
    toJSON(obj) {
        var json = BaseSerializer.prototype.toJSON.call(this, obj);

        json.anisotropy = obj.anisotropy;
        json.center = obj.center;
        json.encoding = obj.encoding;
        json.flipY = obj.flipY;
        json.format = obj.format;
        json.generateMipmaps = obj.generateMipmaps;
        json.gifUrl = obj.gif.url;
        json.magFilter = obj.magFilter;
        json.mapping = obj.mapping;
        json.matrixAutoUpdate = obj.matrixAutoUpdate;
        json.minFilter = obj.minFilter;
        json.mipmaps = obj.mipmaps;
        json.name = obj.name;
        json.offset = obj.offset;
        json.premultiplyAlpha = obj.premultiplyAlpha;
        json.repeat = obj.repeat;
        json.rotation = obj.rotation;
        json.type = obj.type;
        json.unpackAlignment = obj.unpackAlignment;
        json.uuid = obj.uuid;
        json.version = obj.version;
        json.wrapS = obj.wrapS;
        json.wrapT = obj.wrapT;
        json.isTexture = obj.isTexture;
        json.needsUpdate = obj.needsUpdate;

        return json;
    }

    fromJSON(json, parent, server) {
        var img = ImageUtils.onePixelCanvas();
        var obj = parent === undefined ? new THREE.Texture(img) : parent;

        obj.anisotropy = json.anisotropy;
        //obj.center.copy(json.center); //MISHA
        obj.encoding = json.encoding;
        obj.flipY = json.flipY;
        obj.format = json.format;
        obj.generateMipmaps = json.generateMipmaps;
        img = document.createElement("img");

        if (!json.gifUrl.startsWith("blob:http://")) {
            if (json.gifUrl && json.gifUrl.startsWith("/")) {
                img.src = server + json.gifUrl;
            } else {
                img.src = json.gifUrl;
            }
        }

        obj.image = img;
        obj.needsUpdate = true;
        img.onload = function () {
            obj.image = img;
            obj.needsUpdate = true;
        };

        obj.magFilter = json.magFilter;
        obj.mapping = json.mapping;
        obj.matrixAutoUpdate = json.matrixAutoUpdate;
        obj.minFilter = json.minFilter;
        obj.mipmaps = json.mipmaps;
        obj.name = json.name;
        obj.offset.copy(json.offset);
        obj.premultiplyAlpha = json.premultiplyAlpha;
        obj.repeat.copy(json.repeat);
        obj.rotation = json.rotation;
        obj.type = json.type;
        obj.unpackAlignment = json.unpackAlignment;
        obj.uuid = json.uuid;
        obj.version = json.version;
        obj.wrapS = json.wrapS;
        obj.wrapT = json.wrapT;
        obj.isTexture = json.isTexture;
        obj.gifUrl = json.gifUrl;
        obj.needsUpdate = true;

        return obj;
    }
}

export default GifTextureSerializer;
