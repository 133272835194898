import * as THREE from "three";
import { Object3D } from "three";
import GameManager from "../../behaviors/game/GameManager";
import { COLLISION_TYPE, OBJECT_TYPES, WEAPON_TYPES, WEAPON_EFFECTS, WEAPON_AIMERS, CHARACTER_TYPES } from "../../types/editor";
import global from "../../global";
import { IPhysics } from "src/physics/common/types";

enum GAME_STATE {
    NOT_STARTED = 0,
    STARTED,
    FINISHED,
    PAUSED,
}

class WeaponBehaviorUpdater {
    target: Object3D;
    removed = false;
    gunAimerImageBase64: string | null = null;
    game?: GameManager;
    weaponHUDAimerSize: string | null = null;
    imageVisibilityToken: string | null = null;
    weaponShowHUDAimerInGame: boolean | null = null;
    endingSession = false;
    weaponHUDAimerImageName: string | null = null;
    weaponAimerImage: HTMLImageElement | null = null;
    weaponType: WEAPON_TYPES;
    usingPhysics = false;
    isDynamic = false;
    physics?: IPhysics;
    playerCollisionListenerId: string | undefined;
    private collisionCooldown: boolean = false;
    private weaponThumbnail: HTMLImageElement | null = null;
    private img: HTMLImageElement | null = null;

    constructor(target: Object3D, weaponType: WEAPON_TYPES) {
        this.target = target;
        this.target = target;
        this.weaponType = weaponType;
        this.usingPhysics =
            this.target.userData.physics && this.target.userData.physics.body;

        if (global && global.app) {
            global.app.on("removeGunAimer", this.removeGunAimer.bind(this));
        }
    }

    init(gameManager: GameManager) {
        this.game = gameManager;
        this.physics = this.game?.behaviorManager?.collisionDetector.physics;
      
        this.target.traverse((child: Object3D) => {
            child.userData.disableCameraCollision = true;
        });
        this.addCollisionListener();

        const weaponObjects: { object: Object3D; weaponBehavior: any }[] = [];
        this.game?.scene?.traverse((object: Object3D) => {
            if (object.userData && object.userData.behaviors) {
                const weaponBehavior = object.userData.behaviors.find(
                    (behavior: any) => behavior.type === OBJECT_TYPES.WEAPON
                );
                if (weaponBehavior) {
                    weaponObjects.push({ object, weaponBehavior });
                }
            }
        });

        let weaponInfoContainer = document.getElementById('weapon-container') as HTMLDivElement | null;
        if (!weaponInfoContainer) {
            weaponInfoContainer = document.createElement('div');
            weaponInfoContainer.id = 'weapon-container';
            weaponInfoContainer.style.position = 'fixed';
            weaponInfoContainer.style.bottom = '10px';
            weaponInfoContainer.style.left = '50%';
            weaponInfoContainer.style.transform = 'translateX(-50%)';
            weaponInfoContainer.style.display = 'flex';
            weaponInfoContainer.style.gap = '10px';
            weaponInfoContainer.style.zIndex = '1000';
            document.body.appendChild(weaponInfoContainer);
        }

        weaponObjects.forEach(({ object, weaponBehavior }) => {
            const weaponId = `weapon-${object.uuid}`;
            let weaponDiv = document.getElementById(weaponId) as HTMLDivElement | null;

            if (!weaponDiv) {
                weaponDiv = document.createElement('div');
                weaponDiv.id = weaponId;
                weaponDiv.style.width = '5vw';
                weaponDiv.style.height = '5vw';
                weaponDiv.style.backgroundImage = `url(${weaponBehavior.weaponHUDImage})`;
                weaponDiv.style.backgroundSize = 'cover';
                weaponDiv.style.border = '2px solid white';
                weaponDiv.style.borderRadius = '5px';
                weaponDiv.title = weaponBehavior.weaponType;
                weaponInfoContainer!.appendChild(weaponDiv);
            }
        });
    }


    addCollisionListener() {
        this.playerCollisionListenerId =
            this.game!.behaviorManager?.collisionDetector.addListener(
                this.target,
                {
                    type: COLLISION_TYPE.WITH_PLAYER,
                    callback: this.onCollisionWithPlayer.bind(this),
                    useBoundingBoxes: false,
                },
                this.isDynamic
            );

    }

    onCollisionWithPlayer() {
        if (!this.game || !this.game.player || !this.game.scene || this.game.state !== GAME_STATE.STARTED) return;

        if (this.collisionCooldown && this.target.userData.weaponDropped && !this.target.userData.allowPickUp) return;

        if (this.target.userData && this.target.userData.behaviors) {
            const weaponBehavior = this.target.userData.behaviors.find(
                (behavior: any) => behavior.type === OBJECT_TYPES.WEAPON
            );

            if (weaponBehavior && !weaponBehavior.weaponStarting) {

                weaponBehavior.weaponStarting = true;
                this.game!.behaviorManager!.collisionDetector.deleteListener(this.target)
                this.weaponHUDAimerImageName = weaponBehavior.weaponHUDAimerImageName;
                this.weaponShowHUDAimerInGame = weaponBehavior.weaponShowHUDAimerInGame;
                this.gunAimerImageBase64 = weaponBehavior.weaponHUDAimerImage;
                this.weaponHUDAimerSize = weaponBehavior.weaponHUDAimerSize;
                if (weaponBehavior.weaponShowHUDAimerInGame) {
                    let imgId = this.weaponHUDAimerImageName + '_' + this.target.name;
                    let existingImg = document.getElementById(imgId) as HTMLImageElement;
                    this.target.userData.aimerID = imgId;
                    if (!existingImg) {
                        this.removeGunAimer();
                        this.img = document.createElement("img");
                        this.img.id = imgId;
                        this.img.style.position = "fixed";
                        this.img.style.top = "50%";
                        this.img.style.left = "50%";
                        this.img.style.transform = "translate(-50%, -50%)";
                        this.img.style.zIndex = WEAPON_AIMERS.AIMER_SCREEN_ZINDEX.toString();;
                        this.img.src = this.gunAimerImageBase64!;
                        this.img.style.minWidth = this.weaponHUDAimerSize + "px";
                        this.img.style.minHeight = this.weaponHUDAimerSize + "px";
                        this.img.style.maxWidth = this.weaponHUDAimerSize + "px";
                        this.img.style.maxHeight = this.weaponHUDAimerSize + "px";
                        this.target.userData.scropeZoomAimerSize = this.img.style.minWidth;
                        document.body.appendChild(this.img);
                    }
                }

                this.collisionCooldown = true;
                this.game.scene!.traverse(object => {
                    if (object.userData && object.userData.behaviors) {
                        const otherWeaponBehavior = object.userData.behaviors.find(
                            (behavior: any) => behavior.type === OBJECT_TYPES.WEAPON
                        );
                        if (!this.target && otherWeaponBehavior && otherWeaponBehavior.starting_weapon) {
                            otherWeaponBehavior.starting_weapon = false;
                        }
                    }
                });

                if (!this.target.userData.fpsEnabled) {
                    setTimeout(() => {
                        this.collisionCooldown = false;
                        this.addCollisionListener();
                    }, 4000);
                } else {
                    this.collisionCooldown = false;
                    this.addCollisionListener();
                }
            }
        }
    }


    update(clock: THREE.Clock, delta: number) {
       
    }


    reset() {
        if (this.removed) {
            this.removed = false;
            this.addCollisionListener();
        }
    }

    renderImage() {
        let img: HTMLImageElement | null = this.weaponAimerImage;

        if (!img) {
            img = document.createElement("img");
            img.id = this.weaponHUDAimerImageName as string;
            img.style.position = "fixed";
            img.style.top = "50%";
            img.style.left = "50%";
            img.style.transform = "translate(-50%, -50%)";
            img.style.zIndex = WEAPON_AIMERS.AIMER_SCREEN_ZINDEX.toString();
            document.body.appendChild(img);
        }

        img.src = this.gunAimerImageBase64!;
        img.style.minWidth = this.weaponHUDAimerSize + "px";
        img.style.minHeight = this.weaponHUDAimerSize + "px";
        img.style.maxWidth = this.weaponHUDAimerSize + "px";
        img.style.maxHeight = this.weaponHUDAimerSize + "px";
        img.style.display = "none";
    }

    toggleImageVisibility(visible: boolean) {
        if (this.weaponHUDAimerImageName) {
            this.weaponAimerImage = document.getElementById(
                this.weaponHUDAimerImageName,
            ) as HTMLImageElement;
        }
        if (this.weaponAimerImage) {
            this.weaponAimerImage.style.display = visible ? "block" : "none";
        }
    }

    removeGunAimer() {

        const images = document.querySelectorAll('img');
        images.forEach((img) => {
            if (img.id && img.id.includes(WEAPON_EFFECTS.WEAPON_HUD_AIMER_IMG_NAME)) {
                img.remove();
            }
        });

        // Remove labels with the ID 'weapon-state--label'
        const labels = document.querySelectorAll('div#weapon-state--label');
        labels.forEach((label) => {
            if (label instanceof HTMLDivElement) {
                label.remove();
            }
        });

    }
}

export default WeaponBehaviorUpdater;
