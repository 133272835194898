import * as THREE from "three";
import {TriggerBehaviorInterface, PropAnimationBehaviorInterface} from "../../types/editor";
import {BehaviorUpdater} from "../../behaviors/BehaviorManager";
import GameManager from "../../behaviors/game/GameManager";
import {OBJECT_TYPES, COLLISION_TYPE, PHYSICS_PROXY_UI} from "../../types/editor";
import RangeDetector from "../../behaviors/range/RangeDetector";


class TriggerBehaviorUpdater implements BehaviorUpdater {
    target: THREE.Object3D;
    behavior: TriggerBehaviorInterface;
    game?: GameManager;
    rangeDetector: RangeDetector;
    physicsEnabled = false;
    keyPressed = false;
    mixer: THREE.AnimationMixer | null = null;
    animationPausedAtStart = false;
    animationAction: THREE.AnimationAction | null = null;
    autoInteractPlayer: boolean = false;
    triggerAnimation: boolean = false;
    triggerPropAnimationName: string | null = null;
    animationPlaying: boolean = false;
    playerColliding: boolean = false;
    physicsRemoved: boolean = false;
    removed: boolean = false;
    triggerDelay: number = 0;
    addedCollider: boolean = false;
    triggerAssociated3DObject: THREE.Object3D | undefined;
    triggerObjectTouched: boolean = false;
    triggerTouchedActivateObject: THREE.Object3D | undefined;
   
    constructor(target: THREE.Object3D, behavior: TriggerBehaviorInterface) {
        this.target = target;
        this.behavior = behavior;
        this.physicsEnabled = this.target.userData.physics && this.target.userData.physics.enabled;
        this.rangeDetector = null as unknown as RangeDetector;
        this.triggerDelay = 0;
    }

    init(gameManager: GameManager) {

        this.game = gameManager;

        if (gameManager.scene) {
            this.rangeDetector = new RangeDetector(gameManager);
        } 

        if (this.target) {
            this.playAnimation();
        }
        this.addRangeDetector();
        this.addCollisionListener();
        this.addKeyListeners();

        this.game!.player!.userData.velocityLocked = false;
    }

    update(clock: THREE.Clock, delta: number) {
        if (!this.game || !this.game.scene || !this.target ) return;

        this.mixer?.update(delta);
        this.rangeDetector.update();
        this.triggerAssociated3DObject = undefined;

        if (this.target.userData && this.target.userData.behaviors) {
            const triggerBehavior = this.target.userData.behaviors.find(
                (behavior: any) => behavior.type === OBJECT_TYPES.TRIGGER,
            ) as TriggerBehaviorInterface;

            //handle player touches and pressE behavior options
            if (this.target.userData.behaviors.find((object: any) => object.type === OBJECT_TYPES.TRIGGER && object.player_touches || OBJECT_TYPES.TRIGGER && object.pressE)) {
                if (triggerBehavior && this.triggerDelay >= 0) {
                    this.triggerDelay = triggerBehavior.delay;
                    const behaviorObjectName = triggerBehavior.then_object || triggerBehavior.else_object;
                    if (behaviorObjectName) {
                        //get the animation clip from the saved associated object if available.
                        this.triggerAssociated3DObject = this.game.scene.getObjectByName(behaviorObjectName) as THREE.Object3D;
                        if (this.triggerAssociated3DObject) {
                            const animationBehavior = this.triggerAssociated3DObject?.userData?.behaviors.find(
                                (behavior: any) => behavior.type === OBJECT_TYPES.PROP_ANIMATION,
                            ) as PropAnimationBehaviorInterface;

                            if (animationBehavior && animationBehavior.startOnTrigger && animationBehavior.propAnimation) {
                                this.triggerAnimation = animationBehavior.startOnTrigger;
                                this.triggerPropAnimationName = animationBehavior.propAnimation;
                            }
                        }
                    }
                }
            }

            //handle if object touches behavior options
            if (this.target.userData.behaviors.find((object: any) => object.type === OBJECT_TYPES.TRIGGER && object.object_touches)) {
                const triggerBehavior = this.target.userData.behaviors.find(
                    (object: any) => object.type === OBJECT_TYPES.TRIGGER && object.object_touches
                );

                if (triggerBehavior) {
                    const behaviorObjectName = triggerBehavior.then_object || triggerBehavior.else_object;
                    this.triggerTouchedActivateObject = this.game.scene.getObjectByName(behaviorObjectName) as THREE.Object3D;
                    if (this.triggerTouchedActivateObject) {
                        const triggerBoundingBox = new THREE.Box3().setFromObject(this.triggerTouchedActivateObject);
                        const targetBoundingBox = new THREE.Box3().setFromObject(this.target);
                        if (triggerBoundingBox.intersectsBox(targetBoundingBox)) {
                            this.triggerObjectTouched = true;
                        }
                    }
                }
            }

            //find any animation clip from the trigger object prop animation if available
            const animationBehavior = this.target.userData.behaviors.find(
                (behavior: any) => behavior.type === OBJECT_TYPES.PROP_ANIMATION,
            ) as PropAnimationBehaviorInterface;

            if (animationBehavior && animationBehavior.startOnTrigger && animationBehavior.propAnimation) {
                this.triggerAnimation = animationBehavior.startOnTrigger;
                this.triggerPropAnimationName = animationBehavior.propAnimation;
            }


        }

        this.autoInteractPlayer = this.playerColliding;

        if (!this.addedCollider && !this.triggerAnimation) {
            this.addCollisionAndPhysics();
            this.addedCollider = true;
        }

    }

    reset() {
        this.removeKeyListeners();
    }

    addRangeDetector() {
        if (this.target.userData.behaviors.find((el: any) => el.type === OBJECT_TYPES.TRIGGER && el.pressE)) {
            this.rangeDetector.setPlayer(this.game!.player!);
            this.rangeDetector.addTargets(this.target);
        }
    }

    addCollisionListener() {
        this.game!.behaviorManager?.collisionDetector.addListener(
            this.target,
            {
                type: COLLISION_TYPE.WITH_PLAYER,
                callback: this.onCollisionWithPlayer.bind(this),
                useBoundingBoxes: false,
                distanceThreshold: 10.0, 
            },
            this.target.userData.physics && this.target.userData.physics.enabled,
        );
    }

    addKeyListeners() {
        document.addEventListener("keydown", this.handleKeyDown.bind(this));
        document.addEventListener("keyup", this.handleKeyUp.bind(this));
    }

    removeKeyListeners() {
        document.removeEventListener("keydown", this.handleKeyDown.bind(this));
        document.removeEventListener("keyup", this.handleKeyUp.bind(this));
    }

    handleKeyDown(event: KeyboardEvent) {
        if (event.code === "KeyE" && !this.keyPressed) {
            this.keyPressed = true;
            if (this.playerColliding && !this.animationPlaying ) {
                this.executeTriggerActions();
            }
        }
    }

    handleKeyUp(event: KeyboardEvent) {
        if (event.code === "KeyE") {
            this.keyPressed = false;
        }
    }

    executeTriggerActions() {

        if (!this.target) { return }

        //This will be re-factored to states after concept proof

        //Use press E when player touches trigger object
        if (this.triggerAnimation && this.behavior.pressE && this.keyPressed) {
            this.setTriggerTimer(false);
        }

        //Player auto interact with object no press E needed
        if (this.triggerAnimation && !this.triggerTouchedActivateObject && this.playerColliding && !this.behavior.pressE && !this.keyPressed && !this.autoInteractPlayer) {
            this.setTriggerTimer(true);
         }

        //handle switch object only
        if (this.triggerAssociated3DObject && this.behavior.pressE && this.keyPressed) {
            this.setTriggerTimer(true);
        }

        //handle trigger object touch only
        if (this.triggerAnimation && this.triggerObjectTouched ) {
            this.setTriggerTimer(true);
        }
     
    }

    setTriggerTimer(autoInteractPlayer: boolean){
        setTimeout(() => {
            this.resetMixer();
            this.playAnimation();
            this.autoInteractPlayer = autoInteractPlayer;
        }, this.triggerDelay * 1000);
    }

    playAnimation() {
      
        const targetObject = this.triggerAssociated3DObject || this.target;
        const animations = targetObject?._obj?.animations;

        if (animations && animations.length > 0) {
            if (!this.mixer) {
                this.mixer = new THREE.AnimationMixer(targetObject);
            }

            const targetClip = animations.find(
                (clip: any) => clip.name === this.triggerPropAnimationName
            );

            if (!targetClip) {
                console.warn(`Animation "${this.triggerPropAnimationName}" not found.`);
                return;
            }

            this.animationAction = this.mixer.clipAction(targetClip);

            this.animationAction.loop = THREE.LoopOnce;
            this.animationAction.clampWhenFinished = true;
            this.animationPlaying = true;

            if (this.playerColliding) {
                this.removeCollisionAndPhysics();
                this.addedCollider = false;
            }

            this.animationAction.play();

            this.mixer!.addEventListener("finished", (event: any) => {
                if (event.action === this.animationAction) {
                    if (!this.addedCollider && !this.animationPlaying && !this.autoInteractPlayer) {
                        this.addCollisionAndPhysics();
                        this.addedCollider = true;
                    }
                    this.mixer!.removeEventListener("finished", this);
                    this.animationPlaying = false;
                }
            });
        } 
    }


    private addCollisionAndPhysics() {
        const targetObject = this.triggerAssociated3DObject || this.target;
        this.game!.app.addPhysicsObjectBody(targetObject);
        const overlay = document.getElementById(PHYSICS_PROXY_UI.PHYSICS_MESSAGE_ELEMENT); 
        if (overlay) {
            overlay.style.display = "none";
        }
    }

    private removeCollisionAndPhysics() {
        const targetObject = this.triggerAssociated3DObject || this.target;
        this.game!.app.removePhysicsObjectBody(targetObject);
    }

    private removeCollisionListener() {
        const targetObject = this.triggerAssociated3DObject || this.target;
        this.game!.behaviorManager!.collisionDetector.deleteListener(targetObject);
    }
  
    resetMixer(): void {
        if (this.mixer) {
            const actions = (this.mixer as THREE.AnimationMixer)._actions;
            actions.forEach((action: THREE.AnimationAction) => {
                action.stop();
                action.reset();
            });
            this.animationAction = null;
            this.mixer.time = 0;
        }
    }

    onCollisionWithPlayer() {
        this.playerColliding = true;
        setTimeout(() => (this.playerColliding = this.triggerObjectTouched = false), 500);
        this.removeKeyListeners();
        this.executeTriggerActions();
    }

}

export default TriggerBehaviorUpdater;
