import {Document, JSONDocument, WebIO} from "@gltf-transform/core";
import {ALL_EXTENSIONS, EXTMeshoptCompression} from "@gltf-transform/extensions";
import {
    dedup,
    flatten,
    join,
    prune,
    resample,
    simplify,
    textureCompress,
    unpartition,
    meshopt,
} from "@gltf-transform/functions";
import {MeshoptEncoder, MeshoptDecoder, MeshoptSimplifier} from "meshoptimizer";

const compressModel = async (data: ArrayBuffer | JSONDocument, isJSON: boolean, onError?: () => void) => {
    let compressedData = data;
    try {
        await MeshoptEncoder.ready;
        await MeshoptDecoder.ready;
        const io = new WebIO().registerExtensions(ALL_EXTENSIONS).registerDependencies({
            "meshopt.decoder": MeshoptDecoder,
            "meshopt.encoder": MeshoptEncoder,
        });

        let doc: Document;

        if (isJSON) {
            doc = await io.readJSON(data as JSONDocument);
        } else {
            const buf = new Uint8Array(data as ArrayBuffer);
            doc = await io.readBinary(buf);
        }

        doc.createExtension(EXTMeshoptCompression)
            .setRequired(true)
            .setEncoderOptions({method: EXTMeshoptCompression.EncoderMethod.FILTER});

        await doc.transform(
            resample(),
            unpartition(),
            dedup(),
            prune(),
            flatten(),
            join(),
            meshopt({encoder: MeshoptEncoder, level: "high"}),
            textureCompress({
                targetFormat: "webp",
                resize: [1024, 2024],
            }),
            backfaceCulling({cull: true}),
        );

        if (isJSON) {
            compressedData = await io.writeJSON(doc);
        } else {
            compressedData = await io.writeBinary(doc);
        }

        for (const material of doc.getRoot().listMaterials()) {
            material.dispose();
        }
        for (const mesh of doc.getRoot().listMeshes()) {
            mesh.dispose();
        }
    } catch (error) {
        console.error(error);
        onError && onError();
    }

    return compressedData;
};

const simplifyModel = async (data: ArrayBuffer | JSONDocument, isJSON: boolean, onError?: () => void) => {
    let simlifiedData = data;
    try {
        const io = new WebIO().registerExtensions(ALL_EXTENSIONS);

        let doc: Document;

        if (isJSON) {
            doc = await io.readJSON(data as JSONDocument);
        } else {
            const buf = new Uint8Array(data as ArrayBuffer);
            doc = await io.readBinary(buf);
        }

        await doc.transform(
            unpartition(),
            dedup(),
            prune(),
            flatten(),
            join(),
            simplify({simplifier: MeshoptSimplifier, ratio: 0.75, error: 0.001}),
        );

        if (isJSON) {
            simlifiedData = await io.writeJSON(doc);
        } else {
            simlifiedData = await io.writeBinary(doc);
        }

        for (const material of doc.getRoot().listMaterials()) {
            material.dispose();
        }
        for (const mesh of doc.getRoot().listMeshes()) {
            mesh.dispose();
        }
    } catch (error) {
        console.error(error);
        onError && onError();
    }

    return simlifiedData;
};

function backfaceCulling(options: any) {
    return (document: any) => {
        for (const material of document.getRoot().listMaterials()) {
            material.setDoubleSided(!options.cull);
        }
    };
}

export const ModelUtils = {
    compressModel,
    simplifyModel,
};
