/*
 * Copyright 2017-2020 The ShadowEditor Authors. All rights reserved.
 *
 * Use of this source code is governed by a MIT-style
 * license that can be found in the LICENSE file.
 *
 * For more information, please visit: https://github.com/tengge1/ShadowEditor
 * You can also visit: https://gitee.com/tengge1/ShadowEditor
 */
import BaseLoader from "./BaseLoader";
import {GLTFLoader} from "three/addons/loaders/GLTFLoader.js";
import {VRMLoaderPlugin, VRMUtils} from "@pixiv/three-vrm";
import global from "../../../global";
import * as THREE from "three";
import {MeshoptDecoder} from "meshoptimizer";
/**
 * VRMLoader
 *
 */
class VRMLoader extends BaseLoader {
    constructor() {
        super();
    }

    load(url, options) {
        // eslint-disable-line
        return new Promise(resolve => {
            this.require(["GLTFLoader"]).then(() => {
                const loader = new GLTFLoader();
                loader.register(parser => {
                    return new VRMLoaderPlugin(parser);
                });
                loader.setMeshoptDecoder(MeshoptDecoder);
                loader.load(
                    url,
                    gltf => {
                        var material;
                        const vrm = gltf.userData.vrm;
                        VRMUtils.removeUnnecessaryVertices(gltf.scene);
                        // VRMLoader doesn't support VRM Unlit extension yet so
                        // converting all materials to MeshBasicMaterial here as workaround so far.
                        vrm.scene.traverse(function (object) {
                            object.frustumCulled = false;
                            if (object.material) {
                                if (Array.isArray(object.material)) {
                                    for (var i = 0, il = object.material.length; i < il; i++) {
                                        material = new THREE.MeshBasicMaterial();
                                        THREE.Material.prototype.copy.call(material, object.material[i]);
                                        material.color.copy(object.material[i].color);
                                        material.map = object.material[i].map;
                                        material.lights = false;
                                        material.skinning = object.material[i].skinning;
                                        material.morphTargets = object.material[i].morphTargets;
                                        material.morphNormals = object.material[i].morphNormals;
                                        object.material[i] = material;
                                    }
                                } else {
                                    material = new THREE.MeshBasicMaterial();
                                    THREE.Material.prototype.copy.call(material, object.material);
                                    material.color.copy(object.material.color);
                                    material.map = object.material.map;
                                    material.lights = false;
                                    material.skinning = object.material.skinning;
                                    material.morphTargets = object.material.morphTargets;
                                    material.morphNormals = object.material.morphNormals;
                                    object.material = material;
                                }
                            }
                        });

                        vrm.scene._obj = gltf;
                        vrm.scene._root = vrm.scene;
                        global.app?.vrmExpressionControl?.registerModel(vrm);

                        resolve(vrm.scene);
                    },
                    undefined,
                    e => {
                        console.log(e);
                        resolve(null);
                    },
                );
            });
        });
    }
}

export default VRMLoader;
