import {BILLBOARD_TYPES, BillboardBehaviorInterface} from "../../types/editor";
import {BehaviorUpdater} from "../../behaviors/BehaviorManager";
import GameManager from "../../behaviors/game/GameManager";
import * as THREE from "three";
import {CSS3DObject} from "three/examples/jsm/renderers/CSS3DRenderer";
import {css} from "styled-components";

class BillboardBehaviorUpdater implements BehaviorUpdater {
    target: THREE.Object3D;
    behavior: BillboardBehaviorInterface;
    game?: GameManager;
    raycaster: THREE.Raycaster = new THREE.Raycaster();
    v1: THREE.Vector3 = new THREE.Vector3();
    v2: THREE.Vector3 = new THREE.Vector3();
    v3: THREE.Vector3 = new THREE.Vector3();

    constructor(target: THREE.Object3D, behavior: BillboardBehaviorInterface) {
        this.target = target;
        this.behavior = behavior;
    }

    init(gameManager: GameManager) {
        this.game = gameManager;
    }

    update(clock: THREE.Clock, delta: number): void {
        if (!this.game?.player || !this.game?.camera || !this.game?.scene) {
            return;
        }

        if (this.behavior.faceCamera) {
            this.target.lookAt(this.game.player.position);
        }

        if (
            this.behavior.billboardMode === BILLBOARD_TYPES.WEB ||
            this.behavior.billboardMode === BILLBOARD_TYPES.YT_VIDEO
        ) {
            if (!this.isObjectBehindCamera(this.target, this.game.camera)) {
                const isVisible = this.isObjectVisible(
                    this.target,
                    this.game.camera,
                    this.raycaster,
                    this.game.scene?.children,
                );

                const cssObject = this.target.children[0] as CSS3DObject;
                if (cssObject) {
                    if (isVisible) {
                        this.target.visible = true;
                        cssObject.element.style.display = "block";
                    } else {
                        this.target.visible = false;
                        cssObject.element.style.display = "none";
                    }
                }
            }
        }
    }

    isObjectBehindCamera(el: THREE.Object3D, camera: THREE.Camera) {
        const objectPos = this.v1.setFromMatrixPosition(el.matrixWorld);
        const cameraPos = this.v2.setFromMatrixPosition(camera.matrixWorld);
        const deltaCamObj = objectPos.sub(cameraPos);
        const camDir = camera.getWorldDirection(this.v3);
        return deltaCamObj.angleTo(camDir) > Math.PI / 2;
    }

    isObjectVisible(el: THREE.Object3D, camera: THREE.Camera, raycaster: THREE.Raycaster, occlude: THREE.Object3D[]) {
        const elPos = this.v1.setFromMatrixPosition(el.matrixWorld);
        const screenPos = elPos.clone();
        screenPos.project(camera);
        //@ts-ignore
        raycaster.setFromCamera(screenPos, camera);
        const intersects = raycaster.intersectObjects(occlude, true);
        if (intersects.length) {
            const intersectionDistance = intersects[0].distance;
            const pointDistance = elPos.distanceTo(raycaster.ray.origin);
            return pointDistance < intersectionDistance;
        }
        return true;
    }

    reset() {}
}

export default BillboardBehaviorUpdater;
