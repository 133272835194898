import * as THREE from "three";
import {Box3, Mesh, Object3D} from "three";

export type BoxShape = {
    width: number;
    height: number;
    length: number;
};

export type CapsuleShape = {
    radius: number;
    height: number;
};

export default class BoundingBoxUtil {
    /**
     * Computes the bounding box of an Object3D with its children.
     *
     * @param object - The 3D object for which the bounding box is to be computed.
     * @returns An object containing the width, height, and length of the bounding box.
     */
    public static getBox(object: Object3D): BoxShape {
        const box = new Box3().setFromObject(object);
        const size = box.getSize(new THREE.Vector3());
        return {width: size.x, height: size.y, length: size.z} as BoxShape;
    }

    /**
     * Computes the bounding box of an Object3D and its children without considering its transformations.
     * Temporarily resets the object's rotation and scale to their default values,
     * calculates the bounding box, and then restores the original transformations.
     *
     * @param object - The Object3D instance for which to compute the bounding box.
     * @returns The bounding box of the object as a BoxShape.
     */
    public static getBoxWithoutTransform(object: Object3D): BoxShape {
        const prevRotation = object.rotation.clone();
        const prevScale = object.scale.clone();

        object.rotation.set(0, 0, 0);
        object.scale.set(1, 1, 1);

        object.updateMatrixWorld(true);

        const box = this.getBox(object);

        object.rotation.copy(prevRotation);
        object.scale.copy(prevScale);

        return box;
    }

    /**
     * Calculates and returns the radius of the bounding sphere of an Object3D with its children.
     *
     * @param object - The Object3D for which to calculate the radius.
     * @returns The radius of the Object3D.
     */
    public static getRadius(object: Object3D) {
        let geometry = (object as Mesh).geometry;
        geometry.computeBoundingSphere();
        return geometry.boundingSphere?.radius || 0;
    }

    /**
     * Calculates the radius of an Object3D object  with its children without considering its current scale transformation.
     * Temporarily resets the scale of the object to (1, 1, 1), computes the radius, and then restores the original scale.
     *
     * @param object - The Object3D for which to calculate the radius.
     * @returns The radius of the Object3D without scale transformation.
     */
    public static getRadiusWithoutTransform(object: Mesh) {
        const prevScale = object.scale.clone();
        object.scale.set(1, 1, 1);

        object.updateMatrixWorld(true);

        const radius = this.getRadius(object);

        object.scale.copy(prevScale);

        return radius;
    }

    /**
     * Calculates the capsule shape parameters for a given Object3D with its children.
     *
     * @param object - The Object3D from which to get the capsule representation.
     * @returns The capsule representation of the object.
     */
    public static getCapsule(object: Object3D) {
        const radius = this.getRadius(object);
        const box = this.getBox(object);
        return {radius, height: box.height - 2 * radius} as CapsuleShape;
    }

    /**
     * Retrieves a capsule representation of Object3D with its children without applying its current transformation.
     * Temporarily resets the object's scale to (1, 1, 1) to get the capsule in its original dimensions,
     * then restores the object's original scale.
     *
     * @param object - The Object3D from which to get the capsule representation.
     * @returns The capsule representation of the object.
     */
    public static getCapsuleWithoutTransform(object: Object3D) {
        const prevScale = object.scale.clone();
        object.scale.set(1, 1, 1);

        object.updateMatrixWorld(true);

        const capsule = this.getCapsule(object);

        object.scale.copy(prevScale);

        return capsule;
    }
}
