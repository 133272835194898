export class DetectDevice {
    static isMobile(): boolean {
        const mobilePattern = /Mobi|Android|iPhone|iPad|iPod|Windows Phone/i;
        const isMobileUserAgent = mobilePattern.test(navigator.userAgent);
        const isTouchDevice = 'ontouchstart' in window || navigator.maxTouchPoints > 0;
        const isSmallScreen = window.innerWidth <= 800 && window.innerHeight <= 900;
        return (isMobileUserAgent || isTouchDevice) && isSmallScreen;
    }

    static isDesktop(): boolean {
        return !this.isMobile();
    }

    static getDeviceType(): string {
        return this.isMobile() ? "Mobile" : "Desktop";
    }

    static getOS(): string {
        const userAgent = navigator.userAgent;

        if (/Windows NT/i.test(userAgent)) return "Windows";
        if (/Mac OS X/i.test(userAgent)) return "macOS";
        if (/Linux/i.test(userAgent)) return "Linux";
        if (/Android/i.test(userAgent)) return "Android";
        if (/iPhone|iPad|iPod/i.test(userAgent)) return "iOS";

        return "Unknown OS";
    }

    static getDeviceInfo(): string {
        return `${this.getDeviceType()} - ${this.getOS()}`;
    }
}
