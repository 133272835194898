import {useNavigate} from "react-router-dom";

import {formatNumber, truncateName} from "../../services";
import {ROUTES} from "../../../../AppRouter";
import {IUser} from "../../types";
import plusIcon from "../../../assets/plus.svg";

import {StyledButton} from "../../../../editor/assets/v2/common/StyledButton";
import {AvatarContainer, FollowingData, FollowingRow, LeftColumn, UsernameRow} from "./FloatingColumn.style";
import {StickyBox} from "../StickyBox/StickyBox";

interface Props {
    user?: IUser | null;
    isMyProfile?: boolean;
    contentRef: React.RefObject<HTMLDivElement>;
}

export const FloatingColumn = ({user, isMyProfile, contentRef}: Props) => {
    const navigate = useNavigate();

    return (
        <StickyBox isEnabled contentElement={contentRef}>
            <LeftColumn>
                <AvatarContainer />
                <UsernameRow>
                    {user?.username ? truncateName(user?.username!, 16) : truncateName(user?.name || "Unknown", 16)}
                    {isMyProfile ? (
                        <StyledButton
                            width="104px"
                            height="40px"
                            onClick={() => navigate(ROUTES.SETTINGS)}
                            isGreyTertiary>
                            Edit Profile
                        </StyledButton>
                    ) : (
                        <StyledButton width="89px" onClick={() => void 0} isBlue height="40px">
                            <img className="plus-icon" src={plusIcon} alt="" />
                            Follow
                        </StyledButton>
                    )}
                </UsernameRow>
                <FollowingRow>
                    <FollowingData>
                        <div className="label">Followers</div>
                        <div className="value">{formatNumber(365, 999)}</div>
                    </FollowingData>
                    <FollowingData>
                        <div className="label">Following</div>
                        <div className="value">{formatNumber(1000, 999)}</div>
                    </FollowingData>
                </FollowingRow>
            </LeftColumn>
        </StickyBox>
    );
};
