/*
 * Copyright 2017-2020 The ShadowEditor Authors. All rights reserved.
 *
 * Use of this source code is governed by a MIT-style
 * license that can be found in the LICENSE file.
 *
 * For more information, please visit: https://github.com/tengge1/ShadowEditor
 * You can also visit: https://gitee.com/tengge1/ShadowEditor
 */
import BaseHelper from "./BaseHelper";

import global from "../global";
import * as THREE from "three";
import {Box3} from "three";
import {isInputActive} from "../editor/assets/v2/utils/isInputActive";

/**
 * 选择帮助器
 *
 */
class SelectHelper extends BaseHelper {
    constructor() {
        super();
        this.hideObjects = [];
        this.sceneHelperGrid = null;
        this.sceneHelperGridColor = null;
        this.sceneHelperGridTransparent = null;
        this.sceneHelperGridOpacity = null;
        this.sceneHelpers = null;
        this.scene = null;
        this.camera = null;
        this.renderer = null;
        this.composer = null;
        this.animate = false;

        this.selectionBoxes = [];

        this.selectedObject = null;
        this.controls = null;

        this.lastClickTime = null;
        this.doubleClickThreshold = 300;
    }

    start() {
        global.app.on(`objectSelected.${this.id}`, this.onObjectSelected.bind(this));
        global.app.on(`objectArraySelected.${this.id}`, this.onObjectArraySelected.bind(this));
        global.app.on(`objectRemoved.${this.id}`, this.onObjectRemoved.bind(this));
        global.app.on(`afterRender.${this.id}`, this.onAfterRender.bind(this));
        global.app.on(`storageChanged.${this.id}`, this.onStorageChanged.bind(this));
        global.app.on(`sceneLoaded.${this.id}`, this.onSceneLoaded.bind(this));
        global.app.on(`sceneSaveStart.${this.id}`, this.deleteSelectionBoxes.bind(this));

        document.addEventListener("mousemove", this.onMouseMove.bind(this));
        document.addEventListener("mouseup", this.onMouseUp.bind(this));
        document.addEventListener("keydown", this.onKeyDown.bind(this));
    }

    stop() {
        global.app.on(`objectSelected.${this.id}`, null);
        global.app.on(`objectArraySelected.${this.id}`, null);
        global.app.on(`objectRemoved.${this.id}`, null);
        global.app.on(`afterRender.${this.id}`, null);
        global.app.on(`storageChanged.${this.id}`, null);
        global.app.on(`sceneLoaded.${this.id}`, null);
        global.app.on(`sceneSaveStart.${this.id}`, null);
        global.app.on(`sceneSaved.${this.id}`, null);
        global.app.on(`sceneSaveFailed.${this.id}`, null);

        document.removeEventListener("mousemove", this.onMouseMove);
        document.removeEventListener("mouseup", this.onMouseUp);
        document.removeEventListener("keydown", this.onKeyDown);

        this.unselect();
    }

    onSceneLoaded() {
        const {editor} = global.app;
        editor.composer = null;
        editor.renderPass = null;
        editor.outlinePass = null;
        editor.fxaaPass = null;

        this.sceneHelpers = editor.sceneHelpers;
        if (this.sceneHelpers) {
            this.sceneHelpers.traverse(object => {
                if (object && object.userData.isInfiniteGrid) {
                    this.sceneHelperGrid = object;
                    this.sceneHelperGridColor = object.material.color;
                    this.sceneHelperGridTransparent = object.material.transparent;
                    this.sceneHelperGridOpacity = object.material.opacity;
                }
            });
        }

        this.scene = editor.scene;
        this.camera = editor.camera;
        this.renderer = editor.renderer;

        this.controls = this.scene.userData.controls;
    }

    onMouseDown(event) {
        if (event.button === 0 && this.selectedObject) {
            this.updateSelectionBoxes(this.selectedObject);
        }
    }

    onMouseMove(event) {
        if (this.selectedObject) {
            this.updateSelectionBoxes(this.selectedObject);
        }
    }

    onMouseUp(event) {
        if (this.selectedObject) {
            this.updateSelectionBoxes(this.selectedObject);
        }
    }

    onKeyDown(event) {
        if (this.selectedObject && (event.key === "f" || event.key === "F") && !isInputActive()) {
            this.focusCameraOnObject(this.camera, this.selectedObject);
        }
    }

    onObjectArraySelected(objects) {
        this.selectedObject = null;
        this.unselect();

        if (!objects || objects.length === 0) {
            return;
        }

        objects.forEach((object, index) => {
            this.createSelectionBox();
            this.updateSelectionBox(object, index);
        });
    }

    onObjectSelected(object) {
        if (!object) {
            this.selectedObject = null;
            this.unselect();
            return;
        }

        this.deleteSelectionBoxes();

        this.selectedObject = object;

        this.createSelectionBox();
        this.updateSelectionBox(object, 0);

        const currentTime = Date.now();
        const isDoubleClick = currentTime - this.lastClickTime < this.doubleClickThreshold;
        this.lastClickTime = currentTime;

        if (this.selectedObject && isDoubleClick) {
            this.focusCameraOnObject(this.camera, this.selectedObject);
        }
    }

    updateInsertionPoint(camera) {
        camera.updateMatrixWorld();

        const origin = new THREE.Vector3().setFromMatrixPosition(camera.matrixWorld);
        const direction = new THREE.Vector3().copy(camera.getWorldDirection(new THREE.Vector3()));

        const planeNormal = new THREE.Vector3(0, 1, 0);
        const planePoint = new THREE.Vector3(0, 0, 0);

        const planeD = -planeNormal.dot(planePoint);
        const denominator = planeNormal.dot(direction);

        if (Math.abs(denominator) > 1e-6) {
            const t = -(planeNormal.dot(origin) + planeD) / denominator;

            if (t >= 0) {
                const intersectionPoint = origin.clone().add(direction.clone().multiplyScalar(t));
                return [
                    {
                        point: intersectionPoint,
                        distance: origin.distanceTo(intersectionPoint),
                    },
                ];
            }
        }

        return [];
    }

    updateSelectionBoxes(object) {
        if (Array.isArray(object)) {
            object.forEach((obj, index) => {
                this.updateSelectionBox(obj, index);
            });
        } else {
            this.updateSelectionBox(object, 0);
        }
    }

    updateSelectionBox(object, index) {
        const selectionBox = this.selectionBoxes[index];
        if (!selectionBox) {
            console.log("no selection box found for object in index", index);
            return;
        }

        let hasGeometry = false;
        object.traverse(obj => {
            if (obj.geometry) {
                hasGeometry = true;
            }
        });

        if (!hasGeometry) {
            const boxSize = 2;
            selectionBox.box.setFromCenterAndSize(object.position, object.scale.clone().multiplyScalar(boxSize));
            return;
        }

        selectionBox.box.setFromObject(object);
    }

    focusCameraOnObject(camera, selectedObject) {
        if (!selectedObject || selectedObject.name.toLowerCase().includes("sky")) {
            return;
        }

        // orbit controls prevent camera from rotating,
        // and focus method is not implemented
        // so set target position manually and update controls
        const controls = global.app.editor.controls;
        const orbitControls = controls?.current?.controls;
        if (!orbitControls) {
            return;
        }

        const focusPosition = new THREE.Vector3();
        selectedObject.getWorldPosition(focusPosition);

        const box = new THREE.Box3().setFromObject(selectedObject);
        const sphere = new THREE.Sphere();
        box.getBoundingSphere(sphere);

        let radius = sphere.radius;

        if (radius <= 0) {
            radius = 1;
        }

        // make sure that the object is fit in the camera view with some margin
        const fovMargin = 10;
        const fov = (camera.fov - fovMargin) * (Math.PI / 180);
        const distance = radius / Math.sin(fov / 2);

        orbitControls.target?.copy(focusPosition);

        const prevMaxDistance = orbitControls.maxDistance;
        const prevMinDistance = orbitControls.minDistance;

        orbitControls.maxDistance = distance;
        orbitControls.minDistance = distance;

        orbitControls.update();

        orbitControls.maxDistance = prevMaxDistance;
        orbitControls.minDistance = prevMinDistance;
    }

    onObjectRemoved(object) {
        if (object === this.object) {
            this.unselect();
        }
    }

    unselect() {
        global.app.editor.transformControls.detach();
        this.cancelSelectionAnimation();

        if (global.app.editor.outlinePass) {
            global.app.editor.outlinePass.selectedObjects = [];
        }

        if (global.app.editor.transformControls) {
            global.app.editor.transformControls.visible = false;
        }
        if (this.object) {
            delete this.object;
        }

        this.deleteSelectionBoxes();
    }

    onAfterRender() {
        if (!this.object || !this.object.parent) {
            // TODO: this.object.parent为null时表示该物体被移除
            return;
        }

        let renderScene = global.app.editor.scene;
        let renderCamera =
            global.app.editor.view === "perspective" ? global.app.editor.camera : global.app.editor.orthCamera;
        let renderer = global.app.editor.renderer;

        let scene = this.scene;
        let camera = this.camera;
        let selected = this.object;

        // 记录原始状态
        let oldOverrideMaterial = renderScene.overrideMaterial;
        let oldBackground = renderScene.background;

        let oldAutoClear = renderer.autoClear;
        if (!this.oldClearColor) {
            this.oldClearColor = new THREE.Color();
        }

        renderer.getClearColor(this.oldClearColor);
        let oldClearAlpha = renderer.getClearAlpha();
        let oldRenderTarget = renderer.getRenderTarget();

        // 绘制蒙版
        this.hideObjects.length = 0;
        this.hideNonSelectedObjects(renderScene, selected, renderScene);

        renderScene.overrideMaterial = this.maskMaterial;
        renderScene.background = null;

        renderer.autoClear = false;
        renderer.setRenderTarget(this.maskBuffer);
        renderer.setClearColor(0xffffff);
        renderer.setClearAlpha(1);
        renderer.clear();

        renderer.render(renderScene, renderCamera);

        this.showNonSelectedObjects(renderScene, selected);
        this.hideObjects.length = 0;

        // 绘制边框
        //MISHA
        this.quad.material = this.edgeMaterial;

        renderScene.overrideMaterial = null;

        renderer.setRenderTarget(this.edgeBuffer);
        renderer.clear();
        renderer.render(scene, camera);

        // 与原场景叠加
        //MISHA
        this.quad.material = this.copyMaterial;

        renderer.setRenderTarget(null);
        renderer.render(scene, camera);

        // 还原原始状态
        renderScene.overrideMaterial = oldOverrideMaterial;
        renderScene.background = oldBackground;

        renderer.autoClear = oldAutoClear;
        renderer.setClearColor(this.oldClearColor);
        renderer.setClearAlpha(oldClearAlpha);
        renderer.setRenderTarget(oldRenderTarget);
    }

    hideNonSelectedObjects(obj, selected, root) {
        if (obj === selected) {
            let current = obj.parent;
            while (current && current !== root) {
                let index = this.hideObjects.indexOf(current);
                this.hideObjects.splice(index, 1);
                current.visible = current.userData.oldVisible;
                delete current.userData.oldVisible;
                current = current.parent;
            }
            return;
        }

        if (obj !== root) {
            obj.userData.oldVisible = obj.visible;
            obj.visible = false;
            this.hideObjects.push(obj);
        }

        for (let child of obj.children) {
            if (child instanceof THREE.Light) {
                continue;
            }
            this.hideNonSelectedObjects(child, selected, root);
        }
    }

    showNonSelectedObjects() {
        this.hideObjects.forEach(n => {
            n.visible = n.userData.oldVisible;
            delete n.userData.oldVisible;
        });
    }

    onStorageChanged(name, value) {
        if (!this.edgeMaterial) {
            return;
        }
        if (name === "selectedColor") {
            this.edgeMaterial.uniforms.color.value.set(value);
        } else if (name === "selectedThickness") {
            this.edgeMaterial.uniforms.thickness.value = value;
        }
    }

    cancelSelectionAnimation() {
        if (global.app.editor?.animationId) {
            cancelAnimationFrame(global.app.editor.animationId);
            global.app.editor.animationId = null;
        }
    }

    createSelectionBox() {
        const selectionBox = new THREE.Box3Helper(new THREE.Box3());
        selectionBox.material.depthTest = false;
        selectionBox.material.transparent = true;
        selectionBox.visible = true;
        selectionBox.name = "SelectionBox";
        this.selectionBoxes.push(selectionBox);
        this.sceneHelpers.add(selectionBox);
        return selectionBox;
    }

    deleteSelectionBoxes() {
        if (this.selectionBoxes.length > 0) {
            this.selectionBoxes.forEach(selectionBox => {
                this.sceneHelpers.remove(selectionBox);
            });
            this.selectionBoxes = [];
        }
    }

    dispose() {
        this.scene.children = this.scene.children.filter(child => {
            if (child.userData && child.userData.isInfiniteGrid) {
                this.scene.remove(child);
                return false;
            }
            return true;
        });
    }
}

export default SelectHelper;
