/*
 * Copyright 2017-2020 The ShadowEditor Authors. All rights reserved.
 *
 * Use of this source code is governed by a MIT-style
 * license that can be found in the LICENSE file.
 *
 * For more information, please visit: https://github.com/tengge1/ShadowEditor
 * You can also visit: https://gitee.com/tengge1/ShadowEditor
 */
import PackageManager from "../../../package/PackageManager";

let ID = -1;

/**
 * BaseLoader
 *
 */
class BaseLoader {
    id: string;
    packageManager: PackageManager;
    require: OmitThisParameter<((names: Object) => Promise<any>) | any>;

    constructor() {
        this.id = `BaseLoader${ID--}`;
        this.packageManager = new PackageManager();
        this.require = this.packageManager.require.bind(this.packageManager);
    }

    load(url: string, options?: any) {
        // eslint-disable-line
        return new Promise(resolve => {
            resolve(null);
        });
    }
}

export default BaseLoader;
