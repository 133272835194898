import {toast} from "react-toastify";
import Ajax from "../utils/Ajax";
import {backendUrlFromPath} from "../utils/UrlUtils";

export const isUserOnWhitelist = async (userEmail?: string) => {
    try {
        const response = await Ajax.get({url: backendUrlFromPath(`/api/Whitelist/Get`), needAuthorization: false});
        const obj = response?.data;
        if (obj.Code !== 200) {
            if (obj.Code === 404) {
                return false;
            }
            toast.error("Couldn't fetch the whitelist.");
            return;
        }
        const whitelist = obj.Data;
        return whitelist.some((data: {email: string}) => data.email === userEmail);
    } catch (error) {
        console.log(`Error from fetching whitelist: ${error}`);
    }
};
