import {useEffect, useState} from "react";
import axios from "axios";
import {toast} from "react-toastify";
import {useNavigate} from "react-router-dom";

import {backendUrlFromPath} from "../../../../../utils/UrlUtils";
import {ROUTES} from "../../../../../AppRouter";
import {useAuthorizationContext} from "../../../../../context";
import {ISubmitButton, SubmitButtonType} from "./types";
import deleteIcon from "../../icons/delete-icon.svg";

import {LoadingAnimation} from "../../../../../ui/progress/LoadingAnimation";
import {AccountBox, Container, Heading} from "../SettingsPage/SettingsPage.style";
import {InvalidEmailList, ValidationTextArea} from "./AdminPanel.style";
import {StyledButton} from "../../common/StyledButton";
import {Separator} from "../../RightPanel/common/Separator";

export const AdminPanel = () => {
    const {isAdmin, authToken} = useAuthorizationContext();
    const [addEmails, setAddEmails] = useState("");
    const [removeEmails, setRemoveEmails] = useState("");
    const [loading, setLoading] = useState(false);
    const [invalidAddEmails, setInvalidAddEmails] = useState<string[]>([]);
    const [invalidRemoveEmails, setInvalidRemoveEmails] = useState<string[]>([]);
    const navigate = useNavigate();

    useEffect(() => {
        if (!isAdmin) {
            toast.error("Access forbidden.");
            navigate(ROUTES.HOME);
        }
    }, [isAdmin, navigate]);

    const handleSubmit = async (action: SubmitButtonType) => {
        const emailList = action === "add" ? addEmails : removeEmails;

        // Split the raw data into emails based on commas or newlines
        const emailArray = emailList
            .split(/[,\n]/) // Split by commas or newlines
            .map(email => email.trim()) // Trim spaces
            .filter(email => email); // Remove empty strings

        // Validate each email and collect invalid ones
        const invalidEmails = emailArray.filter(email => !/^[\w-]+(\.[\w-]+)*@([\w-]+\.)+[a-zA-Z]{2,7}$/.test(email));

        if (invalidEmails.length > 0) {
            if (action === "add") {
                setInvalidAddEmails(invalidEmails);
            } else {
                setInvalidRemoveEmails(invalidEmails);
            }
            return toast.error("Some emails are invalid. See below for details.");
        }

        if (action === "add") {
            setInvalidAddEmails([]); // Clear invalid emails for add
        } else {
            setInvalidRemoveEmails([]); // Clear invalid emails for remove
        }

        setLoading(true);

        try {
            const url = action === "add" ? "/api/Whitelist/Admin/Add" : "/api/Whitelist/Admin/Delete";
            await axios.post(
                backendUrlFromPath(url)!,
                {emails: emailArray},
                {headers: {Authorization: `Bearer ${authToken}`}},
            );
            const successMessage =
                action === "add"
                    ? "Emails added successfully to the whitelist."
                    : "Emails removed successfully from the whitelist.";
            toast.success(successMessage);

            // Clear the input after successful submission
            if (action === "add") {
                setAddEmails("");
            } else {
                setRemoveEmails("");
            }
        } catch (error) {
            toast.error("Failed to update the whitelist.");
        } finally {
            setLoading(false);
        }
    };

    const clearWhitelist = async () => {
        const confirmation = window.confirm(
            "Are you sure you want to clear the whitelist? This action cannot be undone!",
        );

        if (!confirmation) {
            return;
        }

        setLoading(true);
        try {
            await axios.post(
                backendUrlFromPath("/api/Whitelist/Admin/Clear")!,
                {},
                {
                    headers: {Authorization: `Bearer ${authToken}`},
                },
            );
        } catch (error) {
            toast.error("Failed to clear the whitelist.");
        } finally {
            setLoading(false);
        }
    };

    const emailSections: ISubmitButton[] = [
        {
            action: "add",
            label: "Emails to add",
            value: addEmails,
            setValue: setAddEmails,
            invalidEmails: invalidAddEmails,
            setInvalidEmails: setInvalidAddEmails,
            buttonLabel: "Add to Whitelist",
            isBlue: true,
        },
        {
            action: "remove",
            label: "Emails to remove",
            value: removeEmails,
            setValue: setRemoveEmails,
            invalidEmails: invalidRemoveEmails,
            setInvalidEmails: setInvalidRemoveEmails,
            buttonLabel: "Delete from Whitelist",
            isRed: true,
        },
    ];

    if (!isAdmin) return;

    return (
        <Container>
            <LoadingAnimation show={loading} />
            <AccountBox className="box">
                <Heading>Whitelist Management</Heading>
                {emailSections.map(({action, label, value, setValue, invalidEmails, buttonLabel, isBlue, isRed}) => (
                    <div key={action} className={"wrapper"}>
                        <div>
                            <label>{label}</label>
                            <ValidationTextArea
                                value={value}
                                onChange={e => setValue(e.target.value)}
                                placeholder="Paste emails here, separated by commas or new lines"
                            />
                        </div>

                        {invalidEmails.length > 0 && (
                            <InvalidEmailList>Invalid emails: {invalidEmails.join(", ")}</InvalidEmailList>
                        )}

                        <StyledButton
                            isRed={!!isRed}
                            isBlue={!!isBlue}
                            onClick={() => handleSubmit(action)}
                            disabled={loading}>
                            {loading ? "Submitting..." : buttonLabel}
                        </StyledButton>
                    </div>
                ))}
                <Separator />
                <label style={{color: "white"}}>Clear whitelist</label>
                <label>This will remove all emails from whitelist!</label>
                <StyledButton isRed onClick={clearWhitelist} disabled={loading} width="124px">
                    <img src={deleteIcon} alt="" /> {loading ? "Submitting..." : "Clear"}
                </StyledButton>
            </AccountBox>
        </Container>
    );
};
