import {PROP_ANIMATION_TYPES, PropAnimationBehaviorInterface} from "../../types/editor";
import {BehaviorUpdater} from "../../behaviors/BehaviorManager";
import GameManager from "../../behaviors/game/GameManager";
import * as THREE from "three";
import {AnimationData} from "../../controls/AnimationController";
import Player from "../../player/Player";
import global from "../../global";

class PropAnimationBehaviorUpdater implements BehaviorUpdater {
    target: THREE.Object3D;
    game?: GameManager;
    requestAnimationFrameId: number;
    behavior: PropAnimationBehaviorInterface;

    constructor(target: THREE.Object3D, behavior: PropAnimationBehaviorInterface) {
        this.target = target;
        this.behavior = behavior;
        this.requestAnimationFrameId = -1;
    }

    init(gameManager: GameManager) {
        this.game = gameManager;
        this.initAnimation();
    }

    initAnimation = () => {
        if (!this.game || !this.game.scene) {
            console.warn("GameManager or scene is not initialized properly.");
            return;
        }

        const selectedAnimation = this.behavior.propAnimation;
        const animationSpeed = this.behavior.animationSpeed || 1.0;
        const loop = this.behavior.animationType === PROP_ANIMATION_TYPES.LOOP;

        if (selectedAnimation && selectedAnimation !== "none") {
            (global.app as Player).animationControl.playAnimation(
                this.target,
                selectedAnimation,
                animationSpeed,
                !loop,
            );
        }
    };

    update(clock: THREE.Clock, delta: number): void {
        if (!this.game || !this.game.scene) {
            console.warn("GameManager or scene is not initialized properly.");
            return;
        }

        /*if (this.target.userData.animation) {
            const {mixer, speed} = this.target.userData.animation;
            mixer.update(delta * speed);
            this.requestAnimationFrameId = requestAnimationFrame(() => this.update(clock, delta));
        }*/
    }

    stop = () => {
        if (this.requestAnimationFrameId !== -1) {
            cancelAnimationFrame(this.requestAnimationFrameId);
            this.requestAnimationFrameId = -1;
        }
    };

    dispose = () => {
        if (this.target.userData.animation) {
            const {mixer} = this.target.userData.animation as AnimationData;
            mixer.stopAllAction();
            mixer.uncacheRoot(mixer.getRoot());
            delete this.target.userData.animation;
        }

        this.requestAnimationFrameId = -1;
    };

    reset() {}
}

export default PropAnimationBehaviorUpdater;
