import * as THREE from 'three';
import { MOBILE_JOYSTICK_MOVEMENT_STATE, MOBILE_JOYSTICK_DIRECTION_STATE } from '../types/editor';

export class JoyStickTouchControls {

    private character: THREE.Object3D;
    private outerCircle!: HTMLElement;
    private innerCircle!: HTMLElement;
    private maxDistance!: number;
    private radius!: number;
    private isDragging = false;
    private onStartHandler: (event: TouchEvent) => void;
    private onDragHandler: (event: TouchEvent) => void;
    private onEndHandler: () => void;
   
    constructor(character: THREE.Object3D) {
        this.character = character;
        this.onStartHandler = this.onStart.bind(this);
        this.onDragHandler = this.onDrag.bind(this);
        this.onEndHandler = this.onEnd.bind(this);
        this.loadJoyStickControl();

    }

    private initializeFullscreen() {
        if (document.fullscreenElement == null) {
            document.body.requestFullscreen().catch((err) => {
                console.error("Failed to enter fullscreen mode:", err);
            });
        }
    }

    private loadJoyStickControl() {
        const outerCircle = document.createElement('div');
        outerCircle.id = 'character-joystick-control';
        outerCircle.classList.add('large-button');
        outerCircle.style.width = '22vw';
        outerCircle.style.height = '22vw';
        outerCircle.style.maxWidth = '75px';
        outerCircle.style.maxHeight = '75px';
        outerCircle.style.borderRadius = '50%';
        outerCircle.style.backgroundColor = 'rgba(250, 250, 250, 0.40)';
        outerCircle.style.position = 'fixed';
        outerCircle.style.bottom = '6%';
        outerCircle.style.left = '5%';
        outerCircle.style.display = 'flex';
        outerCircle.style.justifyContent = 'center';
        outerCircle.style.alignItems = 'center';
        outerCircle.style.zIndex = '3000';
        outerCircle.style.background = 'radial-gradient(circle at 50% 0px, #ffffff, rgba(255, 255, 255, 0) 85%)';
        outerCircle.style.border = '1px solid white';

        outerCircle.addEventListener('touchstart', (event: TouchEvent) => event.preventDefault(), { passive: false });
        outerCircle.addEventListener('touchmove', (event: TouchEvent) => event.preventDefault(), { passive: false });
        outerCircle.addEventListener('touchend', (event: TouchEvent) => event.preventDefault(), { passive: false });

        document.body.appendChild(outerCircle);

        const outerCircleRect = outerCircle.getBoundingClientRect();
        this.radius = Math.min(outerCircleRect.width, outerCircleRect.height) / 2;
        this.maxDistance = this.radius * 1.3;
        this.outerCircle = outerCircle;

        const innerCircle = document.createElement('div');

        innerCircle.style.width = '50%';
        innerCircle.style.height = '50%';
        innerCircle.style.borderRadius = '50%';
        innerCircle.style.position = 'absolute';
        innerCircle.style.left = '50%';
        innerCircle.style.top = '50%';
        innerCircle.style.transform = 'translate(-50%, -50%)';
        innerCircle.style.cursor = 'grab';
        innerCircle.style.background = `
            radial-gradient(circle at 30% 30%, #555555, #000000 80%)
        `;
                innerCircle.style.boxShadow = `
            inset 0px 3px 6px rgba(255, 255, 255, 0.3),
            0px 3px 6px rgba(0, 0, 0, 0.6)
        `;

        const innerTouchArea = document.createElement('div');
        innerTouchArea.style.width = '100%';
        innerTouchArea.style.height = '100%';
        innerTouchArea.style.position = 'absolute';
        innerTouchArea.style.left = '50%';
        innerTouchArea.style.top = '50%';
        innerTouchArea.style.transform = 'translate(-50%, -50%)';
        innerTouchArea.style.borderRadius = '50%';
        innerTouchArea.style.pointerEvents = 'auto';
        innerTouchArea.style.background = 'transparent';

        outerCircle.appendChild(innerTouchArea);
        innerTouchArea.appendChild(innerCircle);

        innerTouchArea.addEventListener('touchstart', this.onStartHandler);
        document.addEventListener('touchend', this.onEndHandler);

        this.innerCircle = innerCircle;

        innerCircle.addEventListener('touchstart', this.onStartHandler);
        document.addEventListener('touchend', this.onEndHandler);

    }

    private onStart(event: TouchEvent) {
        event.preventDefault();
        this.initializeFullscreen();
        this.isDragging = true;
        this.innerCircle.style.cursor = 'grabbing';
        document.addEventListener('touchmove', this.onDragHandler);
    }

    private onDrag(event: TouchEvent) {
        if (!this.isDragging) return;

        const clientX = event.touches[0].clientX;
        const clientY = event.touches[0].clientY;

        const outerRect = this.outerCircle.getBoundingClientRect();
        const offsetX = clientX - outerRect.left;
        const offsetY = clientY - outerRect.top;

        const centerX = outerRect.width / 2;
        const centerY = outerRect.height / 2;
        const dx = (offsetX - centerX) / this.radius;
        const dy = (offsetY - centerY) / this.radius;

        let distance = Math.sqrt(dx ** 2 + dy ** 2) * this.radius;

        if (distance > this.maxDistance) {
            distance = this.maxDistance;
            const angle = Math.atan2(dy, dx);
            const constrainedX = Math.cos(angle) * this.maxDistance + centerX;
            const constrainedY = Math.sin(angle) * this.maxDistance + centerY;

            this.innerCircle.style.left = `${constrainedX}px`;
            this.innerCircle.style.top = `${constrainedY}px`;
        } else {
            this.innerCircle.style.left = `${offsetX}px`;
            this.innerCircle.style.top = `${offsetY}px`;
        }

        const normalizedDX = dx / Math.sqrt(dx ** 2 + dy ** 2);
        const normalizedDY = dy / Math.sqrt(dx ** 2 + dy ** 2);

        this.character.userData.touchDirection = { dx: normalizedDX, dy: normalizedDY };
        this.character.userData.touchDistance = distance;

        const walkSpeed = this.character.userData.walkSpeed || 1;
        const runSpeed = this.character.userData.runSpeed || 5;

        const normalizedDistance = distance / this.maxDistance;

        const speed = normalizedDistance * (runSpeed - walkSpeed) + walkSpeed;
        this.character.userData.currentSpeed = speed;

        const runningThreshold = (2 / 3) * runSpeed;

        if (speed > runningThreshold) {
            this.character.userData.touchJoystickIsRunning = true;
            this.character.userData.touchJoystickIsWalking = false;
        } else if (speed > 0) {
            this.character.userData.touchJoystickIsRunning = false;
            this.character.userData.touchJoystickIsWalking = true;
        } else {
            this.character.userData.touchJoystickIsRunning = false;
            this.character.userData.touchJoystickIsWalking = false;
        }

        // console.log(`Speed: ${speed}, Running: ${this.character.userData.touchJoystickIsRunning}, Walking: ${this.character.userData.touchJoystickIsWalking}`);

        const threshold = 0.4;

        if (Math.abs(dx) < threshold) {
            this.character.userData.touchMovementState =
                dy < 0
                    ? MOBILE_JOYSTICK_MOVEMENT_STATE.STRAIGHT_FORWARD
                    : MOBILE_JOYSTICK_MOVEMENT_STATE.STRAIGHT_BACKWARD;
            this.character.userData.touchDirectionState = null;
        } else {
            this.character.userData.touchMovementState =
                dy < 0
                    ? MOBILE_JOYSTICK_MOVEMENT_STATE.FORWARD
                    : MOBILE_JOYSTICK_MOVEMENT_STATE.BACKWARD;

            this.character.userData.touchDirectionState =
                dx < -threshold
                    ? MOBILE_JOYSTICK_DIRECTION_STATE.LEFT
                    : dx > threshold
                        ? MOBILE_JOYSTICK_DIRECTION_STATE.RIGHT
                        : null;
        }

        this.updateModelRotation(dx, dy);
    }

    private updateModelRotation(dx: number, dy: number) {
        const rotationSpeed = 1;
        let targetAngle = Math.atan2(dx, dy);
        let currentAngle = this.character.rotation.y;
        currentAngle = THREE.MathUtils.lerp(currentAngle, targetAngle, rotationSpeed);
        this.character.userData.touchRotation = currentAngle;
    }

    private onEnd() {
        this.isDragging = false;
        this.innerCircle.style.cursor = 'grab';
        this.innerCircle.style.left = '50%';
        this.innerCircle.style.top = '50%';
        this.innerCircle.style.transform = 'translate(-50%, -50%)';
        this.character.userData.touchMovementState = MOBILE_JOYSTICK_MOVEMENT_STATE.TOUCH_STOPPED;
        this.character.userData.touchDirectionState = null;
        this.character.userData.touchJoystickIsRunning = null;
        this.character.userData.touchJoystickIsWalking = null;
        this.character.userData.currentSpeed = 0;
        document.removeEventListener('touchmove', this.onDragHandler);
    }

}