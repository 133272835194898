import {useRef} from "react";
import {useOnClickOutside} from "usehooks-ts";
import styled from "styled-components";
import {useAuthorizationContext} from "../../context";
import {regularFont} from "../../assets/style";
import {HEADER_HEIGHT, HEADER_Z_INDEX} from "./Header.style";
import {getProfilePath} from "../pages/services";

enum LABELS {
    PROFILE = "View Profile",
    SETTINGS = "Settings",
}

const MENU_ITEMS = [{label: LABELS.PROFILE}, {label: LABELS.SETTINGS, link: "/settings"}];

export const UserMenu = ({close}: {close: () => void}) => {
    const {dbUser} = useAuthorizationContext();
    const menuRef = useRef<HTMLDivElement>(null);
    const username = dbUser?.username || dbUser?.name;

    useOnClickOutside(menuRef, close);

    const handleNavigation = (link?: string, userProfile?: boolean) => {
        if (userProfile && dbUser) {
            if (!username) {
                console.error("Username field doesn't exist.");
                return "/";
            }
            return getProfilePath(username);
        } else if (link) {
            return link;
        }
    };

    return (
        <Menu ref={menuRef}>
            {MENU_ITEMS.map(({label, link}) => (
                <MenuItem
                    key={label}
                    href={handleNavigation(link, label === LABELS.PROFILE)}
                    target={label === LABELS.PROFILE ? "_self" : "_blank"}
                    rel="noopener noreferrer">
                    {label}
                </MenuItem>
            ))}
        </Menu>
    );
};

const Menu = styled.div`
    width: 160px;
    height: 74px;
    position: fixed;
    right: 24px;
    top: calc(${HEADER_HEIGHT} - 8px);
    background-color: var(--theme-homepage-grey-bg-primary);
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
    border-radius: 4px;
    border: 1px solid var(--theme-grey-bg-secondary);
    padding: 4px;
    z-index: calc(${HEADER_Z_INDEX} + 1);
`;
const MenuItem = styled.a`
    display: flex;
    align-items: center;
    cursor: pointer;
    width: 100%;
    transition: 0.2s;
    padding: 8px;
    border-radius: 4px;
    height: 32px;
    background-color: transparent;
    ${regularFont("xxs")};

    &:hover {
        background-color: var(--theme-homepage-grey-bg-secondary);
    }
`;
