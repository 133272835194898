import Editor from "../../../../../Editor";
import {
    BehaviorInterface,
    OBJECT_TYPES,
    OBJECT_TYPES_ASSETS_LIST,
    OBJECT_TYPES_WHITE_LIST,
} from "../../../../../../types/editor";
import * as THREE from "three";

import AnimationBehaviorConverter from "../../../../../../serialization/behaviours/AnimationBehaviorConverter";
import VolumeBehaviorConverter from "../../../../../../serialization/behaviours/VolumeBehaviorConverter";
import GenericSoundBehaviorConverter from "../../../../../../serialization/behaviours/GenericSoundsBehaviorConverter";
import EnemyBehaviorConverter from "../../../../../../serialization/behaviours/EnemyBehaviorConverter";
import PlatformBehaviorConverter from "../../../../../../serialization/behaviours/PlatformBehaviorConverter";
import SpawnPointBehaviorConverter from "../../../../../../serialization/behaviours/SpawnPointBehaviorConverter";
import JumpPadBehaviorConverter from "../../../../../../serialization/behaviours/JumpPadBehaviorConverter";
import TeleportBehaviorConverter from "../../../../../../serialization/behaviours/TeleportBehaviorConverter";
import FollowBehaviorConverter from "../../../../../../serialization/behaviours/FollowBehaviorConverter";
import CharacterSoundsBehaviorConverter from "../../../../../../serialization/behaviours/CharacterSoundsBehaviorConverter";
import ThrowableBehaviorConverter from "../../../../../../serialization/behaviours/ThrowableBehaviorConverter";
import ConsumableBehaviorConverter from "../../../../../../serialization/behaviours/ConsumableBehaviorConverter";
import CharacterBehaviorConverter from "../../../../../../serialization/behaviours/CharacterBehaviorConverter";
import ScriptBehaviorConverter from "../../../../../../serialization/behaviours/ScriptBehaviorConverter";
import {getPhysics} from "../../../utils/getPhysics";
import {CollisionType} from "../../../types/physics";
import SceneVolumeBehaviorAssetConverter from "../../../../../../serialization/behaviours/SceneVolumeBehaviorAssetConverter";
import SpawnPointBehaviorAssetConverter from "../../../../../../serialization/behaviours/SpawnPointBehaviorAssetConverter";
import CheckPointBehaviorAssetConverter from "../../../../../../serialization/behaviours/CheckPointBehaviorAssetConverter";
import CameraBehaviorConverter from "../../../../../../serialization/behaviours/CameraBehaviorConverter";
import AiNPCBehaviorConverter from "../../../../../../serialization/behaviours/AiNpcBehaviorsConverter";
import BillboardBehaviorConverter from "../../../../../../serialization/behaviours/BillboardBehaviorConverter";
import WeaponAmmoBehaviorConverter from "../../../../../../serialization/behaviours/WeaponAmmoBehaviorConverter";
import WeaponBehaviorConverter from "../../../../../../serialization/behaviours/WeaponBehaviorConverter";
import PropAnimationBehaviorConverter from "../../../../../../serialization/behaviours/PropAnimationBehaviorConverter";
import TriggerBehaviorConverter from "../../../../../../serialization/behaviours/TriggerBehaviorConverter";

export const enableCharacterPhysics = (obj: any) => {
    obj.userData.physics = {
        ...obj.userData.physics,
        ...getPhysics(obj.userData.physics),
    };

    obj.userData.physics.enabled = true;
    obj.userData.physics.mass = 1;
    obj.userData.physics.ctype = CollisionType.Dynamic;
};

export const enableThrowablePhysics = (obj: any) => {
    obj.userData.physics = {
        ...obj.userData.physics,
        ...getPhysics(obj.userData.physics),
    };

    obj.userData.physics.enabled = true;
    obj.userData.physics.ctype = CollisionType.Dynamic;
};

const checkIfCharacterExist = (editor: Editor) => {
    return (
        !!editor.camera?.userData.thirdPersonOptions ||
        !!editor.camera?.userData.fpsOptions ||
        !!editor.camera?.userData.sideScrollerOptions ||
        !!editor.camera?.userData.VehicleOptions
    );
};

export const setBehaviorForObject = (type: OBJECT_TYPES, editor: Editor, object: any, callback?: () => void) => {
    if (!object || object instanceof Array) return;

    const obj = editor.objectByUuid(object.uuid) || object;
    if ((OBJECT_TYPES_WHITE_LIST.includes(type) || OBJECT_TYPES_ASSETS_LIST.includes(type)) && obj) {
        if (!obj.userData.behaviors) {
            obj.userData.behaviors = [];
        }

        let behavior: BehaviorInterface | null = null;
        const id = THREE.MathUtils.generateUUID();
        switch (type) {
            case OBJECT_TYPES.CHARACTER:
                behavior = CharacterBehaviorConverter.DEFAULT.getDefaultBehavior(id);
                enableCharacterPhysics(obj);
                break;
            case OBJECT_TYPES.ANIMATION:
                behavior = AnimationBehaviorConverter.DEFAULT.getDefaultBehavior(id);
                break;
            case OBJECT_TYPES.VOLUME:
                behavior = VolumeBehaviorConverter.DEFAULT.getDefaultBehavior(id);
                break;
            case OBJECT_TYPES.GENERIC_SOUND:
                behavior = GenericSoundBehaviorConverter.DEFAULT.getDefaultBehavior(id);
                break;
            case OBJECT_TYPES.ENEMY:
                behavior = EnemyBehaviorConverter.DEFAULT.getDefaultBehavior(id);
                break;
            case OBJECT_TYPES.PLATFORM:
                behavior = PlatformBehaviorConverter.DEFAULT.getDefaultBehavior(id);
                break;
            case OBJECT_TYPES.JUMPPAD:
                behavior = JumpPadBehaviorConverter.DEFAULT.getDefaultBehavior(id);
                break;
            case OBJECT_TYPES.TELEPORT:
                behavior = TeleportBehaviorConverter.DEFAULT.getDefaultBehavior(id);
                break;
            case OBJECT_TYPES.FOLLOW:
                behavior = FollowBehaviorConverter.DEFAULT.getDefaultBehavior(id);
                break;
            case OBJECT_TYPES.SPAWNPOINT:
                behavior = SpawnPointBehaviorConverter.DEFAULT.getDefaultBehavior(id);
                break;
            case OBJECT_TYPES.CHARACTER_SOUNDS:
                behavior = CharacterSoundsBehaviorConverter.DEFAULT.getDefaultBehavior(id);
                break;
            case OBJECT_TYPES.THROWABLES:
                behavior = ThrowableBehaviorConverter.DEFAULT.getDefaultBehavior(id);
                enableThrowablePhysics(obj);
                break;
            case OBJECT_TYPES.CONSUMABLE:
                behavior = ConsumableBehaviorConverter.DEFAULT.getDefaultBehavior(id);
                break;
            case OBJECT_TYPES.SCRIPT:
                behavior = ScriptBehaviorConverter.DEFAULT.getDefaultBehavior(id);
                break;
            case OBJECT_TYPES.SPAWN_POINT:
                behavior = SpawnPointBehaviorAssetConverter.DEFAULT.getDefaultBehavior(id);
                break;
            case OBJECT_TYPES.CHECK_POINT:
                behavior = CheckPointBehaviorAssetConverter.DEFAULT.getDefaultBehavior(id);
                break;
            case OBJECT_TYPES.BILLBOARD:
                behavior = BillboardBehaviorConverter.DEFAULT.getDefaultBehavior(id) as unknown as BehaviorInterface;
                break;
            case OBJECT_TYPES.SCENE_VOLUME:
                behavior = SceneVolumeBehaviorAssetConverter.DEFAULT.getDefaultBehavior();
                break;
            case OBJECT_TYPES.CAMERA:
                behavior = CameraBehaviorConverter.DEFAULT.getDefaultBehavior(id);
                break;
            case OBJECT_TYPES.AI_NPC:
                behavior = AiNPCBehaviorConverter.DEFAULT.getDefaultBehavior(id);
                break;
            case OBJECT_TYPES.WEAPON:
                behavior = WeaponBehaviorConverter.DEFAULT.getDefaultBehavior(id);
                break;
            case OBJECT_TYPES.WEAPON_AMMO:
                behavior = WeaponAmmoBehaviorConverter.DEFAULT.getDefaultBehavior(id);
                break;
            case OBJECT_TYPES.PROP_ANIMATION:
                behavior = PropAnimationBehaviorConverter.DEFAULT.getDefaultBehavior(id);
                break;
            case OBJECT_TYPES.TRIGGER:
                behavior = TriggerBehaviorConverter.DEFAULT.getDefaultBehavior(id);
                break;
            default:
                break;
        }

        if (!behavior) {
            behavior = {
                enabled: false,
                id,
                type: type,
            };
        } else {
            behavior.id = id;
            behavior.enabled = true;
        }

        // disable all other behaviors of the same type
        obj.userData.behaviors.forEach((el: BehaviorInterface) => {
            if (el.type === type) {
                el.enabled = false;
            }
        });

        obj.userData.behaviors.push(behavior);
    }
    callback && callback();
};

export const pasteBehaviorForObject = (
    behavior: BehaviorInterface,
    editor: Editor,
    object: any,
    callback?: () => void,
) => {
    if (!object || object instanceof Array) return;
    const type = behavior.type;

    const obj = editor.objectByUuid(object.uuid) || object;
    if ((OBJECT_TYPES_WHITE_LIST.includes(type) || OBJECT_TYPES_ASSETS_LIST.includes(type)) && obj) {
        if (!obj.userData.behaviors) {
            obj.userData.behaviors = [];
        }
        if (obj.userData.behaviors && obj.userData.behaviors.some((behavior: any) => behavior.type === type)) {
            obj.userData.behaviors.forEach((el: BehaviorInterface) => {
                if (el.type === type) {
                    el.enabled = false;
                }
            });
        }

        switch (type) {
            case OBJECT_TYPES.CHARACTER:
                enableCharacterPhysics(obj);
                break;
            case OBJECT_TYPES.THROWABLES:
                enableThrowablePhysics(obj);
                break;
            default:
                break;
        }

        obj.userData.behaviors.push(behavior);
    }
    callback && callback();
};
