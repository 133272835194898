import styled, {keyframes} from "styled-components";
import {flexCenter, regularFont} from "../../../../assets/style";

const spinningAnimation = keyframes`
  0% {
      transform: rotate(0deg);
  }
  100% {
      transform: rotate(360deg);
  }
`;

export const Container = styled.div`
    position: relative;
    background-color: var(--theme-container-main-dark);
`;

export const Wrapper = styled.div`
    padding: 20px 20px 40px;
    display: flex;
    flex-direction: column;
    gap: 60px;
`;

export const MainContent = styled.div`
    display: flex;
    align-items: flex-start;
    gap: 20px;
`;

export const GameBlock = styled.div`
    border-radius: 16px;
    display: flex;
    flex-direction: column;
    gap: 16px;
    width: 100%;
    .default-img {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }
    .iframeWrapper {
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;
        width: 100%;
        border-radius: 16px;
        border: none;
        background-color: var(--theme-grey-bg);
        width: 100%;
        padding-bottom: 56.25%;
        position: relative;
    }
    iframe {
        width: 100%;
        height: 100%;
        border-radius: 16px;
        background-color: var(--theme-homepage-main-bg);
        overflow: hidden;
        border: none;
        position: absolute;
        top: 0;
        left: 0;
    }

    .fullScreenIframe {
        position: fixed;
        background-color: var(--theme-grey-bg);
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: 99999999;

        iframe {
            border-radius: 0;
            border: 0;
        }
    }

    .fullScreenBtn {
        position: absolute;
        top: 20px;
        right: 20px;
        background-color: var(--theme-grey-bg-secondary-button);
        border-radius: 8px;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 40px;
        height: 40px;
        z-index: 1;
        img {
            width: 12px;
            height: 12px;
        }
    }

    .spinning {
        animation-name: ${spinningAnimation};
        animation-iteration-count: infinite;
        animation-duration: 1s;
    }
`;

export const Info = styled.div`
    display: flex;
    flex-direction: column;
    gap: 40px;
    width: 100%;
`;

export const GameDataWrapper = styled.div`
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    width: 100%;
    @media only screen and (max-width: 767px) {
        flex-direction: column;
        gap: 16px;
        justify-content: center;
        .mobileWrapper-stats {
            width: 100%;
            display: flex;
            align-items: flex-start;
            justify-content: space-between;
        }
    }
`;

export const DetailsBlock = styled.div`
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    width: 100%;
    gap: 60px;

    @media only screen and (max-width: 767px) {
        flex-direction: column;
        gap: 20px;
        justify-content: center;
    }
`;

export const GameImg = styled.div<{image: string}>`
    width: 101px;
    height: 101px;
    border-radius: 12px;
    ${({image}) =>
        image &&
        `
background-image: url(${image});
background-repeat: no-repeat;
background-size: cover;
background-position: center;
`}
`;

export const GameDetails = styled.div`
    ${flexCenter};
    flex-direction: column;
    align-items: flex-start;
    row-gap: 6px;
    .name {
        ${regularFont("xl")};
        font-weight: var(--theme-font-bold);
        white-space: nowrap;
    }

    .producedBy {
        ${regularFont("s")};
        font-weight: var(--theme-font-medium);
        color: var(--theme-font-unselected-color);
    }
`;

export const RightInfo = styled.div`
    color: #fff;
    display: flex;
    flex-direction: column;
    gap: 24px;
    width: 320px;
`;

export const RightInfoBlock = styled.div`
    display: flex;
    flex-direction: column;
    gap: 8px;
    > span {
        color: var(--theme-font-disabled) !important;
        ${regularFont("xs")};
        font-weight: 500;
    }
`;

export const Tags = styled.div`
    display: flex;
    gap: 12px;
    flex-wrap: wrap;
    width: 100%;
`;

export const Tag = styled.div`
    color: white;
    ${regularFont("xs")};
    font-weight: 500;
    padding: 8px 12px 8px;
    width: fit-content;
    display: flex;
    align-items: center;
    gap: 4px;
    background: var(--theme-grey-bg);
    border-radius: 8px;
    span {
        color: var(--theme-homepage-link-color);
    }
`;

export const Description = styled.div`
    display: flex;
    flex-direction: column;
    gap: 20px;
    width: 100%;

    .dateswrapper {
        display: flex;
        gap: 32px;
        @media only screen and (max-width: 1023px) {
            flex-direction: column;
            gap: 20px;
        }
    }

    .title {
        ${regularFont("xs")};
        color: var(--theme-font-disabled);
        font-weight: 500;
    }

    .value {
        ${regularFont("xs")};
        font-weight: 500;
    }

    .info {
        display: flex;
        gap: 4px;
    }

    .description {
        display: flex;
        flex-direction: column;
        gap: 4px;
    }
`;

export const Games = styled.div`
    display: flex;
    flex-direction: column;
    gap: 20px;
    min-width: 320px;
    > span {
        ${regularFont("l")};
        font-weight: 700;
    }
`;

export const Game = styled.div<{
    image: string;
}>`
    min-height: 230px;
    width: 100%;
    max-width: 340px;
    background-color: var(--theme-homepage-box-bg-primary);
    border-radius: 12px;
    cursor: pointer;
    ${({image}) =>
        image &&
        `
background-image: url(${image});
background-repeat: no-repeat;
background-size: cover;
background-position: center;
position: relative;
`}
`;

export const UrlWrapper = styled.div`
    background-color: var(--theme-grey-bg);
    border-radius: 10px;
    width: 100%;
    height: 40px;
    position: relative;
    display: flex;
    align-items: center;

    .textWrapper {
        width: 100%;
        padding: 0 8px;
        ${regularFont("xxs")};
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
        color: #d4d4d8;
    }

    .copyBtn {
        position: absolute;
        right: 8px;
        max-width: 24px;
        top: 50%;
        transform: translateY(-50%);
        cursor: pointer;
    }

    .copiedMessage {
        position: absolute;
        bottom: calc(100% + 16px);
        left: 50%;
        transform: translateX(-50%);
        opacity: 0;
        background: rgba(255, 255, 255, 0.1);
        &.visible {
        }
    }
`;

export const CopyMessage = styled.div<{$visible?: boolean}>`
    box-sizing: border-box;
    position: absolute;
    top: -8px;
    transform: translate(-50%, -100%);
    left: 50%;
    opacity: 0;
    pointer-events: none;
    background: rgba(255, 255, 255, 0.1);
    transition: opacity 0.3s ease;
    padding: 8px 16px;
    border-radius: 16px;
    ${({$visible}) => $visible && `opacity: 1;`}
`;

export const ShareButtonsWrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 16px;
`;

export const GamesSection = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    row-gap: 16px;
    width: 100%;
`;

export const StyledGamesList = styled.div`
    width: 100%;
    margin: 0 auto;

    display: grid;
    grid-template-columns: repeat(4, 1fr);
    align-items: start;
    justify-items: start;
    gap: 20px;

    @media only screen and (max-width: 1023px) {
        grid-template-columns: repeat(3, 1fr);
    }

    @media only screen and (max-width: 767px) {
        grid-template-columns: repeat(1, 1fr);
    }
`;

export const SectionTitle = styled.div`
    ${regularFont("l")};
    font-weight: var(--theme-font-bold);
    width: 100%;
    color: white;
`;
