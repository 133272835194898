import {useEffect} from "react";
import Carousel from "react-multi-carousel";
import {useMediaQuery} from "usehooks-ts";
import "react-multi-carousel/lib/styles.css";

import {useHomepageContext} from "../../../../context";
import {IBasicGameInterface} from "../../types";
import {HeroSection} from "./HeroSection/HeroSection";

export const HeroCarousel = () => {
    const {games} = useHomepageContext();
    const isTablet = useMediaQuery("(min-width: 768px) and (max-width: 1023px)");
    const isMobile = useMediaQuery("(max-width: 767px)");

    const responsive = {
        desktop: {
            breakpoint: {max: 4000, min: 0},
            items: 1,
            partialVisibilityGutter: 60,
        },
    };

    const chunkArray = (array: IBasicGameInterface[], size: number) => {
        const result = [];
        for (let i = 0; i < array.length; i += size) {
            result.push(array.slice(i, i + size));
        }
        return result;
    };

    const gamesAmount = isMobile ? 1 : isTablet ? 3 : 5;
    const groupedGames = chunkArray(games, gamesAmount);

    const adjustCarouselHeight = () => {
        const heroGrid = document.getElementById("heroGrid");
        const carouselTrack = document.querySelector(".react-multi-carousel-track");

        if (heroGrid && carouselTrack instanceof HTMLElement) {
            const heroHeight = heroGrid.offsetHeight;
            carouselTrack.style.height = `${heroHeight}px`;
        }
    };

    useEffect(() => {
        adjustCarouselHeight();

        window.addEventListener("resize", adjustCarouselHeight);
        return () => window.removeEventListener("resize", adjustCarouselHeight);
    }, []);

    return (
        <Carousel responsive={responsive} partialVisible itemClass="carousel-item" arrows>
            {groupedGames.map((games, index) => (
                <HeroSection selectedGames={games} key={index} />
            ))}
        </Carousel>
    );
};
