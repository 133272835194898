import {useNavigate} from "react-router-dom";

import {ROUTES} from "../../../../../AppRouter";
import {ADMIN_LABEL} from "../../../../../context/HomepageContext";
import {useAuthorizationContext, useHomepageContext} from "../../../../../context";
import {getProfilePath, truncateName} from "../../../../../v2/pages/services";

import bugIcon from "../../icons/bug-ant.svg";
import adminIcon from "../../icons/admin.svg";

import {Avatar} from "../../Avatar/Avatar";
import {DASHBOARD_MENU, PAGES} from "../../GamesDashboard/constants";
import {List, Nav, Top, ListItem} from "./SideNavigation.style";

export const SideNavigation = () => {
    const {dbUser, isAdmin} = useAuthorizationContext();
    const {activePage} = useHomepageContext();
    const navigate = useNavigate();
    const username = dbUser?.username || dbUser?.name;

    const navigateToProfile = () => {
        if (dbUser) {
            if (!username) return console.error("Username field doesn't exist.");
            navigate(getProfilePath(username));
        }
    };

    const handleActivePage = (label: PAGES) => {
        const key = Object.keys(PAGES).find(key => PAGES[key as keyof typeof PAGES] === label);
        if (key) {
            navigate(`${ROUTES[key as keyof typeof ROUTES]}`);
        }
    };

    return (
        <Nav>
            <Top onClick={navigateToProfile}>
                <Avatar name={username} image={dbUser?.avatar} size={32} />
                {username && truncateName(username!, 16)}
            </Top>
            <List>
                {DASHBOARD_MENU.map(({label, icon}) => {
                    if (label === PAGES.ADMIN_PANEL && !isAdmin) return;
                    return (
                        <ListItem
                            $active={activePage === label}
                            key={label}
                            onClick={() => handleActivePage(label)}
                            $bottomItem={label === PAGES.ADMIN_PANEL || (label === PAGES.SETTINGS && !isAdmin)}>
                            <img className="icon" src={icon} alt="" /> {label}
                        </ListItem>
                    );
                })}
                <ListItem $active={false} $disabled>
                    <img className="icon" src={bugIcon} alt="" /> Report a Bug
                </ListItem>
            </List>
        </Nav>
    );
};
