import {FormEvent, useRef, useState} from "react";
import {Oval} from "react-loader-spinner";
import {toast} from "react-toastify";
import {BottomBar, Container, StyledTextArea, SubmitButton, Title} from "./AiAssistant.style";
import submitIcon from "./icons/submit.svg";
import {useOnClickOutside} from "usehooks-ts";
import global from "../../../../global";
import Application from "../../../../Application";
import SetPositionCommand from "../../../../command/SetPositionCommand.js";
import {getBehaviorsFromText} from "./utils/getBehaviorFromText";
import {getScaleOperationFromText, SCALE_OPERATION_TYPE, ScaleOperation} from "./utils/getScaleOperationFromText";
import {OBJECT_TYPES} from "../../../../types/editor";
import EventBus from "../../../../behaviors/event/EventBus";
import {Object3D, Vector3} from "three";

const regex = /\bchange (?:model )?to (\w+)\b/i;

const prompts = [
    {
        id: "0",
        text: "Turn this into a weapon",
    },
    {
        id: "1",
        text: "Make this 3 times the size",
    },
    {
        id: "2",
        text: "Make this level 5pm lighting",
    },
    {
        id: "3",
        text: "Make object into a health pack",
    },
    {
        id: "4",
        text: "Turn this into a jump pad",
    },
    {
        id: "5",
        text: "Make this object into a enemy",
    },
];

type Props = {
    isOpen: boolean;
    onClose: () => void;
};

export const AiAssistant = ({isOpen, onClose}: Props) => {
    const app = global.app as Application;

    const editor = app?.editor;
    const [value, setValue] = useState("");
    const [selectedPrompt, setSelectedPrompt] = useState<string>("");
    const ref = useRef<HTMLFormElement>(null);
    const [loadingAI, setLoadingAI] = useState(false);

    useOnClickOutside(ref, onClose);

    const handlePromptClick = (prompt: any) => {
        setValue(prompt.text);
        setSelectedPrompt(prompt.id);
    };

    const handleTextAreaChange = (value: string) => {
        setSelectedPrompt("");
        setValue(value);
    };

    const updateState = () => {
        app.call(`objectChanged`, app.editor, editor?.selected);
        app.call(`objectUpdated`, app.editor, editor?.selected);
    };

    const handlePromptSubmit = () => {
        if (editor && editor.selected && !(editor.selected instanceof Array)) {
            switch (selectedPrompt) {
                case "0":
                    // Turn this into a weapon
                    EventBus.instance.send("behaviors.attach", {
                        objectId: editor.selected.uuid,
                        types: [OBJECT_TYPES.WEAPON],
                    });
                    break;
                case "1":
                    if (editor.selected && !(editor.selected instanceof Array)) {
                        EventBus.instance.send("transform.scale", {
                            objectId: editor.selected.uuid,
                            values: {
                                x: +editor.selected.scale.x * 3,
                                y: +editor.selected.scale.y * 3,
                                z: +editor.selected.scale.z * 3,
                            },
                        });
                    }
                    break;
                case "2":
                    // Make this level 5pm lighting
                    break;
                case "3":
                    // Make object into a health pack
                    EventBus.instance.send("behaviors.attach", {
                        objectId: editor.selected.uuid,
                        types: [OBJECT_TYPES.CONSUMABLE],
                    });
                    break;
                case "4":
                    // Turn this into a jump pad
                    EventBus.instance.send("behaviors.attach", {
                        objectId: editor.selected.uuid,
                        types: [OBJECT_TYPES.PLATFORM],
                    });

                    EventBus.instance.send("behaviors.update", {
                        objectId: editor.selected.uuid,
                        behaviors: [
                            {
                                type: OBJECT_TYPES.PLATFORM,
                                enableJumpPad: true,
                            },
                        ],
                    });
                    break;
                case "5":
                    // Make this object into a enemy
                    EventBus.instance.send("behaviors.attach", {
                        objectId: editor.selected.uuid,
                        types: [OBJECT_TYPES.ENEMY],
                    });
                    break;
                default:
                    break;
            }
        }
    };

    const scaleBasedOnPrompt = (operation: ScaleOperation) => {
        if (editor && editor.selected && !(editor.selected instanceof Array)) {
            const {scaleFactor, type} = operation;
            const {x, y, z} = editor.selected.scale;
            const vector =
                type === SCALE_OPERATION_TYPE.SCALE_UP
                    ? new Vector3(x * scaleFactor, y * scaleFactor, z * scaleFactor)
                    : new Vector3(x / scaleFactor, y / scaleFactor, z / scaleFactor);

            EventBus.instance.send("transform.scale", {
                objectId: editor.selected.uuid,
                values: {
                    x: vector.x,
                    y: vector.y,
                    z: vector.z,
                },
            });
        }
    };

    const changeModelCommand = async (userPrompt: string) => {
        const match = userPrompt.match(regex);
        const replaceableModelName = match ? match[1] : null;

        if (replaceableModelName) {
            const targetModel = app.editor?.scene.children.find(el => el.name.toLowerCase() === replaceableModelName);
            const editor = app.editor;
            const selected: any = app.editor?.selected;
            if (targetModel && !!editor && !!selected) {
                const selectedPosition = selected.position.clone();
                const targetModelPosition = targetModel.position.clone();
                await editor.execute(
                    new SetPositionCommand(
                        selected,
                        new Vector3(targetModelPosition.x, targetModelPosition.y, targetModelPosition.z),
                    ),
                );

                await editor.execute(
                    new SetPositionCommand(
                        targetModel,
                        new Vector3(selectedPosition.x, selectedPosition.y, selectedPosition.z),
                    ),
                );
            } else {
                toast.error(
                    `Failed to execute command. You need to select an object and have a ${replaceableModelName} on the scene.`,
                );
            }
        }
    };

    const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        setLoadingAI(true);

        setTimeout(() => {
            setLoadingAI(false);

            if (selectedPrompt) {
                handlePromptSubmit();
                clearInputs();
                return;
            } else if (editor) {
                const behaviors = getBehaviorsFromText(value);

                if (editor.selected && !(editor.selected instanceof Array)) {
                    behaviors.forEach(behavior => {
                        EventBus.instance.send("behaviors.attach", {
                            objectId: (editor.selected as Object3D).uuid,
                            types: behavior,
                        });
                    });
                }

                const scaleOperation = getScaleOperationFromText(value);

                scaleOperation && scaleBasedOnPrompt(scaleOperation);
            }

            const lowerCaseValue = value.trim().toLowerCase();
            changeModelCommand(lowerCaseValue);

            clearInputs();
        }, 3000);
    };

    const clearInputs = () => {
        setValue("");
        setSelectedPrompt("");
    };

    return (
        <Container ref={ref} $isOpen={isOpen} onSubmit={e => handleSubmit(e)}>
            <Title>AI Mod</Title>
            {loadingAI ? (
                <Oval
                    visible
                    height="40"
                    width="40"
                    color="#0284c7"
                    secondaryColor="#333"
                    ariaLabel="oval-loading"
                    wrapperStyle={{}}
                    wrapperClass="loaderWrapper"
                />
            ) : (
                <StyledTextArea
                    value={value}
                    onChange={e => handleTextAreaChange(e.target.value)}
                    placeholder="Change all my square primitives to the color blue."
                />
            )}
            {/* <PromptWrapper>
                {prompts.map(prompt => (
                    <PromptItem
                        key={prompt.id}
                        $isSelected={selectedPrompt === prompt.id}
                        onClick={() => handlePromptClick(prompt)}>
                        {prompt.text}
                    </PromptItem>
                ))}
            </PromptWrapper> */}
            <BottomBar>
                <SubmitButton disabled={!value} type="submit">
                    <img src={submitIcon} alt="Submit" />
                </SubmitButton>
            </BottomBar>
        </Container>
    );
};
