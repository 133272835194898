import {IBasicGameInterface} from "../../../../../v2/pages/types";
import {SingleGame} from "../../SingleGame/SingleGame";
import {MainGame, StyledSection} from "./HeroSection.style";

interface Props {
    selectedGames: IBasicGameInterface[];
}

export const HeroSection = ({selectedGames}: Props) => {
    return (
        <StyledSection id="heroGrid">
            <MainGame>
                <SingleGame item={selectedGames[0]} heroSection />
            </MainGame>
            {selectedGames.map((game, index) => {
                if (index === 0) return;
                return <SingleGame item={game} key={game.ID} heroSection />;
            })}
        </StyledSection>
    );
};
