/*
 * Copyright 2017-2020 The ShadowEditor Authors. All rights reserved.
 *
 * Use of this source code is governed by a MIT-style
 * license that can be found in the LICENSE file.
 *
 * For more information, please visit: https://github.com/tengge1/ShadowEditor
 * You can also visit: https://gitee.com/tengge1/ShadowEditor
 */
import TextureSerializer from "./TextureSerializer";
import CanvasTextureSerializer from "./CanvasTextureSerializer";
import CompressedTextureSerializer from "./CompressedTextureSerializer";
import CubeTextureSerializer from "./CubeTextureSerializer";
import DataTextureSerializer from "./DataTextureSerializer";
import DepthTextureSerializer from "./DepthTextureSerializer";
import VideoTextureSerializer from "./VideoTextureSerializer";
import GifTextureSerializer from "./GifTextureSerializer";

const Serializers = {
    CanvasTexture: CanvasTextureSerializer,
    CompressedTexture: CompressedTextureSerializer,
    CubeTexture: CubeTextureSerializer,
    DataTexture: DataTextureSerializer,
    DepthTexture: DepthTextureSerializer,
    VideoTexture: VideoTextureSerializer,
    Texture: TextureSerializer,
    _Texture: TextureSerializer,
    Tl: TextureSerializer,
    Te: GifTextureSerializer,
};

/**
 * TexturesSerializer
 *
 */
class TexturesSerializer {
    toJSON(obj) {
        var serializer = Serializers[obj.constructor.name];
        if (serializer === undefined) {
            serializer = Serializers.Texture;
        }

        return new serializer().toJSON(obj);
    }

    fromJSON(json, parent, server) {
        var generator = json.metadata.generator;
        var serializer = Serializers[generator.replace("Serializer", "")];

        if (generator === "GifTextureSerializer") {
            serializer = Serializers.Te;
        }

        if (serializer === undefined) {
            serializer = Serializers.Texture;
        }

        const texture = new serializer().fromJSON(json, parent, server);

        return texture;
    }
}

export default TexturesSerializer;
