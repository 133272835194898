import styled from "styled-components";
import {regularFont} from "../../../../../assets/style";

export const StyledStats = styled.div`
    display: flex;
    gap: 8px;

    ${regularFont("xxs")};
    font-weight: var(--theme-font-medium);
    .likeButton,
    .shareButton {
        cursor: pointer;
    }

    .data {
        border: 1px solid var(--theme-grey-bg-button);
        padding: 8px 12px;
        border-radius: 64px;
        display: flex;
        gap: 4px;
        align-items: center;
        justify-content: center;
        row-gap: 12px;
        ${regularFont("xxs")};
        font-weight: var(--theme-font-medium);
        .icon {
            width: 12px;
            opacity: 0.78;
        }
    }

    .playButton {
        .icon {
            width: 10px;
        }
    }

    .playButton,
    .likeButton {
        width: 59px;
    }
`;
