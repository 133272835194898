import {useEffect, useState} from "react";
import RemoveObjectCommand from "../../../command/RemoveObjectCommand";
import global from "../../../global";

import arrowIcon from "./icons/arrow.svg";
import lockIcon from "./icons/lock-icon.svg";
import deleteIcon from "./icons/delete-icon.svg";
import behaviorsIcon from "./icons/behaviors-icon.svg";
import cloneIcon from "./icons/clone-icon.svg";
import modelIcon from "./icons/model-icon.svg";
import lightsIcon from "./icons/lights-icon.svg";
import cameraIcon from "./icons/misc-icon.svg";
import meshIcon from "./icons/mesh-icon.svg";

import {ItemMenuText, RightClickMenu} from "../../../ui/common/RightClickMenu/RightClickMenu";
import {
    ExpandItemButton,
    Line,
    ListItem,
    ListItemContent,
    SelectedItemIcon,
    SubList,
    Text,
    TypeImg,
    TypeImgWrapper,
} from "./Tree.style";
import Application from "../../../Application";

enum MENU_OPTION_TYPE {
    CLONE,
    LOCK,
    EMPTY_GROUP,
    GROUP,
    UNGROUP,
    DELETE,
}

interface Props {
    selected: string[] | null;
    data: any;
    index: number;
    leaf: boolean;
    lockedItems: string[];
    isBehaviors: boolean;
    isGroup: boolean;
    onLockClick: (value: string) => void;
    handleClick: (event: React.MouseEvent<HTMLLIElement, MouseEvent>) => void;
    onDoubleClick: (value: any, event: any) => void;
    onDrop: (value: any, newParentValue: any, newBeforeValue: any) => void;
    onExpand: (value: any, event: any) => void;
    scrollToSelected: () => void;
    createEmptyGroup: () => void;
}

export const TreeItem = ({
    selected,
    data,
    index,
    leaf,
    lockedItems,
    isBehaviors,
    isGroup,
    onLockClick,
    handleClick,
    onDoubleClick,
    onDrop,
    onExpand,
    scrollToSelected,
    createEmptyGroup,
}: Props) => {
    const [expanded, setExpanded] = useState<boolean>();
    const [menuItem, setMenuItem] = useState("");
    const [currentDrag, setCurrentDrag] = useState<EventTarget>();
    const [menuPosition, setMenuPosition] = useState<{x: number; y: number} | null>(null);

    const app = global.app as Application;

    const getTypeImage = (obj: any) => {
        if (obj.isLight) {
            return lightsIcon;
        }

        if (obj.isCamera) {
            return cameraIcon;
        }

        if (obj.isMesh) {
            return meshIcon;
        }

        if (obj.isObject3D) {
            return modelIcon;
        }

        return modelIcon;
    };

    const getDefaultName = (obj: any) => {
        if (obj.isLight) {
            return "Light";
        }

        if (obj.isCamera) {
            return "Camera";
        }

        if (obj.isObject3D) {
            return "3D Model";
        }

        return data.cls;
    };

    const handleUngroup = (event: any, value: any) => {
        event.stopPropagation();
        if (!app || !app.editor) return;

        const object = app.editor.objectByUuid(value);
        app.editor.ungroupElements(object as any);
    };

    const handleGroup = (event: any) => {
        event.stopPropagation();
        if (!app || !app.editor) return;
        const selected = app.editor.selected as any;

        if (selected.length > 1) {
            app.editor.groupElements(selected);
        }
    };

    const handleRightClick = (event: any) => {
        event.stopPropagation();

        const value = event.target.getAttribute("value");
        setMenuItem(value);

        const x = event.clientX;
        const y = event.clientY;

        setMenuPosition({x, y});
    };

    const handleDrop = (event: React.DragEvent<HTMLLIElement>) => {
        if (!app?.editor) return;

        event.preventDefault();
        event.stopPropagation();

        let target = event.currentTarget;
        const id = event.dataTransfer.getData("text/plain");
        const droppedElement = document.getElementById(id);

        if (target === droppedElement) {
            return;
        }

        target.classList.remove("dragTop");
        target.classList.remove("dragBottom");
        target.classList.remove("drag");

        if (typeof onDrop === "function") {
            const area = event.nativeEvent.offsetY / target.clientHeight;
            const currentValue =
                droppedElement instanceof HTMLLIElement ? droppedElement?.getAttribute("value") || "" : "";

            let newParentValue = null;
            let newBeforeValue = null;

            const targetParent = target.parentElement?.parentElement;
            const targetValue = target.getAttribute("value");
            const nextSiblingValue = target.nextElementSibling ? target.nextElementSibling.getAttribute("value") : null;

            if (area < 0.25) {
                newParentValue = targetParent?.getAttribute("value") || app.editor.scene.uuid;
                newBeforeValue = targetValue;
            } else if (area > 0.75) {
                newParentValue = targetParent?.getAttribute("value") || app.editor.scene.uuid;
                newBeforeValue = nextSiblingValue;
            } else {
                newParentValue = targetValue;
                newBeforeValue = null;
            }
            const object = app.editor.objectByUuid(currentValue);
            const newParent = app.editor.objectByUuid(newParentValue || "");

            if (object && (newParentValue === null || newParent)) {
                onDrop(currentValue, newParentValue, newBeforeValue);
            } else {
                console.error("Cannot execute MoveObjectCommand: newParent is undefined");
            }
        }
    };

    const handleDoubleClick = (event: any) => {
        const value = event.target.getAttribute("value");

        if (value) {
            onDoubleClick && onDoubleClick(value, event);
        }
    };

    const handleExpandNode = (event: any) => {
        event.stopPropagation();
        const value = event.target.getAttribute("data-value");
        setExpanded(prevState => !prevState);
        onExpand && onExpand(value, event);
    };

    const handleClone = (event: any, value: any) => {
        event.stopPropagation();
        app?.editor?.cloneObjectByUuid(value);
        scrollToSelected();
    };

    const handleDrag = (event: React.DragEvent<HTMLLIElement>) => {
        event.stopPropagation();
        setCurrentDrag(event.currentTarget || undefined);
    };

    const handleDragStart = (event: React.DragEvent<HTMLLIElement>) => {
        event.stopPropagation();
        event.dataTransfer.setData("text/plain", event.currentTarget.id);
    };

    const handleDragOver = (event: React.DragEvent<HTMLLIElement>) => {
        event.preventDefault();
        event.stopPropagation();

        let target = event.currentTarget;

        if (target === currentDrag) {
            return;
        }

        let area = event.nativeEvent.offsetY / target.clientHeight;

        if (area < 0.25) {
            target.classList.add("dragTop");
        } else if (area > 0.75) {
            target.classList.add("dragBottom");
        } else {
            target.classList.add("drag");
        }
    };

    const handleDragLeave = (event: React.DragEvent<HTMLLIElement>) => {
        event.preventDefault();
        event.stopPropagation();

        let target = event.currentTarget;

        if (target === currentDrag) {
            return;
        }

        target.classList.remove("dragTop");
        target.classList.remove("dragBottom");
        target.classList.remove("drag");
    };

    const handleDelete = (event: any, value: any) => {
        event.stopPropagation();
        if (!app?.editor) return;
        let object = app.editor.objectByUuid(value);

        if (object === null || object?.parent === null) {
            return;
        }

        app?.editor?.execute(new (RemoveObjectCommand as any)(object));
    };

    const closeMenu = () => {
        setMenuPosition(null);
        setMenuItem("");
    };

    useEffect(() => {
        setExpanded(!!data?.expanded);
    }, []);

    const handleMenuClick = (e: React.MouseEvent<HTMLDivElement, MouseEvent>, type: MENU_OPTION_TYPE) => {
        closeMenu();
        switch (type) {
            case MENU_OPTION_TYPE.CLONE:
                handleClone(e, data.value);
                break;

            case MENU_OPTION_TYPE.LOCK:
                onLockClick(data.value);
                break;

            case MENU_OPTION_TYPE.EMPTY_GROUP:
                createEmptyGroup();
                break;

            case MENU_OPTION_TYPE.UNGROUP:
                handleUngroup(e, data.value);
                break;

            case MENU_OPTION_TYPE.GROUP:
                handleGroup(e);
                break;

            case MENU_OPTION_TYPE.DELETE:
                handleDelete(e, data.value);
                break;

            default:
                break;
        }
    };

    const children =
        expanded && data.children?.length > 0 ? (
            <SubList $collapse={false}>
                {data.children.map((child: any, idx: number) => (
                    <TreeItem
                        key={child.value}
                        scrollToSelected={scrollToSelected}
                        selected={selected}
                        data={child}
                        index={idx}
                        leaf={!child.children || child.children.length === 0}
                        lockedItems={lockedItems}
                        isBehaviors={isBehaviors}
                        isGroup={isGroup}
                        onLockClick={onLockClick}
                        handleClick={handleClick}
                        onDoubleClick={onDoubleClick}
                        onDrop={onDrop}
                        onExpand={onExpand}
                        createEmptyGroup={createEmptyGroup}
                    />
                ))}
            </SubList>
        ) : null;

    return (
        <ListItem
            className={!!selected?.includes(data.value) ? "selected" : ""}
            $selected={!!selected?.includes(data.value)}
            value={data.value}
            key={data.value + index}
            onContextMenu={handleRightClick}
            onClick={data.onClick || handleClick}
            onDoubleClick={handleDoubleClick}
            draggable
            onDrag={handleDrag}
            onDragStart={handleDragStart}
            onDragOver={handleDragOver}
            onDragLeave={handleDragLeave}
            onDrop={handleDrop}
            id={data.value + index}>
            <ListItemContent className="node-content">
                <TypeImgWrapper>
                    <TypeImg
                        className="itemIcon"
                        $selected={!!selected?.includes(data.value)}
                        $behavior={isBehaviors}
                        src={isBehaviors ? behaviorsIcon : getTypeImage(data)}
                    />
                </TypeImgWrapper>

                <Text href={""} $selected={!!selected?.includes(data.value)} className="text">
                    {data.text}
                    {!data.text && <>{getDefaultName(data)}</>}
                </Text>
                <Line $top className="line" />
                <Line $bottom className="line" />

                {data.children.length > 0 ? (
                    <ExpandItemButton
                        $selected={!!selected?.includes(data.value)}
                        $open={!leaf && !!expanded}
                        onClick={handleExpandNode}
                        data-value={data.value}>
                        <img data-value={data.value} src={arrowIcon} />
                    </ExpandItemButton>
                ) : (
                    <Line $middle className="line" />
                )}

                {!data.noLock && (
                    <SelectedItemIcon
                        $lockClose={lockedItems.includes(data.value)}
                        $rightPosition="28px"
                        $selected={!!selected?.includes(data.value)}
                        data-value={data.value}
                        onClick={() => onLockClick(data.value)}
                        src={lockIcon}
                    />
                )}
                {data.value !== "game-manager-id" && (
                    <>
                        <SelectedItemIcon
                            $rightPosition="48px"
                            $selected={!!selected?.includes(data.value)}
                            src={cloneIcon}
                            onClick={e => handleClone(e, data.value)}
                            height={13}
                        />
                        <SelectedItemIcon
                            $rightPosition="6px"
                            $selected={!!selected?.includes(data.value)}
                            src={deleteIcon}
                            onClick={e => handleDelete(e, data.value)}
                        />
                        {menuItem === data.value && menuPosition && (
                            <RightClickMenu
                                onClickoutsideCallback={closeMenu}
                                left={menuPosition.x}
                                top={menuPosition.y}>
                                <ItemMenuText onClick={e => handleMenuClick(e, MENU_OPTION_TYPE.CLONE)}>
                                    Duplicate
                                </ItemMenuText>
                                <ItemMenuText onClick={e => handleMenuClick(e, MENU_OPTION_TYPE.LOCK)}>
                                    {lockedItems.includes(data.value) ? "Unlock" : "Lock"}
                                </ItemMenuText>
                                <ItemMenuText onClick={e => handleMenuClick(e, MENU_OPTION_TYPE.EMPTY_GROUP)}>
                                    Create Empty Group
                                </ItemMenuText>
                                {isGroup && (
                                    <ItemMenuText onClick={e => handleMenuClick(e, MENU_OPTION_TYPE.UNGROUP)}>
                                        Ungroup
                                    </ItemMenuText>
                                )}
                                {(app.editor?.selected as any)?.length > 1 && (
                                    <ItemMenuText onClick={e => handleMenuClick(e, MENU_OPTION_TYPE.GROUP)}>
                                        Group
                                    </ItemMenuText>
                                )}
                                <ItemMenuText onClick={e => handleMenuClick(e, MENU_OPTION_TYPE.DELETE)} $red>
                                    Delete
                                </ItemMenuText>
                            </RightClickMenu>
                        )}
                    </>
                )}
            </ListItemContent>

            {leaf ? null : children}
        </ListItem>
    );
};
