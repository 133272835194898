import React from "react";
import {useMediaQuery} from "usehooks-ts";
import {useNavigate} from "react-router";

import {getProfilePath, truncateName} from "../../../services";
import plusIcon from "../../../../../editor/assets/v2/icons/plus-bold.svg";
import {StyledButton} from "../../../../../editor/assets/v2/common/StyledButton";
import {IUser} from "../../../types";

import {AuthorContainer, AvatarContainer, UserName, WrapperInfo} from "./GameAuthor.style";
import {GameDetails} from "../PlayPage.style";

interface Props {
    gameName: string | null;
    gameOwner: IUser | null;
}

export const GameAuthor = ({gameName, gameOwner}: Props) => {
    const isMobile = useMediaQuery("(max-width: 767px)");
    const navigate = useNavigate();
    const username = gameOwner?.username || gameOwner?.name;

    const handleGoToProfile = () => {
        if (!gameOwner) return;
        if (!username) return console.error("Username field doesn't exist.");
        navigate(getProfilePath(username));
    };

    return (
        <AuthorContainer>
            {!isMobile && (
                <GameDetails>
                    <span className="name">{gameName}</span>
                </GameDetails>
            )}
            <WrapperInfo>
                <UserName>
                    <AvatarContainer $avatar={gameOwner?.avatar || undefined} onClick={handleGoToProfile} />
                    <div className="container">
                        <div onClick={handleGoToProfile}>{username && truncateName(username, 16)}</div>
                        <StyledButton width="85px" onClick={e => e.stopPropagation()} isGreySecondary>
                            <img className="plus-icon" src={plusIcon} alt="" /> <span className="follow">Follow</span>
                        </StyledButton>
                    </div>
                </UserName>
            </WrapperInfo>
        </AuthorContainer>
    );
};
