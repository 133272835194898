import {useEffect, useState} from "react";
import {useMediaQuery} from "usehooks-ts";
import {FrazeButton} from "../FrazeButton/FrazeButton";
import {Side, Wrapper} from "./RecentSearches.style";
import {IBasicGameInterface, SEARCH_GAME_QUERY} from "../../types";
import {saveSearchToLocalStorage} from "../../services";
import {FilterSelect} from "../FilterSelect/FilterSelect";

interface Props {
    queryParams: Partial<Record<SEARCH_GAME_QUERY, string>> | undefined;
    results: IBasicGameInterface[];
    setResults: React.Dispatch<React.SetStateAction<IBasicGameInterface[]>>;
}

export const RecentSearches = ({queryParams, results, setResults}: Props) => {
    const isMobile = useMediaQuery("(max-width: 767px)");
    const [recentSearches, setRecentSearches] = useState<string[]>([]);

    useEffect(() => {
        const storedSearchHistory = localStorage.getItem("searchHistory");
        if (storedSearchHistory) {
            setRecentSearches(JSON.parse(storedSearchHistory));
        }
    }, []);

    useEffect(() => {
        if (queryParams) {
            const tag = queryParams.tags ? `#${queryParams.tags}` : null;
            const name = queryParams.name || null;
            let searchResults;
            if (tag) {
                searchResults = saveSearchToLocalStorage(tag);
            }
            if (name) {
                searchResults = saveSearchToLocalStorage(name);
            }
            searchResults && setRecentSearches(searchResults);
        }
    }, [queryParams]);

    const clearHistory = () => {
        localStorage.removeItem("searchHistory");
        setRecentSearches([]);
    };

    return (
        <Wrapper>
            {!isMobile && (
                <Side>
                    {recentSearches?.length > 0 && <div className="recentSearchesLabel">Recent Searches</div>}
                    {recentSearches.map((fraze, index) => (
                        <FrazeButton key={fraze + index} fraze={fraze} />
                    ))}
                </Side>
            )}
            <Side>
                {recentSearches?.length > 0 && !isMobile && (
                    <div className="clearHistoryLabel" onClick={clearHistory}>
                        Clear Search History
                    </div>
                )}
                <FilterSelect results={results} setResults={setResults} />
            </Side>
        </Wrapper>
    );
};
