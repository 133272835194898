import * as THREE from 'three';
import { MOBILE_BUTTON_ACTION_STATES } from '../types/editor';

export class ActionTouchControls {
    private character: THREE.Object3D;

    constructor(character: THREE.Object3D) {
        this.character = character;
        this.loadActionControlButtons();
    }

    private loadActionControlButtons() {
        const buttonContainer = document.createElement('div');
        buttonContainer.style.position = 'fixed';
        buttonContainer.style.bottom = '5%';
        buttonContainer.style.right = '5%';
        buttonContainer.style.zIndex = '3001';
        buttonContainer.style.display = 'flex';
        buttonContainer.style.flexDirection = 'column';
        buttonContainer.style.alignItems = 'flex-end';
        buttonContainer.classList.add('character-state-control-button');

        this.addTouchActions(buttonContainer);

        const createButton = (
            innerColor: string,
            outerColor: string,
            actionType: typeof MOBILE_BUTTON_ACTION_STATES[keyof typeof MOBILE_BUTTON_ACTION_STATES]
        ) => {

            const outerCircle = document.createElement('div');
            outerCircle.style.width = '50px';
            outerCircle.style.height = '50px';
            outerCircle.style.borderRadius = '50%';
            outerCircle.style.backgroundColor = outerColor;
            outerCircle.style.display = 'flex';
            outerCircle.style.justifyContent = 'center';
            outerCircle.style.alignItems = 'center';
            outerCircle.style.position = 'relative';
            outerCircle.style.marginBottom = '10px';
            outerCircle.style.background = 'radial-gradient(circle at 50% 0px, #ffffff, rgba(255, 255, 255, 0) 58%)';
            outerCircle.style.boxShadow = 'none';
            outerCircle.style.cursor = 'pointer';
            outerCircle.style.border = '1px solid white';

            const innerCircle = document.createElement('div');
            innerCircle.style.width = '80%';
            innerCircle.style.height = '80%';
            innerCircle.style.borderRadius = '50%';
            innerCircle.style.background = `radial-gradient(circle at 30% 30%, #555555, #000000 80%)`;
            innerCircle.style.boxShadow = `inset 0px 3px 6px rgba(255, 255, 255, 0.3), 
                0px 3px 6px rgba(0, 0, 0, 0.6)`;

            outerCircle.appendChild(innerCircle);

            const onTouchStart = (event: TouchEvent) => {
                event.preventDefault();
                this.handleTouchStart(actionType);
                outerCircle.removeEventListener('touchstart', onTouchStart);
                outerCircle.addEventListener('touchend', onTouchEnd);
            };

            const onTouchEnd = (event: TouchEvent) => {
                event.preventDefault();
                this.handleTouchEnd(actionType);
                outerCircle.removeEventListener('touchend', onTouchEnd);
                outerCircle.addEventListener('touchstart', onTouchStart);
            };

            outerCircle.addEventListener('touchstart', onTouchStart);

            return outerCircle;
        };

        const button1 = createButton('black', 'lightgray', MOBILE_BUTTON_ACTION_STATES.JUMP);
        buttonContainer.appendChild(button1);

        const button2 = createButton('black', 'lightgray', MOBILE_BUTTON_ACTION_STATES.INTERACT);
        buttonContainer.appendChild(button2);

        const lastButton = buttonContainer.lastChild as HTMLElement;
        const buttonWidth = 50;
        lastButton.style.position = 'relative';
        lastButton.style.left = `-${buttonWidth + 10}px`;

        document.body.appendChild(buttonContainer);
    }

    private handleTouchStart(actionType: typeof MOBILE_BUTTON_ACTION_STATES[keyof typeof MOBILE_BUTTON_ACTION_STATES]) {
        if (actionType === MOBILE_BUTTON_ACTION_STATES.JUMP) {
            this.character.userData.touchJump = true;
        } else if (actionType === MOBILE_BUTTON_ACTION_STATES.INTERACT) {
            this.character.userData.touchInteract = true;
        }
    }

    private handleTouchEnd(actionType: typeof MOBILE_BUTTON_ACTION_STATES[keyof typeof MOBILE_BUTTON_ACTION_STATES]) {
        if (actionType === MOBILE_BUTTON_ACTION_STATES.JUMP) {
            this.character.userData.touchJump = false;
        } else if (actionType === MOBILE_BUTTON_ACTION_STATES.INTERACT) {
            this.character.userData.touchInteract = false;
        }
    }

    private addTouchActions(container: HTMLElement) {
        container.addEventListener('touchstart', (event) => event.preventDefault(), { passive: false });
        container.addEventListener('touchmove', (event) => event.preventDefault(), { passive: false });
        container.addEventListener('touchend', (event) => event.preventDefault(), { passive: false });
        container.addEventListener('touchcancel', (event) => event.preventDefault(), { passive: false });
    }
}
