import {useEffect, useRef} from "react";
import {toast} from "react-toastify";

import Application from "../../../../../../../Application";
import global from "../../../../../../../global";
import {truncateName} from "../../../../../../../v2/pages/services";
import closeIcon from "../../../icons/x.svg";
import copyIcon from "../../../icons/copy.svg";
import checkedIcon from "../../../icons/checked.svg";

import {StyledSwitch} from "../../../../common/StyledSwitch";
import {StyledButton} from "../../../../common/StyledButton";
import {Separator} from "../../Separator";
import {CopyURLContainer, Description, Heading, MainHeading, PostPublishHeading, PublishPanelContainer} from "./style";
import {SelectionOfButtons} from "../../SelectionOfButtons";
import {PublishAction} from "../TopMenu";

interface Props {
    handleSettingsSave: (action?: PublishAction) => void;
    closePanel: () => void;
    fullUrl: string;
    isPublic: boolean;
    isCloneable: boolean;
    isPublished: boolean;
    setIsPublic: (value: boolean) => void;
    setIsCloneable: (value: boolean) => void;
}

export const PublishPanel = ({
    handleSettingsSave,
    closePanel,
    fullUrl,
    isPublic,
    isCloneable,
    setIsPublic,
    setIsCloneable,
    isPublished,
}: Props) => {
    const panelRef = useRef<HTMLDivElement>(null);
    const app = global.app as Application;

    useEffect(() => {
        if (!isPublished) {
            setIsCloneable(false);
            setIsPublic(true);
        }
    }, [isPublished]);

    useEffect(() => {
        if (panelRef.current) {
            const handleClickOutside = (event: MouseEvent) => {
                if (panelRef.current && !panelRef.current.contains(event.target as Node)) {
                    closePanel();
                }
            };

            document.addEventListener("mousedown", handleClickOutside);
            return () => document.removeEventListener("mousedown", handleClickOutside);
        }
    }, [panelRef, closePanel]);

    return (
        <PublishPanelContainer ref={panelRef}>
            <MainHeading>
                <div className="label">Settings</div>
                <button className="reset-css" onClick={closePanel}>
                    <img src={closeIcon} alt="close" />
                </button>
            </MainHeading>
            <Separator />
            {app.editor?.isPublished && (
                <>
                    <PostPublishHeading>
                        <img src={checkedIcon} alt="" />
                        <Heading>
                            <div className="label">Your game is live!</div>
                        </Heading>
                    </PostPublishHeading>
                    <Description>
                        Your game is publicaly playable. Copy the direct link below to share with friends!
                    </Description>
                    <CopyURLContainer>
                        <div className="url">{truncateName(fullUrl, 27)}</div>
                        <StyledButton
                            isGrey
                            width="auto"
                            onClick={() => {
                                navigator.clipboard.writeText(fullUrl);
                                toast.success("Copied to clipboard!");
                            }}
                            className="regularWeight">
                            <img src={copyIcon} alt="" />
                        </StyledButton>
                    </CopyURLContainer>
                    <Separator />
                </>
            )}
            <Heading>
                <div className="label">Is public?</div>
                <StyledSwitch checked={isPublic} onChange={() => setIsPublic(!isPublic)} />
            </Heading>
            <Description>Make your game visible in community games</Description>
            <Separator />
            <Heading>
                <div className="label">Is cloneable?</div>
                <StyledSwitch
                    checked={isCloneable}
                    onChange={() => setIsCloneable(!isCloneable)}
                    disabled={!isPublic}
                />
            </Heading>
            <Description>Allow others to duplicate this project and make it their own</Description>
            <Separator margin="12px auto 8px" />
            {app.editor?.isPublished ? (
                <SelectionOfButtons>
                    <StyledButton
                        isGreySecondary
                        onClick={() => handleSettingsSave("unpublish")}
                        width="81px"
                        height="32px !important">
                        <span>Unpublish</span>
                    </StyledButton>
                    <StyledButton isBlue onClick={() => handleSettingsSave()} width="135px" height="32px !important">
                        <span>Update</span>
                    </StyledButton>
                </SelectionOfButtons>
            ) : (
                <StyledButton isBlue onClick={() => handleSettingsSave("publish")} height="32px !important">
                    <span>Publish</span>
                </StyledButton>
            )}
        </PublishPanelContainer>
    );
};
