import {COLLISION_MATERIAL_TYPE} from "../../../../types/editor";
import {CollisionType, IPhysics} from "../types/physics";

export const getPhysics = (physics: any): IPhysics => {
    const {
        enabled,
        shape,
        mass,
        inertia,
        ctype,
        position,
        scale,
        rotation,
        anchorOffset,
        enable_preview,
        collision_material,
    } = physics || {};

    return {
        enabled: enabled || false,
        shape: shape || "btBoxShape",
        mass: mass || 0,
        inertia: {
            x: inertia?.x || 0,
            y: inertia?.y || 0,
            z: inertia?.z || 0,
        },
        ctype: ctype || CollisionType.Static,
        position: {
            x: position?.x || 0,
            y: position?.y || 0,
            z: position?.z || 0,
        },
        scale: {
            x: scale?.x || 1,
            y: scale?.y || 1,
            z: scale?.z || 1,
        },
        rotation: {
            x: rotation?.x || 0,
            y: rotation?.y || 0,
            z: rotation?.z || 0,
        },
        anchorOffset: anchorOffset, // can be undefined
        enable_preview: enable_preview || false,
        collision_material: collision_material || COLLISION_MATERIAL_TYPE.GROUND,
    };
};
