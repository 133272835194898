import {useEffect, useState} from "react";
import * as THREE from "three";
import {toast} from "react-toastify";

import {getGamesByQuery} from "../../api/getGames";
import {IBasicGameInterface} from "../../v2/pages/types";
import avatarPlaceholder from "../assets/avatar-placeholder.svg";
import {IFRAME_MESSAGES} from "../../types/editor";
import {loginWithDiscord} from "../../api/firebase/loginWithCustomToken";
import logo from "../assets/logo.svg";

import {Link} from "../../v2/common/Link/Link";
import {LoadingAnimation} from "../../ui/progress/LoadingAnimation";
import {GamesContainer, Header, Iframe, LegalPages, NoData, Wrapper} from "./AI16ZHome.style";
import {SingleGame} from "./SingleGame/SingleGame";
import DiscordUtils, {IDiscordUser} from "../discord/utils";
import {Avatar} from "../../editor/assets/v2/Avatar/Avatar";

const TAG = "ai16z";

export interface IPlayGame {
    url: string;
    name: string;
    thumbnail?: string;
}

export const AI16ZHome = () => {
    const [communityGames, setCommunityGames] = useState<IBasicGameInterface[]>([]);
    const [showLoding, setShowLoading] = useState(false);
    const [playGameData, setPlayGameData] = useState<IPlayGame>();
    const [discordUser, setDiscordUser] = useState<IDiscordUser>();
    const urlParams = new URLSearchParams(window.location.search);
    const code = urlParams.get("code");

    const generateURL = () => {
        let generatedURL;
        if (playGameData) {
            generatedURL =
                playGameData.url +
                `&username=${discordUser ? discordUser.displayName : "guest" + THREE.MathUtils.generateUUID()}&ai16z=true&customLogo=${playGameData.name}`;

            if (playGameData.thumbnail) {
                generatedURL += `&thumbnail=${playGameData.thumbnail}`;
            }
        }

        return generatedURL;
    };

    const getDiscordUser = async () => {
        if (!code) {
            console.error("Discord code is missing");
            return;
        }

        const params = new URLSearchParams({
            client_id: process.env.REACT_APP_DISCORD_CLIENT_ID || "",
            client_secret: process.env.REACT_APP_DISCORD_CLIENT_SECRET || "",
            grant_type: "authorization_code",
            code,
            redirect_uri: process.env.REACT_APP_DISCORD_REDIRECT_URI || "",
        });

        try {
            const response = await fetch("https://discord.com/api/oauth2/token", {
                method: "POST",
                headers: {
                    "Content-Type": "application/x-www-form-urlencoded",
                },
                body: params,
            });

            const data = await response.json();
            const accessToken = data.access_token;

            const userData = await DiscordUtils.getUserDataFromToken(accessToken);
            setDiscordUser(userData);
            loginWithDiscord(accessToken, userData);
        } catch (error) {
            console.error("Error:", error);
            toast.error("Error fetching discord user");
        }
    };

    useEffect(() => {
        setShowLoading(true);
        const fetchGames = async () => {
            const games = await getGamesByQuery({tags: TAG});
            games && setCommunityGames(games);
            setShowLoading(false);
        };
        fetchGames();

        const handleMessage = (event: any) => {
            const message = event.data;
            if (message === IFRAME_MESSAGES.GAME_CLOSED) {
                setPlayGameData(undefined);
            }
        };

        window.addEventListener("message", handleMessage);
        getDiscordUser();

        return () => {
            window.removeEventListener("message", handleMessage);
        };
    }, []);

    useEffect(() => {
        if (process.env.REACT_APP_DISCORD_OAUTH2_URL && !code) {
            window.location.href = process.env.REACT_APP_DISCORD_OAUTH2_URL;
        }
    }, []);

    return (
        <Wrapper>
            {playGameData?.url && <Iframe src={generateURL()} />}
            <LoadingAnimation show={showLoding} />
            <Header>
                <img src={logo} alt="EARTH.AI" />
                <div className="right-side">
                    <Link href="https://mochi.erth.xyz" text="Create" />
                    <Avatar
                        image={discordUser?.avatarUrl || avatarPlaceholder}
                        name={discordUser?.username || ""}
                        size={45}
                    />
                </div>
            </Header>
            {communityGames?.length > 0 ? (
                <GamesContainer>
                    {communityGames.map(game => (
                        <SingleGame key={game.ID} game={game} setPlayGameData={setPlayGameData} />
                    ))}
                </GamesContainer>
            ) : (
                <NoData>
                    <div className="description">No available games yet.</div>
                </NoData>
            )}
            <LegalPages>
                <a href="https://www.erth.ai/legal/terms-of-service" target="_blank" rel="noopener noreferrer">
                    Terms of Service
                </a>
                <a href="https://www.erth.ai/legal/privacy-policy" target="_blank" rel="noopener noreferrer">
                    Privacy Policy
                </a>
            </LegalPages>
        </Wrapper>
    );
};
