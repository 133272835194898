import React, {useEffect, useState} from "react";
import * as THREE from "three";
import SetPositionCommand from "../../../../../command/SetPositionCommand.js";
import SetRotationCommand from "../../../../../command/SetRotationCommand.js";
import SetScaleCommand from "../../../../../command/SetScaleCommand.js";
import {ITransformValue, TRANSFORMATION_OPTIONS} from "../../../../../types/editor";
import global from "../../../../../global";
import {toast} from "react-toastify";
import {roundNumber} from "../../utils/roundNumber";
import {MovementSection} from "../../common/MovementSection/MovementSection";
import {VisibilitySection} from "./VisibilitySection.js";

interface Props {
    positionValue: ITransformValue;
    rotationValue: ITransformValue;
    scaleValue: ITransformValue;
    setPositionValue: React.Dispatch<React.SetStateAction<ITransformValue>>;
    setRotationValue: React.Dispatch<React.SetStateAction<ITransformValue>>;
    setScaleValue: React.Dispatch<React.SetStateAction<ITransformValue>>;
    isLocked?: boolean;
    hideVisibility?: boolean;
    hideVisibilitySection?: boolean;
}

export const TransformationSection = ({
    positionValue,
    setPositionValue,
    rotationValue,
    setRotationValue,
    scaleValue,
    setScaleValue,
    isLocked,
    hideVisibility = false,
    hideVisibilitySection,
}: Props) => {
    const app = (global as any).app;
    const editor = app?.editor;
    const selected = editor?.selected;

    const [positionOriginalValue, setPositionOriginalValue] = useState<ITransformValue>({x: 0, y: 0, z: 0});
    const [rotationOriginalValue, setRotationOriginalValue] = useState<ITransformValue>({x: 0, y: 0, z: 0});
    const [scaleOriginalValue, setScaleOriginalValue] = useState<ITransformValue>({x: 0, y: 0, z: 0});
    const [scaleLocked, setScaleLocked] = useState(true);

    const cloneSelected = () => {
        const orgPosition = {
            x: roundNumber(selected.position.x, 4),
            y: roundNumber(selected.position.y, 4),
            z: roundNumber(selected.position.z, 4),
        };
        const orgScale = {
            x: roundNumber(selected.scale.x, 4),
            y: roundNumber(selected.scale.y, 4),
            z: roundNumber(selected.scale.z, 4),
        };
        const orgRotation = {
            x: roundNumber(selected.rotation._x * THREE.MathUtils.RAD2DEG, 2),
            y: roundNumber(selected.rotation._y * THREE.MathUtils.RAD2DEG, 2),
            z: roundNumber(selected.rotation._z * THREE.MathUtils.RAD2DEG, 2),
        };

        return {orgPosition, orgRotation, orgScale};
    };

    useEffect(() => {
        if (!selected) return;
        const {orgPosition, orgRotation, orgScale} = cloneSelected();

        setPositionValue(orgPosition);
        setRotationValue(orgRotation);
        setScaleValue(orgScale);

        setPositionOriginalValue(orgPosition);
        setRotationOriginalValue(orgRotation);
        setScaleOriginalValue(orgScale);

        return () => {
            /*if (editor.animationId) {
        cancelAnimationFrame(editor.animationId);
        editor.animationId = null;
      }*/
        };
    }, [selected]);

    const reset = (type: TRANSFORMATION_OPTIONS) => {
        switch (type) {
            case TRANSFORMATION_OPTIONS.POSITION:
                if (isLocked) {
                    toast.warning("Can't reset values. Position locked.");
                } else {
                    setPositionValue(positionOriginalValue);
                    editor.execute(
                        new SetPositionCommand(
                            selected,
                            new THREE.Vector3(
                                positionOriginalValue.x,
                                positionOriginalValue.y,
                                positionOriginalValue.z,
                            ),
                        ),
                    );
                }
                break;

            case TRANSFORMATION_OPTIONS.ROTATION:
                if (isLocked) {
                    toast.warning("Can't reset values. Rotation locked.");
                } else {
                    setRotationValue(rotationOriginalValue);
                    editor.execute(
                        new SetRotationCommand(
                            selected,
                            new THREE.Euler(
                                +rotationOriginalValue.x * THREE.MathUtils.DEG2RAD,
                                +rotationOriginalValue.y * THREE.MathUtils.DEG2RAD,
                                +rotationOriginalValue.z * THREE.MathUtils.DEG2RAD,
                            ),
                        ),
                    );
                }
                break;

            case TRANSFORMATION_OPTIONS.SCALE:
                if (isLocked) {
                    toast.warning("Can't reset values. Scale locked.");
                } else {
                    setScaleValue(scaleOriginalValue);
                    editor.execute(
                        new SetScaleCommand(
                            selected,
                            new THREE.Vector3(+scaleOriginalValue.x, +scaleOriginalValue.y, +scaleOriginalValue.z),
                        ),
                    );
                }
                break;

            default:
                break;
        }
    };

    const getSetValueFunc = (type: TRANSFORMATION_OPTIONS, value: number, toUpdate: "x" | "y" | "z") => {
        if (type === TRANSFORMATION_OPTIONS.SCALE) {
            let newScale = {...scaleValue};

            if (scaleLocked) {
                const scaleRatio = value / scaleValue[toUpdate];
                if (scaleRatio === 0 || scaleRatio === Infinity || isNaN(scaleRatio)) return;

                newScale = {
                    x: scaleValue.x * scaleRatio,
                    y: scaleValue.y * scaleRatio,
                    z: scaleValue.z * scaleRatio,
                };
            } else {
                newScale[toUpdate] = value;
            }

            app.editor.execute(new SetScaleCommand(selected, new THREE.Vector3(+newScale.x, +newScale.y, +newScale.z)));
            return setScaleValue(newScale);
        }

        if (type === TRANSFORMATION_OPTIONS.POSITION) {
            switch (toUpdate) {
                case "x":
                    editor.execute(
                        new SetPositionCommand(selected, new THREE.Vector3(+value, +positionValue.y, +positionValue.z)),
                    );
                    return setPositionValue(prevState => ({
                        ...prevState,
                        x: value,
                    }));
                case "y":
                    editor.execute(
                        new SetPositionCommand(selected, new THREE.Vector3(+positionValue.x, +value, +positionValue.z)),
                    );
                    return setPositionValue(prevState => ({
                        ...prevState,
                        y: value,
                    }));
                case "z":
                    editor.execute(
                        new SetPositionCommand(selected, new THREE.Vector3(+positionValue.x, +positionValue.y, +value)),
                    );
                    return setPositionValue(prevState => ({
                        ...prevState,
                        z: value,
                    }));

                default:
                    break;
            }
        } else if (type === TRANSFORMATION_OPTIONS.ROTATION) {
            switch (toUpdate) {
                case "x":
                    editor.execute(
                        new SetRotationCommand(
                            selected,
                            new THREE.Euler(
                                +value * THREE.MathUtils.DEG2RAD,
                                +rotationValue.y * THREE.MathUtils.DEG2RAD,
                                +rotationValue.z * THREE.MathUtils.DEG2RAD,
                            ),
                        ),
                    );
                    return setRotationValue(prevState => ({
                        ...prevState,
                        x: value,
                    }));
                case "y":
                    editor.execute(
                        new SetRotationCommand(
                            selected,
                            new THREE.Euler(
                                +rotationValue.x * THREE.MathUtils.DEG2RAD,
                                +value * THREE.MathUtils.DEG2RAD,
                                +rotationValue.z * THREE.MathUtils.DEG2RAD,
                            ),
                        ),
                    );
                    return setRotationValue(prevState => ({
                        ...prevState,
                        y: value,
                    }));
                case "z":
                    editor.execute(
                        new SetRotationCommand(
                            selected,
                            new THREE.Euler(
                                +rotationValue.x * THREE.MathUtils.DEG2RAD,
                                +rotationValue.y * THREE.MathUtils.DEG2RAD,
                                +value * THREE.MathUtils.DEG2RAD,
                            ),
                        ),
                    );
                    return setRotationValue(prevState => ({
                        ...prevState,
                        z: value,
                    }));

                default:
                    break;
            }
        }
    };

    return (
        <MovementSection
            isLocked={isLocked}
            scaleLocked={scaleLocked}
            setScaleLocked={setScaleLocked}
            reset={reset}
            positionValue={positionValue}
            rotationValue={rotationValue}
            scaleValue={scaleValue}
            setScaleValue={setScaleValue}
            getSetValueFunc={getSetValueFunc}>
            {!hideVisibilitySection && <VisibilitySection isLocked={isLocked} hideVisibility={hideVisibility} />}
        </MovementSection>
    );
};
