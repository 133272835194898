import {useEffect, useState} from "react";
import {OBJECT_TYPES, TriggerBehaviorInterface} from "../../../../../types/editor";
import global from "../../../../../global";
import {Separator} from "../common/Separator";
import {PanelCheckbox} from "../common/PanelCheckbox";
import {StyledButton} from "../../common/StyledButton";
import {SelectRow} from "../common/SelectRow";
import {Label} from "../RightPanel.style";
import {SelectionOfButtons} from "../common/SelectionOfButtons";
import TriggerBehaviorConverter from "../../../../../serialization/behaviours/TriggerBehaviorConverter";
import {NumericInputRow} from "../common/NumericInputRow";

type Props = {
    behavior: TriggerBehaviorInterface;
};

interface IOptions {
    key: string;
    value: string;
}
type InputChangeType = (value: any, name: keyof TriggerBehaviorInterface) => void;

export const TriggerBehaviors = ({behavior}: Props) => {
    const app = global.app;
    const editor = app?.editor;
    const selected = editor?.selected;
    const [objectOptions, setObjectOptions] = useState<IOptions[]>([{key: "0", value: "none"}]);
    const [currentBehavior, setCurrentBehavior] = useState<TriggerBehaviorInterface>(behavior);
    const [objectsAvailable, setObjectsAvailable] = useState<any[]>([]);
    const targetBehavior = TriggerBehaviorConverter.DEFAULT.getBehavior(selected, behavior.id) || behavior;

    const handleInputChange: InputChangeType = (value, name) => {
        if (!app || !selected) return;
        if (selected && targetBehavior) {
            (targetBehavior as any)[name] = value;
            setCurrentBehavior({...behavior, [name]: value});
            app.call(`objectChanged`, app.editor, app.editor.selected);
            app.call(`objectUpdated`, app.editor, app.editor.selected);
        }
    };

    const handleObjectTriggerChange = (isElseObject: boolean, behaviorType: OBJECT_TYPES) => {
        if (
            !app ||
            !selected ||
            !targetBehavior ||
            (!targetBehavior.then_behaviors_on_trigger && !isElseObject) ||
            (!targetBehavior.else_behaviors_on_trigger && isElseObject)
        )
            return;

        const targetKey = isElseObject ? "else_behaviors_on_trigger" : "then_behaviors_on_trigger";
        const updatedArray = targetBehavior[targetKey].map(behavior => {
            const key = Object.keys(behavior)[0] as OBJECT_TYPES;
            // @ts-ignore
            const newObj: {key: OBJECT_TYPES; value: boolean} = {[key]: !behavior[key]};
            return key === behaviorType ? newObj : behavior;
        });
        targetBehavior[targetKey] = updatedArray;
        setCurrentBehavior({...behavior, [targetKey]: updatedArray});

        app.call(`objectChanged`, app.editor, app.editor.selected);
        app.call(`objectUpdated`, app.editor, app.editor.selected);
    };

    useEffect(() => {
        const getObjects = () => {
            const options: IOptions[] = [{key: "0", value: "none"}];
            const objects: any[] = [];

            editor?.scene?.children.forEach((object: any) => {
                if (object.name) {
                    options.push({key: `${objects.length + 1}`, value: object.name});
                    objects.push(object);
                }
            });

            setObjectsAvailable(objects);
            setObjectOptions(options);
        };

        getObjects();
    }, [editor?.scene]); // Re-run when scene is updated

    return (
        <>
            <NumericInputRow
                value={currentBehavior.delay || 0}
                setValue={value => handleInputChange(value, "delay")}
                label="Trigger Delay"
                unit="s"
                padding="6px 16px 6px 14px"
                min={0}
            />
            <Separator invisible margin="12px 0 0" />
            <Label $regular>If:</Label>
            <SelectionOfButtons justifyContent="flex-start">
                <StyledButton
                    width="109px"
                    isBlue={currentBehavior.player_touches}
                    isActive={!currentBehavior.player_touches}
                    onClick={() => {
                        handleInputChange(true, "player_touches");
                        handleInputChange(false, "object_touches");
                        handleInputChange(false, "pressE");
                    }}>
                    <span>Player Touches</span>
                </StyledButton>
                <StyledButton
                    width="109px"
                    isBlue={currentBehavior.object_touches}
                    isActive={!currentBehavior.object_touches}
                    onClick={() => {
                        handleInputChange(true, "object_touches");
                        handleInputChange(false, "player_touches");
                        handleInputChange(false, "pressE");
                    }}>
                    <span>Object Touches</span>
                </StyledButton>
                <StyledButton
                    width="109px"
                    isBlue={currentBehavior.pressE}
                    isActive={!currentBehavior.pressE}
                    onClick={() => {
                        handleInputChange(true, "pressE");
                        handleInputChange(false, "object_touches");
                        handleInputChange(false, "player_touches");
                    }}>
                    <span>Press E Key</span>
                </StyledButton>
                <Separator invisible margin="12px 0 0" />
            </SelectionOfButtons>
            <Label $regular>Then:</Label>
            <CommonFields
                objectOptions={objectOptions}
                handleInputChange={handleInputChange}
                behavior={currentBehavior}
                objectsAvailable={objectsAvailable}
                handleObjectTriggerChange={handleObjectTriggerChange}
            />
            <PanelCheckbox
                text="Else:"
                white
                checked={!!currentBehavior.else_condition}
                onChange={() => handleInputChange(!behavior.else_condition, "else_condition")}
                v2
            />
            <Separator margin="12px 0 0" invisible />
            {currentBehavior.else_condition && (
                <CommonFields
                    objectOptions={objectOptions}
                    handleInputChange={handleInputChange}
                    behavior={currentBehavior}
                    elseSelected
                    objectsAvailable={objectsAvailable}
                    handleObjectTriggerChange={handleObjectTriggerChange}
                />
            )}
        </>
    );
};

interface CommonFieldsProps {
    objectOptions: IOptions[];
    handleInputChange: InputChangeType;
    behavior: TriggerBehaviorInterface;
    elseSelected?: boolean;
    objectsAvailable: any[];
    handleObjectTriggerChange: (isElseObject: boolean, behaviorType: OBJECT_TYPES) => void;
}
const CommonFields = ({
    objectOptions,
    handleInputChange,
    behavior,
    elseSelected,
    objectsAvailable,
    handleObjectTriggerChange,
}: CommonFieldsProps) => {
    const app = global.app;
    const editor = app?.editor;
    const behaviorActivate = elseSelected ? behavior.else_activate : behavior.then_activate;
    const behaviorObject = elseSelected ? behavior.else_object : behavior.then_object;
    const [showSavedSettings, setShowSavedSettings] = useState(true);
    const [savedBehavior, setSavedBehavior] = useState<TriggerBehaviorInterface>();

    useEffect(() => {
        if (objectsAvailable.length > 0 && behaviorObject) {
            const exist =
                (!!savedBehavior?.then_object && !elseSelected) || (!!savedBehavior?.else_object && !!elseSelected);
            if (showSavedSettings && exist) {
                setShowSavedSettings(false);
                return;
            }

            const key = elseSelected ? "else_behaviors_on_trigger" : "then_behaviors_on_trigger";
            const obj = objectsAvailable.find(el => el.name === behaviorObject);
            if (obj) {
                if (savedBehavior && savedBehavior.then_object === obj.name) {
                    handleInputChange(savedBehavior.then_behaviors_on_trigger, "then_behaviors_on_trigger");
                    return;
                } else if (savedBehavior && savedBehavior.else_object === obj.name) {
                    handleInputChange(savedBehavior.else_behaviors_on_trigger, "else_behaviors_on_trigger");
                    return;
                }
                const getObject = obj.userData.ID ? editor?.modelByID(obj.userData.ID) : editor?.objectByUuid(obj.uuid);
                const array = getObject?.userData?.behaviors?.map((el: any) => {
                    return {[el.type]: el.startOnTrigger ?? false};
                });
                handleInputChange(array || [], key);
            } else {
                handleInputChange([], key);
            }
        }
    }, [objectsAvailable, behaviorObject]);

    useEffect(() => {
        setSavedBehavior({...behavior});
    }, []);

    return (
        <>
            <SelectionOfButtons>
                <StyledButton
                    width="109px"
                    isBlue={behaviorActivate}
                    isActive={!behaviorActivate}
                    onClick={() =>
                        handleInputChange(!behaviorActivate, elseSelected ? "else_activate" : "then_activate")
                    }>
                    <span>Activate</span>
                </StyledButton>
                <StyledButton
                    width="109px"
                    isBlue={!behaviorActivate}
                    isActive={behaviorActivate}
                    onClick={() =>
                        handleInputChange(!behaviorActivate, elseSelected ? "else_activate" : "then_activate")
                    }>
                    <span>De-Activate</span>
                </StyledButton>
            </SelectionOfButtons>
            <SelectRow
                $margin={"12px 0"}
                label="This Object"
                value={objectOptions.find(el => el.value === behaviorObject)}
                onChange={item => handleInputChange(item.value, elseSelected ? "else_object" : "then_object")}
                data={objectOptions}
            />
            {!elseSelected &&
                behavior.then_behaviors_on_trigger?.map(el => {
                    const key = Object.keys(el)[0];
                    const value = el[key as keyof typeof el];
                    return (
                        <div key={key}>
                            <PanelCheckbox
                                text={key}
                                checked={!!value}
                                onChange={() => handleObjectTriggerChange(false, key as OBJECT_TYPES)}
                                v2
                            />
                            <Separator invisible margin="12px 0 0" />
                        </div>
                    );
                })}
            {elseSelected &&
                behavior.else_behaviors_on_trigger?.map(el => {
                    const key = Object.keys(el)[0];
                    const value = el[key as keyof typeof el];
                    return (
                        <div key={key}>
                            <PanelCheckbox
                                text={key}
                                checked={!!value}
                                onChange={() => handleObjectTriggerChange(true, key as OBJECT_TYPES)}
                                v2
                            />
                            <Separator invisible margin="12px 0 0" />
                        </div>
                    );
                })}
        </>
    );
};
