import React, {useEffect, useRef, useState} from "react";
import classNames from "classnames";
import styled, {keyframes} from "styled-components";
import {regularFont} from "../../assets/style";
import global from "../../global";
import Application from "../../Application";
import logo from "./images/earthAI-logo.svg";

type Props = {
    style?: React.CSSProperties;
    show: boolean;
    className?: string;
    isAutoLoading?: boolean;
};

const fadeOut = keyframes`
  0% {
    opacity: 1;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
`;

const Background = styled.div`
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background-color: #09090b;

    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
`;

const LoadMaskWrapper = styled.div<{$show: boolean}>`
    width: 100%;
    height: calc(100% - 52px);
    bottom: 0;
    left: 0;
    position: fixed;
    display: flex;
    z-index: 9999;
    align-items: flex-end;
    justify-content: center;
    margin-top: 52px;
    animation: ${props => !props.$show && fadeOut} 1s forwards;
`;

const LoadingSectionWrapper = styled.div`
    max-width: 256px;
    width: 90%;
    margin-bottom: 40px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: column;
    row-gap: 8px;

    ${regularFont("s")};
    font-weight: var(--theme-font-medium);

    .logoText {
        ${regularFont("xl")};
        font-weight: var(--theme-font-medium);
    }
`;

const LoadingBarContainer = styled.div`
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 8px;
`;

const LoadingTrack = styled.div`
    height: 8px;
    width: 100%;
    border-radius: 2px;
    background: var(--theme-grey-bg-secondary);
    position: relative;
`;

const LoadingBar = styled.div<{width: number}>`
    height: 8px;
    width: ${props => props.width}%;
    transition: width 0.3s linear;
    background: var(--theme-container-main-blue-border);
    border-radius: 2px;
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
`;

const LoadMask = ({style, show, className, isAutoLoading = true}: Props) => {
    const app = global.app as Application;
    const [showMask, setShowMask] = useState(show);
    const [counter, setCounter] = useState(0);
    const ref = useRef(0);

    const queryParams = new URLSearchParams(window.location.search);
    const logoFromParam = queryParams.get("customLogo");

    const updateCounterState = () => {
        if (ref.current < 100) {
            const result = (ref.current += 1);
            if (result > 100) return setCounter(100);
            setCounter(ref.current);
            ref.current = result;
        }
        setTimeout(updateCounterState, 30);
    };

    useEffect(() => {
        if (show) {
            setShowMask(true);
            ref.current = 0;
            setCounter(0);
            if (isAutoLoading) {
                updateCounterState();
            }
        } else {
            setTimeout(() => {
                setShowMask(false);
            }, 1000);
        }
        app.on(`maskProgress.LoadMask`, (state: number) => {
            if (state > 100) {
                state = 100;
            }
            ref.current = state;
            setCounter(state);
        });

        return () => {
            app.on(`maskProgress.LoadMask`, null);
        };
    }, [show]);

    if (!showMask) return null;

    return (
        <LoadMaskWrapper className={classNames(className, !show && "hide")} style={style} $show={show}>
            <Background>
                <LoadingSectionWrapper>
                    {logoFromParam ? <div className="logoText">{logoFromParam}</div> : <img src={logo} alt="EarthAI" />}
                    <LoadingBarContainer>
                        <LoadingTrack>
                            <LoadingBar width={!show ? 100 : counter} />
                        </LoadingTrack>
                    </LoadingBarContainer>
                </LoadingSectionWrapper>
            </Background>
        </LoadMaskWrapper>
    );
};

export default LoadMask;
