import styled from "styled-components";

export const ValidationTextArea = styled.textarea<{$valueCorrect?: boolean}>`
    border: none;
    ${({$valueCorrect}) => $valueCorrect && "border: 2px solid #34D399;"};
    ${({$valueCorrect}) => $valueCorrect === false && "border: 2px solid #FB7185;"};

    color: var(--theme-font-unselected-secondary-color);
    outline: none;
    height: 150px;
    border-radius: 8px;
    width: 100%;
    padding: 12px 16px;
    background-color: var(--theme-grey-bg);
    font-size: 14px;
    resize: vertical;
    box-sizing: border-box;
`;

export const InvalidEmailList = styled.div`
    color: #fb7185;
    font-size: 11px;
`;
