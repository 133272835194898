import {createBrowserRouter, RouterProvider} from "react-router-dom";
import {AI16ZHome} from "./AI16ZHome/AI16ZHome";

export enum ROUTES {
    HOME = "/",
}

export const Ai16zAppRouter = () => {
    const router = createBrowserRouter([
        {
            path: ROUTES.HOME,
            element: <AI16ZHome />,
        },
    ]);

    return (
        <RouterProvider
            router={router}
            future={{
                v7_startTransition: true,
            }}
        />
    );
};
