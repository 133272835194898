import {useEffect, useRef, useState} from "react";
import {useLocation} from "react-router-dom";
import {toast} from "react-toastify";
import {MathUtils} from "three";
import {useMediaQuery} from "usehooks-ts";

import copyBtn from "../assets/copyBtn.png";
import {IBasicGameInterface, IUser} from "../../types";

import {SingleGame} from "../SingleGame/SingleGame";
import {
    Container,
    CopyMessage,
    Description,
    DetailsBlock,
    GameBlock,
    GameDataWrapper,
    GameDetails,
    Games,
    GamesSection,
    Info,
    MainContent,
    RightInfo,
    RightInfoBlock,
    SectionTitle,
    StyledGamesList,
    Tag,
    Tags,
    UrlWrapper,
    Wrapper,
} from "./PlayPage.style";
import {getGames, updatePlayCount} from "../../../../api/getGames";
import {useAuthorizationContext, useHomepageContext} from "../../../../context";
import {ShareModal} from "../../../../editor/assets/v2/common/ShareModal";
import {PAGES} from "../../../../editor/assets/v2/GamesDashboard/constants";
import gamePlaceholder from "../../../assets/game-controller.svg";
import fullScreeenicon from "../../../assets/fullscreen-btn.svg";

import {Header} from "../../../Header/Header";
import {Footer} from "../../../Footer/Footer";
import {Stats} from "./Stats/Stats";
import {GameAuthor} from "./GameAuthor/GameAuthor";
import {GamesCarousel} from "../GamesCarousel/GamesCarousel";

export const PlayPage = () => {
    const {games, setActivePage, fetchGames} = useHomepageContext();
    const {getUser, dbUser} = useAuthorizationContext();

    useEffect(() => {
        setActivePage(PAGES.PLAY);
        fetchGames();
    }, []);

    const [isCopied, setIsCopied] = useState(false);
    const location = useLocation();
    const [selectedGameData, setSelectedGameData] = useState<IBasicGameInterface>();

    const [openShareModal, setOpenShareModal] = useState(false);
    const [hasUpdatedPlayCount, setHasUpdatedPlayCount] = useState(false);
    const [userGames, setUserGames] = useState<IBasicGameInterface[]>([]);
    const [randomGames, setRandomGames] = useState<IBasicGameInterface[]>(games || []);
    const [anotherRandomGames, setAnotherRandomGames] = useState<IBasicGameInterface[]>([]);
    const [gameOwner, setGameOwner] = useState<IUser>();
    const [oldPathname, setOldPathname] = useState("");
    const iframeRef = useRef<HTMLDivElement>(null);
    const username = gameOwner?.username || gameOwner?.name;

    const isTabletL = useMediaQuery("(max-width: 1279px)");
    const isTablet = useMediaQuery("(max-width: 1023px)");
    const isMobile = useMediaQuery("(max-width: 767px)");

    const gamesInRow = isMobile ? 1 : isTablet ? 3 : 4;

    useEffect(() => {
        if (isCopied) {
            setTimeout(() => {
                setIsCopied(false);
            }, 2000);
        }
    }, [isCopied]);

    const handleUpdatePlayCount = async (game: IBasicGameInterface) => {
        const res = await updatePlayCount(game.ID);
        if (res) {
            const {playCount, likes} = res;
            setSelectedGameData({...game, PlayCount: playCount, Likes: likes});
        }
    };

    useEffect(() => {
        const pathname = location.pathname;
        if (oldPathname !== pathname) {
            setOldPathname(pathname);
            setHasUpdatedPlayCount(false);
            const container = document.getElementById("container");
            if (container) {
                container.scrollTo(0, 0);
            }
            return;
        }

        if (pathname) {
            const match = pathname.match(/\/play\/id-([a-zA-Z0-9]+)-/);
            const id = match ? match[1] : null;
            const gameFromUrl = games.find(el => el.ID === id);
            if (!gameFromUrl) {
                toast.error("Something went wrong. Game not found.");
            } else {
                if (!hasUpdatedPlayCount) {
                    handleUpdatePlayCount(gameFromUrl);
                    setHasUpdatedPlayCount(true);
                }
            }
        }

        if (games && games.length > 0) {
            const randomGames = games.sort(() => Math.random() - 0.5);
            setRandomGames(randomGames);
            const anotherRandomGames = games.sort(() => Math.random() - 0.5);
            setAnotherRandomGames(anotherRandomGames);
        }
    }, [location.key, games, hasUpdatedPlayCount]);

    useEffect(() => {
        (async () => {
            const response = await getGames(gameOwner?.id);

            response && setUserGames(response.filter(game => game.ID !== selectedGameData?.ID));
        })();
    }, [gameOwner]);

    useEffect(() => {
        if (selectedGameData) {
            const getOwner = async () => {
                const response = await getUser(selectedGameData?.UserID);
                response && setGameOwner(response);
            };
            getOwner();
        }
    }, [selectedGameData]);

    const formatPublishedTime = (time?: string) => {
        if (!time) return "...";
        const publishedDate = new Date(time.replace(" ", "T") + "Z");
        const options: Intl.DateTimeFormatOptions = {year: "numeric", month: "long", day: "numeric"};
        return publishedDate.toLocaleDateString("en-US", options);
    };

    const urlPrefix = dbUser
        ? `&UserID=${dbUser.id}&username=${dbUser.username || dbUser.name}`
        : `&username=${"guest" + MathUtils.generateUUID()}`;

    const openFullScreen = () => {
        const elem = iframeRef.current;
        if (!elem) return;
        if (elem.requestFullscreen) {
            elem.requestFullscreen();
            // @ts-ignore
        } else if (elem.webkitRequestFullscreen) {
            /* Safari */
            // @ts-ignore
            elem.webkitRequestFullscreen();
            // @ts-ignore
        } else if (elem.msRequestFullscreen) {
            /* IE11 */
            // @ts-ignore
            elem.msRequestFullscreen();
        }
    };

    const closeFullscreen = () => {
        if (document.exitFullscreen) {
            document.exitFullscreen();
            // @ts-ignore
        } else if (document.webkitExitFullscreen) {
            /* Safari */
            // @ts-ignore
            document.webkitExitFullscreen();
            // @ts-ignore
        } else if (document.msExitFullscreen) {
            /* IE11 */
            // @ts-ignore
            document.msExitFullscreen();
        }
    };

    return (
        <Container>
            <Header />

            <Wrapper>
                <MainContent>
                    <GameBlock>
                        <div className={"iframeWrapper"} ref={iframeRef}>
                            <button
                                className="reset-css fullScreenBtn"
                                onClick={() => (document.fullscreenElement ? closeFullscreen() : openFullScreen())}>
                                <img src={fullScreeenicon} alt="full screen mode" />
                            </button>
                            {selectedGameData?.GameURL ? (
                                <iframe src={selectedGameData?.GameURL + urlPrefix} />
                            ) : (
                                <img className="default-img" src={gamePlaceholder} alt="" />
                            )}
                        </div>
                        <Info>
                            <GameDataWrapper>
                                {isMobile ? (
                                    <>
                                        <div className="mobileWrapper-stats">
                                            <GameDetails>
                                                <span className="name">{selectedGameData?.Name}</span>
                                            </GameDetails>
                                            <Stats
                                                selectedGameData={selectedGameData}
                                                setSelectedGameData={setSelectedGameData}
                                            />
                                        </div>
                                        <GameAuthor
                                            gameName={selectedGameData?.Name || null}
                                            gameOwner={gameOwner || null}
                                        />
                                    </>
                                ) : (
                                    <>
                                        <GameAuthor
                                            gameName={selectedGameData?.Name || null}
                                            gameOwner={gameOwner || null}
                                        />
                                        <Stats
                                            selectedGameData={selectedGameData}
                                            setSelectedGameData={setSelectedGameData}
                                        />
                                    </>
                                )}
                            </GameDataWrapper>
                            <DetailsBlock>
                                <Description>
                                    <div className="dateswrapper">
                                        <div className="info">
                                            <span className="title">Published:</span>
                                            <span className="value">
                                                {formatPublishedTime(selectedGameData?.PublishedTime)}
                                            </span>
                                        </div>
                                        <div className="info">
                                            <span className="title">Last Updated:</span>
                                            <span className="value">
                                                {formatPublishedTime(selectedGameData?.UpdateTime)}
                                            </span>
                                        </div>
                                    </div>

                                    <div className="description">
                                        <span className="title">Description</span>
                                        <span className="value">
                                            {selectedGameData?.Description || "No description yet"}
                                        </span>
                                    </div>
                                </Description>
                                <RightInfo>
                                    <RightInfoBlock>
                                        <span>Share</span>
                                        <UrlWrapper>
                                            <CopyMessage $visible={isCopied}>Copied!</CopyMessage>
                                            <div className="textWrapper">{window.location.href}</div>
                                            <div
                                                onClick={() => {
                                                    navigator.clipboard.writeText(window.location.href);
                                                    setIsCopied(true);
                                                }}>
                                                <img src={copyBtn} alt="copy btn" className="copyBtn" />
                                            </div>
                                        </UrlWrapper>
                                    </RightInfoBlock>
                                    <RightInfoBlock>
                                        <span>Tags</span>
                                        <Tags>
                                            {selectedGameData?.Tags?.map((tag, index) => (
                                                <Tag key={tag + index}>
                                                    <span>#</span>
                                                    {tag}
                                                </Tag>
                                            ))}
                                        </Tags>
                                    </RightInfoBlock>
                                </RightInfo>
                            </DetailsBlock>
                        </Info>
                    </GameBlock>
                    {!isTabletL && (
                        <>
                            {userGames.length > 0 ? (
                                <Games>
                                    <span>More by {username}</span>
                                    {userGames.map((game, index) => {
                                        const recommended = index <= 2;
                                        if (!recommended) return;
                                        return <SingleGame key={game.Name + index} item={game} />;
                                    })}
                                </Games>
                            ) : (
                                <Games>
                                    <span>More Games</span>
                                    {anotherRandomGames.slice(0, 3).map((game, index) => {
                                        const recommended = index <= 2;
                                        if (!recommended) return;
                                        return <SingleGame key={game.Name + index} item={game} />;
                                    })}
                                </Games>
                            )}
                        </>
                    )}
                </MainContent>
                {/* <GamesCarousel games={userGames} /> */}
                {isTabletL && (
                    <>
                        <GamesSection>
                            <SectionTitle>More by {username}</SectionTitle>

                            {!isMobile && (
                                <StyledGamesList>
                                    {userGames.concat(userGames).map((game, index) => {
                                        const recommended = index <= gamesInRow - 1;
                                        if (!recommended) return;
                                        return <SingleGame key={game.Name + index} item={game} />;
                                    })}
                                </StyledGamesList>
                            )}
                        </GamesSection>
                        {isMobile && <GamesCarousel games={userGames.slice(0, 4)} partialVisibilityGutterCount={0} />}
                    </>
                )}
                <GamesSection>
                    <SectionTitle>More Games</SectionTitle>
                    {!isMobile && (
                        <StyledGamesList>
                            {randomGames.slice(0, gamesInRow).map(game => (
                                <SingleGame item={game} key={game.ID} />
                            ))}
                        </StyledGamesList>
                    )}
                </GamesSection>
                {isMobile && <GamesCarousel games={randomGames.slice(0, 4)} partialVisibilityGutterCount={0} />}
            </Wrapper>
            <Footer />
            {openShareModal && (
                <ShareModal
                    onClose={() => setOpenShareModal(false)}
                    url={window.location.href}
                    title={selectedGameData?.Name}
                />
            )}
        </Container>
    );
};
