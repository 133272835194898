import {useEffect} from "react";
import {Container, Content, Wrapper} from "./TermsAndPolicy.style";
import {Header} from "../../Header/Header";
import {Footer} from "../../Footer/Footer";

export const TermsAndPolicy = ({privacyPolicy}: {privacyPolicy?: boolean}) => {
    useEffect(() => {
        const container = document.getElementById("container");
        if (container) {
            container.style.overflowY = "auto";
            container.style.position = "relative";

            container.scrollTo(0, 0);
        }
    }, []);
    return (
        <Container>
            <Header />
            <Wrapper>
                <Content>
                    <header className="title">{privacyPolicy ? "Privacy Policy" : "Terms of Service"}</header>
                    <div className="updateDate">Updated on ...</div>
                    <div className="text">
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit. Fusce eleifend varius elit, sit amet
                        sagittis tellus condimentum tempor. Nunc eu eleifend eros, id lacinia tortor. Pellentesque
                        dictum elit eget dui convallis, non varius dui consectetur. Integer cursus nibh nec elit tempor
                        vulputate. In porttitor lobortis enim id tristique. Fusce faucibus rhoncus libero. Nullam eget
                        rutrum felis. Duis at convallis arcu. Aenean accumsan lorem magna, ut mattis libero condimentum
                        id. Nullam sollicitudin euismod dui sit amet pellentesque. Nullam at felis ut dui condimentum
                        condimentum a et orci. Sed consectetur eros sed interdum vulputate. Aliquam vitae interdum
                        lacus. In eget dapibus mi. Vestibulum at magna at est blandit mattis ut quis turpis. Suspendisse
                        leo quam, porta eu elit ac, suscipit tristique dolor. Aliquam eleifend dignissim odio, iaculis
                        suscipit tortor. Duis venenatis dictum interdum. Curabitur non ex ut libero aliquam egestas.
                        Suspendisse tincidunt ultrices nunc in pellentesque. Phasellus rhoncus dolor mi, sed mattis nisl
                        tincidunt sed. Nam ac tristique quam, in volutpat ipsum. Cras ipsum orci, vestibulum in faucibus
                        sed, posuere quis ex. Donec venenatis auctor orci, in tincidunt mi condimentum iaculis. Maecenas
                        ac massa quis nisl sollicitudin efficitur ac vel ex. Duis in enim dapibus augue sollicitudin
                        venenatis id eget eros. Suspendisse potenti. Nulla pellentesque neque at feugiat convallis.
                        Nulla non nunc vitae tortor rhoncus finibus pharetra quis purus. Duis eget lectus sit amet nibh
                        ultrices mollis. Donec neque turpis, tincidunt in est a, tempus mollis est. Aenean pretium
                        lacinia est eu sollicitudin. Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                        Suspendisse purus libero, consequat sit amet sagittis sed, tincidunt sit amet magna. Aliquam
                        condimentum venenatis porttitor. Sed ut commodo ex. In pellentesque sapien magna, ut facilisis
                        arcu interdum nec. Etiam blandit leo non egestas molestie. Praesent ac sagittis lacus, sed
                        gravida diam. Donec vulputate dictum arcu suscipit interdum. Ut lacinia iaculis neque eget
                        sollicitudin. Donec eu scelerisque mi. Phasellus eget ultricies sem, at pretium metus. Mauris
                        cursus arcu sit amet tincidunt venenatis. Quisque nisl metus, ultrices non pellentesque sed,
                        bibendum quis orci. Nullam lorem augue, sollicitudin non semper at, vestibulum vel turpis.
                        Praesent hendrerit lobortis sodales. Aliquam feugiat lectus a eros lacinia, non volutpat nibh
                        gravida. Nam ultricies lacus purus, in tincidunt magna interdum et. Vivamus sit amet justo
                        vulputate, ornare nisi sed, convallis lectus. Etiam lectus quam, auctor porttitor dui ac,
                        pretium viverra dolor. Ut enim elit, ultricies non turpis eget, semper facilisis ipsum.
                        Vestibulum vel maximus lacus. Nullam massa odio, faucibus eleifend magna ut, congue facilisis
                        lacus. Nulla molestie venenatis est, eu congue lacus tincidunt a. Morbi felis mauris, luctus
                        vitae viverra et, volutpat at neque. Mauris id neque eget sem porttitor sagittis sit amet sit
                        amet felis. Aliquam malesuada consequat vulputate. Nunc eu turpis non odio pharetra consectetur
                        et nec dui. Duis ornare pharetra ultrices. Nunc at ullamcorper nibh. Maecenas blandit lacus quis
                        nisi tincidunt rutrum. Maecenas semper finibus dui, ac lobortis mauris sagittis nec. Sed nibh
                        nulla, gravida sit amet nunc eleifend, faucibus vulputate leo.
                    </div>
                </Content>
            </Wrapper>
            <Footer />
        </Container>
    );
};
