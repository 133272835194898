/*
 * Copyright 2017-2020 The ShadowEditor Authors. All rights reserved.
 *
 * Use of this source code is governed by a MIT-style
 * license that can be found in the LICENSE file.
 *
 * For more information, please visit: https://github.com/tengge1/ShadowEditor
 * You can also visit: https://gitee.com/tengge1/ShadowEditor
 */
import BaseEvent from "./BaseEvent";
import MeshUtils from "../utils/MeshUtils";
import global from "../global";
import {Raycaster, Vector2, GridHelper} from "three";

/**
 * 选取事件
 *
 */
class PickEvent extends BaseEvent {
    constructor() {
        super();
        this.raycaster = new Raycaster();
        this.mouse = new Vector2();

        this.onDownPosition = new Vector2();
        this.onUpPosition = new Vector2();

        this.onAppStarted = this.onAppStarted.bind(this);
        this.onMouseDown = this.onMouseDown.bind(this);
        this.onMouseUp = this.onMouseUp.bind(this);
    }

    start() {
        global.app.on(`appStarted.${this.id}`, this.onAppStarted);
    }

    stop() {
        global.app.on(`appStarted.${this.id}`, null);
    }

    onAppStarted() {
        global.app.viewport.addEventListener("mousedown", this.onMouseDown, false);
    }

    onMouseDown(event) {
        if (event.button !== 0) {
            // 只允许左键选中
            return;
        }

        // 1、这样处理选中的原因是避免把拖动误认为点击
        // 2、不能使用preventDefault，因为div无法获得焦点，无法响应keydown事件。
        // event.preventDefault();

        let array = this.getMousePosition(global.app.viewport, event.clientX, event.clientY);
        this.onDownPosition.fromArray(array);

        document.addEventListener("mouseup", this.onMouseUp, false);
    }

    onMouseUp(event) {
        let array = this.getMousePosition(global.app.viewport, event.clientX, event.clientY);
        this.onUpPosition.fromArray(array);

        this.handleClick(event);

        document.removeEventListener("mouseup", this.onMouseUp, false);
    }

    getIntersects(point, objects, recursive = true) {
        this.mouse.set(point.x * 2 - 1, -(point.y * 2) + 1);
        this.raycaster.setFromCamera(
            this.mouse,
            global.app.editor.view === "perspective" ? global.app.editor.camera : global.app.editor.orthCamera,
        );
        return this.raycaster.intersectObjects(objects, recursive);
    }

    getMousePosition = function (dom, x, y) {
        let rect = dom.getBoundingClientRect();
        return [(x - rect.left) / rect.width, (y - rect.top) / rect.height];
    };

    getClosestSelectableObject(objectsCollision) {
        let closestObject = null;
        let closestDistance = Infinity;

        for (let i = 0; i < objectsCollision.length; i++) {
            const obj = objectsCollision[i].object;
            if (!this.canSelectObject(obj)) {
                continue;
            }

            if (objectsCollision[i].distance < closestDistance) {
                closestDistance = objectsCollision[i].distance;
                closestObject = obj;
            }
        }

        return closestObject;
    }
    canSelectObject(object) {
        const editor = global.app.editor;
        if (
            !object ||
            object?.tag === "helper" ||
            object?.tag === "gizmo" ||
            object === editor.scene ||
            object === editor.camera ||
            object instanceof GridHelper ||
            editor.sceneLockedItems?.includes(object?.uuid)
        ) {
            return false;
        }

        return true;
    }
    handleClick(e) {
        const isMultiselecting = e.shiftKey;
        const editor = global.app.editor;
        const objects = editor.scene.children;
        const selectionHelpers = editor.selectionHelpers;

        if (this.onDownPosition.distanceTo(this.onUpPosition) === 0) {
            const objectsIntersects = this.getIntersects(this.onUpPosition, objects);
            const helpersIntersects = this.getIntersects(this.onUpPosition, selectionHelpers, false);
            const allObjectsIntersects = objectsIntersects.concat(helpersIntersects);

            let currentSelection = null;
            const closestObject = this.getClosestSelectableObject(allObjectsIntersects);

            if (closestObject) {
                if (closestObject.userData.object) {
                    // helper
                    currentSelection = closestObject.userData.object;
                } else {
                    currentSelection = MeshUtils.partToMesh(closestObject);
                }
            }

            if (!currentSelection) {
                if (!isMultiselecting) {
                    editor.select(null);
                }
                return;
            }

            if (isMultiselecting) {
                let selectedObjects = Array.isArray(editor.selected) ? editor.selected.slice() : [editor.selected];

                // Check if the current selection is already in the selected objects
                const alreadySelected = selectedObjects.find(obj => obj === currentSelection);

                if (!alreadySelected) {
                    selectedObjects.push(currentSelection);
                } else {
                    // Remove the current selection from the selected objects
                    selectedObjects = selectedObjects.filter(obj => obj !== currentSelection);
                }

                editor.select(selectedObjects);
            } else {
                editor.select(currentSelection);
            }
        }
    }
}

export default PickEvent;
