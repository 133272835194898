import styled from "styled-components";

export const AuthorContainer = styled.div`
    border-radius: 16px;
    display: flex;
    justify-content: center;
    flex-direction: column;
    gap: 16px;
    width: 100%;
`;

export const AvatarContainer = styled.div<{$avatar?: string}>`
    width: 32px;
    height: 32px;
    border-radius: 100%;
    background-color: var(--theme-grey-bg-secondary-button);
    ${({$avatar}) =>
        $avatar &&
        `
background-image: url(${$avatar});
background-repeat: no-repeat;
background-size: cover;
background-position: center;
`}
`;

export const UserName = styled.div`
    display: flex;
    align-items: center;
    gap: 8px;
    cursor: pointer;

    .container {
        color: var(--theme-font-main-selected-color);
        font-size: 14px;
        font-weight: 500;
        display: flex;
        align-items: center;
        gap: 16px;

        .follow {
            font-weight: 400;
            color: var(--theme-font-main-selected-color);
        }
    }
`;

export const WrapperInfo = styled.div`
    display: flex;
    justify-content: space-between;
`;
