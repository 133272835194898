import global from "../../../../../global";
import {AiNPCBehaviorInterface} from "../../../../../types/editor";
import {useEffect, useState} from "react";
import AiNPCBehaviorConverter from "../../../../../serialization/behaviours/AiNpcBehaviorsConverter";
import {TextInputRow} from "../common/TextInputRow";
import {SelectRow} from "../common/SelectRow";
import Ajax from "../../../../../utils/Ajax";
import {backendUrlFromPath} from "../../../../../utils/UrlUtils";
import {NumericInputRow} from "../common/NumericInputRow";
import {PanelSectionTitle} from "../RightPanel.style";
import {Separator} from "../common/Separator";
import {PanelCheckbox} from "../common/PanelCheckbox";
import {ContentItem, Tag} from "../panels/GameSettings/GameSettings.style";
import deleteIcon from "../icons/x-small.svg";

type Props = {
    behavior: AiNPCBehaviorInterface;
};

type Voice = {
    key: string;
    value: string;
};

export const AiNPCBehaviors = ({behavior}: Props) => {
    const app = (global as any).app;
    const editor = app.editor;
    const selected = editor.selected;
    const [behaviorState, setBehaviorState] = useState(behavior);
    const [adjective, setAdjective] = useState("");
    const [interest, setInterest] = useState("");
    const [voices, setVoices] = useState<Voice[]>([
        {
            key: "9BWtsMINqrJLrRacOk9x",
            value: "Aria",
        },
    ]);

    const targetBehavior = AiNPCBehaviorConverter.DEFAULT.getBehavior(selected, behavior.id);
    /*const animations = useMemo(() => {
        const selected = editor.selected;
        const none = {key: "0", value: "none"};
        if (selected) {
            const objAnimations = selected._obj?.animations || selected.animations;

            if (objAnimations) {
                const anims = objAnimations.map((animation: any, index: number) => ({
                    key: (index + 1).toString(),
                    value: animation.name,
                }));
                return [none, ...anims];
            } else {
                return [none];
            }
        } else {
            return [none];
        }
    }, [behavior]);*/

    const handleInputChange = (value: any, name: keyof AiNPCBehaviorInterface) => {
        if (selected && targetBehavior) {
            (targetBehavior as any)[name] = value;
            setBehaviorState({...targetBehavior, [name]: value});
            app.call(`objectChanged`, app.editor, app.editor.selected);
            app.call(`objectUpdated`, app.editor, app.editor.selected);
        }
    };

    const handleArrayInputChange = (
        e: React.FormEvent,
        value: any,
        name: keyof AiNPCBehaviorInterface,
        callback?: () => void,
    ) => {
        e.preventDefault();
        if (selected && targetBehavior) {
            if (!(targetBehavior as any)[name]) {
                (targetBehavior as any)[name] = [];
            }
            (targetBehavior as any)[name].push(value);
            setBehaviorState({...targetBehavior, [name]: targetBehavior[name]});
            app.call(`objectChanged`, app.editor, app.editor.selected);
            app.call(`objectUpdated`, app.editor, app.editor.selected);
            callback && callback();
        }
    };

    const handleArrayInputChangeDelete = (index: number, name: keyof AiNPCBehaviorInterface) => {
        if (selected && targetBehavior) {
            (targetBehavior as any)[name].splice(index, 1);
            setBehaviorState({...targetBehavior, [name]: targetBehavior[name]});
            app.call(`objectChanged`, app.editor, app.editor.selected);
            app.call(`objectUpdated`, app.editor, app.editor.selected);
        }
    };

    useEffect(() => {
        (async () => {
            try {
                const response = await Ajax.get({
                    url: backendUrlFromPath("/api/AI/Voices") || "",
                    needAuthorization: false,
                });
                if (response?.data) {
                    setVoices(response.data.voices.map((voice: any) => ({key: voice.voice_id, value: voice.name})));
                    console.log(response.data);
                }
                //setVoices(response.data);
            } catch (error) {
                console.error(error);
            }
        })();
    }, []);

    useEffect(() => {
        app.on("objectSelected.AiNPCBehaviors", (selected: any) => {
            if (selected) {
                const objBehavior = AiNPCBehaviorConverter.DEFAULT.getBehavior(selected, behavior.id);

                objBehavior && setBehaviorState(objBehavior);
            }
        });

        return () => {
            app.on("objectSelected.AiNPCBehaviors", null);
        };
    }, []);

    return (
        <>
            <PanelSectionTitle>Agent Details</PanelSectionTitle>
            <Separator invisible margin="6px" />
            <TextInputRow
                isColumn
                label="Name"
                width="100%"
                value={behaviorState.name}
                setValue={value => handleInputChange(value, "name")}
                color="#FAFAFA"
            />
            <TextInputRow
                type="textarea"
                isColumn
                placeholder="Who is your AI Sim?"
                label="Bio"
                width="100%"
                value={behaviorState.bio}
                setValue={value => handleInputChange(value, "bio")}
                height="120px"
            />
            <TextInputRow
                placeholder="What’s their backstory?"
                type="textarea"
                isColumn
                label="Lore"
                width="100%"
                value={behaviorState.lore}
                setValue={value => handleInputChange(value, "lore")}
                height="120px"
            />
            <form
                name="tags"
                onSubmit={e => handleArrayInputChange(e, adjective, "adjectives", () => setAdjective(""))}
                style={{width: "100%"}}>
                <TextInputRow
                    isColumn
                    label="Adjectives"
                    width="100%"
                    value={adjective}
                    setValue={value => setAdjective(value)}
                    color="#FAFAFA"
                />
            </form>
            {behaviorState.adjectives?.length > 0 && (
                <ContentItem
                    $flexDirection="row"
                    $alignItems="flex-start"
                    $justifyContent="flex-start"
                    $columnGap="8px">
                    {behaviorState.adjectives.map((tag, index) => (
                        <Tag key={index}>
                            {tag}{" "}
                            <img src={deleteIcon} onClick={() => handleArrayInputChangeDelete(index, "adjectives")} />
                        </Tag>
                    ))}
                </ContentItem>
            )}
            <form
                name="tags"
                onSubmit={e => handleArrayInputChange(e, interest, "interests", () => setInterest(""))}
                style={{width: "100%"}}>
                <TextInputRow
                    isColumn
                    label="Topics"
                    width="100%"
                    value={interest}
                    setValue={value => setInterest(value)}
                    color="#FAFAFA"
                />
            </form>
            {behaviorState.interests?.length > 0 && (
                <ContentItem
                    $flexDirection="row"
                    $alignItems="flex-start"
                    $justifyContent="flex-start"
                    $columnGap="8px">
                    {behaviorState.interests.map((tag, index) => (
                        <Tag key={index}>
                            {tag}{" "}
                            <img src={deleteIcon} onClick={() => handleArrayInputChangeDelete(index, "interests")} />
                        </Tag>
                    ))}
                </ContentItem>
            )}
            <TextInputRow
                placeholder="What’s an example of what they say on social media?"
                type="textarea"
                isColumn
                label="Social Media Posts"
                width="100%"
                value={behaviorState.social_media_posts}
                setValue={value => handleInputChange(value, "social_media_posts")}
                height="120px"
            />
            <TextInputRow
                placeholder="How do you want them to talk?"
                type="textarea"
                isColumn
                label="Response Style"
                width="100%"
                value={behaviorState.response_style}
                setValue={value => handleInputChange(value, "response_style")}
                height="120px"
            />
            <TextInputRow
                placeholder="Any other rules for your AI Sim?"
                type="textarea"
                isColumn
                label="Miscellaneous"
                width="100%"
                value={behaviorState.miscellaneous}
                setValue={value => handleInputChange(value, "miscellaneous")}
                height="120px"
            />
            <Separator margin="6px 0" />
            <PanelSectionTitle>Agent Settings</PanelSectionTitle>
            <Separator invisible margin="4px" />
            <NumericInputRow
                label="Conversation range"
                value={behaviorState.range}
                setValue={value => handleInputChange(value, "range")}
                width="75px"
                min={0}
            />
            <PanelCheckbox
                text="Show Text Chat UI"
                checked={!!behaviorState.show_text_chat}
                onChange={() => handleInputChange(!behaviorState.show_text_chat, "show_text_chat")}
                v2
                regular
                isGray
            />
            <Separator invisible margin="4px" />
            <PanelCheckbox
                text="Active in Voice Chat"
                checked={!!behaviorState.active_in_voice_chat}
                onChange={() => handleInputChange(!behaviorState.active_in_voice_chat, "active_in_voice_chat")}
                v2
                regular
                isGray
            />
            <Separator invisible margin="4px" />
            <SelectRow
                label="Voice"
                value={voices.find(el => el.key === behaviorState.voice_id)}
                onChange={item => handleInputChange(item.key, "voice_id")}
                data={voices}
            />
        </>
    );
};
