import i18n from "i18next";
import {initReactI18next} from "react-i18next";

import translationsEnglish from "./locales/en/translations.json";
import translationsFrench from "./locales/fr-FR/translations.json";
import translationsJapanese from "./locales/ja-JP/translations.json";
import translationsKorean from "./locales/ko-KR/translations.json";
import translationsRussian from "./locales/ru-RU/translations.json";
import translationsChinese from "./locales/zh-CN/translations.json";
import translationsChineseTW from "./locales/zh-TW/translations.json";

const i8NOptions = {
    fallbackLng: "en",
    lng: "en",
    resources: {
        en: {
            translations: translationsEnglish,
        },
        "fr-FR": {
            translations: translationsFrench,
        },
        "ja-JP": {
            translations: translationsJapanese,
        },
        "ko-KR": {
            translations: translationsKorean,
        },
        "ru-RU": {
            translations: translationsRussian,
        },
        "zh-CN": {
            translations: translationsChinese,
        },
        "zh-TW": {
            translations: translationsChineseTW,
        },
    },
    ns: ["translations"],
    defaultNS: "translations",
};

void i18n.use(initReactI18next).init(i8NOptions);

i18n.languages = Object.keys(i8NOptions.resources);

export default i18n;
