/*
 * Copyright 2017-2020 The ShadowEditor Authors. All rights reserved.
 *
 * Use of this source code is governed by a MIT-style
 * license that can be found in the LICENSE file.
 *
 * For more information, please visit: https://github.com/tengge1/ShadowEditor
 * You can also visit: https://gitee.com/tengge1/ShadowEditor
 */
import BaseSerializer from "../BaseSerializer";
import BufferGeometrySerializer from "./BufferGeometrySerializer";
import * as THREE from "three";
import {TeapotGeometry} from "three/examples/jsm/geometries/TeapotGeometry";

/**
 * TeapotBufferGeometrySerializer
 *
 */
class TeapotBufferGeometrySerializer extends BaseSerializer {
    toJSON(obj) {
        return BufferGeometrySerializer.prototype.toJSON.call(this, obj);
    }

    fromJSON(json, parent) {
        var obj =
            parent === undefined
                ? new TeapotGeometry(
                      json.parameters.size,
                      json.parameters.segments,
                      json.parameters.bottom,
                      json.parameters.lid,
                      json.parameters.body,
                      json.parameters.fitLid,
                      json.parameters.blinn,
                  )
                : parent;

        BufferGeometrySerializer.prototype.fromJSON.call(this, json, obj);

        return obj;
    }
}

export default TeapotBufferGeometrySerializer;
