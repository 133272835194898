import {useEffect, useState} from "react";
import {PROP_ANIMATION_TYPES, PropAnimationBehaviorInterface} from "../../../../../types/editor";
import global from "../../../../../global";
import {Separator} from "../common/Separator";
import {SelectRow} from "../common/SelectRow";
import {NumericInputRow} from "../common/NumericInputRow";
import {PanelCheckbox} from "../common/PanelCheckbox";
import {SelectionOfButtons} from "../common/SelectionOfButtons";
import {StyledButton} from "../../common/StyledButton";
import PropAnimationBehaviorConverter from "../../../../../serialization/behaviours/PropAnimationBehaviorConverter";

type Props = {
    behavior: PropAnimationBehaviorInterface;
};

export const PropAnimationBehaviors = ({behavior}: Props) => {
    const app = global.app;
    const editor = app?.editor;
    const selected = editor?.selected;
    const [behaviorState, setBehaviorState] = useState<PropAnimationBehaviorInterface>(behavior);
    const [animationOptions, setAnimationOptions] = useState([{key: "0", value: "none"}]);

    useEffect(() => {
        if (selected && selected._obj && selected._obj.animations) {
            const loadGLTFAndExtractAnimationClips = () => {
                const animations = selected._obj.animations;
                if (animations && animations.length > 0) {
                    const empty = {key: "0", value: "none"};
                    const animationClips = animations.map((clip: any, index: number) => ({
                        key: (index + 1).toString(),
                        value: clip.name,
                    }));
                    setAnimationOptions([empty, ...animationClips]);
                }
            };

            loadGLTFAndExtractAnimationClips();
        }
    }, [selected]);

    const handleAnimationClipChange = (clipName: string) => {
        if (!app || !selected) return;
        const objBehavior = getObjectBehavior();
        if (objBehavior) {
            objBehavior.propAnimation = clipName;
            setBehaviorState({...behaviorState, propAnimation: clipName});
            selected.userData.propAnimation = clipName; // Save to userData
        }

        app.call(`objectChanged`, app.editor, app.editor.selected);
    };

    const getObjectBehavior = () => {
        if (selected) {
            const targetBehavior = PropAnimationBehaviorConverter.DEFAULT.getBehavior(selected, behavior.id);
            return targetBehavior;
        }
        return null;
    };

    const handleInputChange = (value: any, name: keyof PropAnimationBehaviorInterface) => {
        if (!app || !selected) return;

        const objBehavior = getObjectBehavior();

        if (objBehavior) {
            (objBehavior as any)[name] = value;
            setBehaviorState({...objBehavior, [name]: value});

            app.call(`objectChanged`, app.editor, app.editor.selected);
            app.call(`objectUpdated`, app.editor, app.editor.selected);
        }
    };

    return (
        <>
            <PanelCheckbox
                v2
                regular
                isGray
                text="Start on Trigger"
                checked={!!behavior.startOnTrigger}
                onChange={() => handleInputChange(!behavior.startOnTrigger, "startOnTrigger")}
            />
            <Separator margin="4px 0" invisible />
            <SelectRow
                label="Animation"
                data={animationOptions}
                value={
                    animationOptions.find(item => item.value === behaviorState.propAnimation) || {
                        key: "0",
                        value: "none",
                    }
                }
                onChange={item => handleAnimationClipChange(item.value)}
            />
            <NumericInputRow
                width="75px"
                label="Animation Speed"
                value={behaviorState.animationSpeed || 1}
                setValue={value => handleInputChange(value, "animationSpeed")}
                min={0}
            />
            <SelectionOfButtons margin="0" justifyContent="space-between">
                {Object.keys(PROP_ANIMATION_TYPES).map(key => {
                    const type = PROP_ANIMATION_TYPES[key as unknown as keyof typeof PROP_ANIMATION_TYPES];
                    const isSelected =
                        behaviorState.animationType === type ||
                        (!behaviorState.animationType && type === PROP_ANIMATION_TYPES.PLAY_ONCE);
                    return (
                        <StyledButton
                            width="109px"
                            isBlue={isSelected}
                            isActive={behaviorState.animationType !== type}
                            onClick={() => handleInputChange(type, "animationType")}
                            key={key}>
                            <span>{type}</span>
                        </StyledButton>
                    );
                })}
            </SelectionOfButtons>
        </>
    );
};
