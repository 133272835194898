import styled from "styled-components";
import {flexCenter} from "../../../../assets/style";

export const StyledSection = styled.section`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    row-gap: 40px;
    width: 100%;
    margin: 20px auto 40px;
    padding: 0 20px;
    position: relative;
    z-index: 2;
`;

export const Header = styled.header`
    font-size: 48px;
    font-weight: 700;
    text-align: left;
    color: #fff;

    @media only screen and (max-width: 767px) {
        font-size: 32px;
    }

    a {
        margin-top: 8px;
        width: max-content;
        text-align: left;
    }
`;

export const TagRow = styled.div`
    ${flexCenter};
    justify-content: flex-start;
    width: 100%;
    column-gap: 12px;
`;
