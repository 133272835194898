import styled from "styled-components";
import {flexCenter} from "../../assets/style";

export const Wrapper = styled.div`
    width: 100vw;
    height: 100vh;
    position: fixed;
    display: flex;
    flex-direction: column;
    row-gap: 32px;
    background: var(--theme-container-main-dark);
    overflow: hidden auto;
`;

export const Iframe = styled.iframe`
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100vw;
    height: 100vh;
    z-index: 99999999;
`;

export const GamesContainer = styled.div`
    width: 100%;
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    align-items: start;
    justify-items: start;
    gap: 20px;
    padding: 0 20px;

    @media only screen and (max-width: 1024px) {
        grid-template-columns: repeat(3, 1fr);
    }

    @media only screen and (max-width: 768px) {
        grid-template-columns: repeat(1, 1fr);
    }
`;

export const Header = styled.header`
    width: 100%;
    height: 98px;
    padding: 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .right-side {
        ${flexCenter};
        column-gap: 32px;
    }
`;

export const NoData = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: var(--theme-font-regular);
    font-size: var(--theme-font-size-xl);
    color: white;
    font-family: "Inter";
    height: calc(100% - 64px - 64px);
`;

export const LegalPages = styled.div`
    display: flex;
    align-items: center;
    justify-content: flex-start;
    column-gap: 24px;
    margin-top: auto;
    padding: 0 20px 18px;

    a {
        font-weight: var(--theme-font-bold);
        font-size: var(--theme-font-size-xs);
        color: white;
        font-family: "Inter";
    }
`;
