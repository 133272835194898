import styled from "styled-components";
import {flexCenter, regularFont} from "../../../assets/style";

export const Wrapper = styled.div`
    width: 100vw;
    height: 100vh;
    background-color: #09090b;
    ${flexCenter};
    flex-direction: column;
    row-gap: 16px;

    .topInfo {
        ${regularFont("xxs")};
        color: var(--theme-font-unselected-color);
        text-align: center;
        width: 400px;
    }

    .terms {
        ${regularFont("xxs")};
        color: var(--theme-font-unselected-color);
        text-align: center;
        width: 400px;
    }

    .link {
        text-decoration: underline;
        ${regularFont("xxs")};
        color: var(--theme-font-unselected-color);
    }
`;

export const LoginBox = styled.div`
    width: 400px;
    padding: 40px;
    background-color: var(--theme-container-main-dark);
    border: 1px solid var(--theme-grey-bg);
    ${flexCenter};
    flex-direction: column;
    row-gap: 40px;
    // temporary gap
    row-gap: 24px;
    border-radius: 16px;
`;

export const Top = styled.div`
    ${flexCenter};
    flex-direction: column;
    align-items: flex-start;
    row-gap: 8px;
    width: 100%;
    .logo {
        ${regularFont("xxs")};
        font-weight: 600;
        color: #fff;
    }
    .pageName {
        font-size: 32px;
        line-height: 120%;
        font-weight: 500;
        color: #fff;
    }
    .description {
        ${regularFont("s")};
        color: #fff;
    }
`;

export const Bottom = styled.div`
    width: 100%;
    .TextInput {
        width: 100%;
    }

    .or {
        width: 100%;
        font-size: 11px;
        line-height: 120%;
        color: var(--theme-font-unselected-color);
        text-align: center;
    }
`;
