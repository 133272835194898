import {createBrowserRouter, RouterProvider} from "react-router-dom";
import {useEffect, useState} from "react";
import {toast} from "react-toastify";
import {Create} from "./v2/pages/Create/Create";
import global from "./global";
import {LoginPage} from "./v2/pages/LoginPage/LoginPage";
import {GamesDashboard} from "./editor/assets/v2/GamesDashboard/GamesDashboard";
import {Home} from "./v2/pages/Home/Home";
import UserProfile from "./v2/pages/UserProfile/UserProfile";
import {PlayPage} from "./v2/pages/Home/PlayPage/PlayPage";
import {TermsAndPolicy} from "./v2/pages/TermsAndPolicy/TermsAndPolicy";
import {SearchResults} from "./v2/pages/SearchResults/SearchResults";
import {isUserOnWhitelist} from "./api/whitelist";
import {useAuthorizationContext} from "./context";

export enum ROUTES {
    HOME = "/",
    DASHBOARD = "/dashboard",
    MY_GAMES = "/my-games",
    COMMUNITY = "/community",
    SETTINGS = "/settings",
    PLAY = "/play/:name",
    VIEW_MORE = "/view-more/:category",
    LOGIN = "/login",
    SIGN_UP = "/sign-up",
    CREATE_PROJECT = "/create/project",
    CREATE_PROJECT_WITH_ID = "/create/project/:projectID",
    USER_PROFILE = "/user/:username",
    TERMS_OF_SERVICE = "/legal/terms-of-service",
    PRIVACY_POLICY = "/legal/privacy-policy",
    SEARCH_RESULTS = "/search/results",
    ADMIN_PANEL = "/admin-panel",
}

export const AppRouter = () => {
    const app = global?.app;
    const container = app?.container;
    const {dbUser, isAdmin} = useAuthorizationContext();
    const [isWhitelisted, setIsWhitelisted] = useState<boolean | null>(null);

    const waitlistRouter = createBrowserRouter([
        {
            path: ROUTES.TERMS_OF_SERVICE,
            element: <TermsAndPolicy />,
        },
        {
            path: ROUTES.PRIVACY_POLICY,
            element: <TermsAndPolicy privacyPolicy />,
        },
        {
            path: ROUTES.HOME,
            element: <Home />,
        },
        {
            path: ROUTES.PLAY,
            element: <PlayPage />,
        },
        {
            path: "*",
            element: <LoginPage waitlist />,
        },
    ]);

    const router = createBrowserRouter([
        {
            path: ROUTES.HOME,
            element: <Home />,
        },
        {
            path: ROUTES.SEARCH_RESULTS,
            element: <SearchResults />,
        },
        {
            path: ROUTES.VIEW_MORE,
            element: <SearchResults />,
        },
        {
            path: ROUTES.DASHBOARD,
            element: <GamesDashboard />,
        },
        {
            path: ROUTES.MY_GAMES,
            element: <GamesDashboard />,
        },
        {
            path: ROUTES.COMMUNITY,
            element: <GamesDashboard />,
        },
        {
            path: ROUTES.SETTINGS,
            element: <GamesDashboard />,
        },
        {
            path: ROUTES.PLAY,
            element: <PlayPage />,
        },
        {
            path: ROUTES.LOGIN,
            element: <LoginPage />,
        },
        {
            path: ROUTES.SIGN_UP,
            element: <LoginPage signup />,
        },
        {
            path: ROUTES.CREATE_PROJECT,
            element: <Create />,
        },
        {
            path: ROUTES.CREATE_PROJECT_WITH_ID,
            element: <Create />,
        },
        {
            path: ROUTES.USER_PROFILE,
            element: <UserProfile />,
        },
        {
            path: ROUTES.TERMS_OF_SERVICE,
            element: <TermsAndPolicy />,
        },
        {
            path: ROUTES.PRIVACY_POLICY,
            element: <TermsAndPolicy privacyPolicy />,
        },
        {
            path: ROUTES.ADMIN_PANEL,
            element: <GamesDashboard />,
        },
    ]);

    useEffect(() => {
        if (!container) return;

        const handleContextMenu = (event: MouseEvent) => {
            const contextMenuPathsToBlock: string[] = [ROUTES.CREATE_PROJECT, ROUTES.CREATE_PROJECT_WITH_ID];

            if (contextMenuPathsToBlock.includes(window.location.pathname)) {
                event.preventDefault();
                app?.call("contextmenu", null, event);
            } else {
                event.stopPropagation();
            }
        };

        container.addEventListener("contextmenu", handleContextMenu);
        document.addEventListener("contextmenu", handleContextMenu);

        return () => {
            container.removeEventListener("contextmenu", handleContextMenu);
            document.removeEventListener("contextmenu", handleContextMenu);
        };
    }, [container, window.location.pathname]);

    useEffect(() => {
        const handleWaitlist = async () => {
            if (!dbUser) {
                return toast.error("Couldn't read your email address. Try again.");
            }
            if (isAdmin) {
                setIsWhitelisted(true);
                return;
            } else {
                const isUserWhitelisted = await isUserOnWhitelist(dbUser.email);
                setIsWhitelisted(isUserWhitelisted);
            }
        };

        if (dbUser) handleWaitlist();
    }, [dbUser]);

    if (isWhitelisted === false) {
        return (
            <RouterProvider
                router={waitlistRouter}
                future={{
                    v7_startTransition: true,
                }}
            />
        );
    }

    return (
        <RouterProvider
            router={router}
            future={{
                v7_startTransition: true,
            }}
        />
    );
};
