import {Box3, Vector3, Object3D} from "three";
import ModelLoader from "../../../../../assets/js/loaders/ModelLoader";
import global from "../../../../../global";
import I18n from "i18next";

type CallbackType = (object: Object3D) => void;

export default function loadModel(
    path: string,
    modelData: any,
    callback: CallbackType,
    handleError?: () => void,
): void {
    const app = (global as any).app;
    let loader = new (ModelLoader as any)(app);

    if (loader) {
        loader
            .load(path, modelData, {
                camera: app.editor.camera,
                renderer: app.editor.renderer,
                audioListener: app.editor.audioListener,
                clearChildren: true,
            })
            .then((object: any) => {
                if (!object) {
                    app.toast(I18n.t("Could not load model"), "error");
                    handleError && handleError();
                    return;
                }

                object.name = name;

                Object.assign(object.userData, modelData, {
                    Server: true,
                });

                if (modelData.Type === "fbx") {
                    object.traverse((child: any) => {
                        if ((child as any).isMesh) {
                            child.castShadow = true;
                            child.receiveShadow = true;
                        }
                    });

                    const box = new Box3().setFromObject(object);
                    const center = new Vector3();
                    box.getCenter(center);
                    object.position.sub(center);
                }

                callback(object);
            })
            .catch((e: any) => {
                app.toast(I18n.t("Could not load model"), "error");
                handleError && handleError();
                console.log(e);
            });
    }
}

const renameChildren = (object: any) => {
    // Funkcja rekurencyjna do zmiany nazw dzieci obiektu
    const rename = (object: any) => {
        object.children.forEach((child: any) => {
            // Zmieniamy nazwę dziecka
            child.name = child.name.replace("mixamorig", "");
            // Rekurencyjnie zmieniamy nazwę dzieci tego dziecka
            if (child.children.length > 0) {
                rename(child);
            }
        });
    };

    // Wywołanie funkcji z początkowym obiektem i nową nazwą
    rename(object);
};

const renameObjAnimations = (object: any) => {
    object?._obj.animations.forEach((anim: any) => {
        // Zmieniamy nazwę dziecka
        anim.tracks.forEach((track: any) => {
            track.name = track.name.replace("mixamorig", "");
        });
    });
};
