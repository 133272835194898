/*
 * Copyright 2017-2020 The ShadowEditor Authors. All rights reserved.
 *
 * Use of this source code is governed by a MIT-style
 * license that can be found in the LICENSE file.
 *
 * For more information, please visit: https://github.com/tengge1/ShadowEditor
 * You can also visit: https://gitee.com/tengge1/ShadowEditor
 */
import {Alert, Confirm, Photo, Prompt, Video} from "./ui/index";
import Storage from "./utils/Storage";
import PackageManager from "./package/PackageManager";
import EventDispatcher from "./event/EventDispatcher";
import Converter from "./serialization/Converter";
import UtilsConverter from "./utils/Converter";
import TimeUtils from "./utils/TimeUtils";
import {AppContainer} from "./AppContainer";
import {Ai16zAppContainer} from "./ai16z/Ai16zAppContainer";
import Ajax from "./utils/Ajax";
import global from "./global";
import React, {ChangeEvent} from "react";
import * as THREE from "three";
import {createRoot} from "react-dom/client";
import ApplicationProps from "./ApplicationProps";
import i18n from "./i18n/config";
import Player from "./player/Player";
import Stats from "stats.js";
import {backendUrlFromPath} from "./utils/UrlUtils";
import Editor from "./editor/Editor";
import {Object3D, PerspectiveCamera, Renderer, WebGLRenderer} from "three";
import {generateProjectLink} from "./v2/pages/services";

const {t} = i18n;

export class Application {
    viewport: HTMLElement | undefined;
    container: HTMLElement;
    width: number;
    height: number;
    options: ApplicationProps;
    storage: Storage;
    debug: boolean;
    packageManager: PackageManager;
    require: any;
    event: EventDispatcher;
    call: Function;
    on: Function;
    editor: Editor | null;
    ui: React.ReactElement;
    input: HTMLInputElement | null = null;
    viewportRef: HTMLElement | null = null;
    editorRef: HTMLElement | null = null;
    cesiumRef: HTMLDivElement | null = null;
    svgRef: HTMLDivElement | null = null;
    playerRef: HTMLDivElement | null = null;
    stats = new Stats();
    player: Player | undefined;
    renderer: Renderer | null;
    room: any | null = null;
    userName: string | null = null;

    constructor(container: HTMLElement, options: any) {
        global.app = this;
        global.three$1 = THREE;
        this.viewport = undefined;
        this.container = container;
        this.width = this.container.clientWidth;
        this.height = this.container.clientHeight;

        this.options = new ApplicationProps(options);

        this.storage = new Storage();
        this.debug = !!this.storage.get("debug") || false;

        this.packageManager = new PackageManager();
        this.require = this.packageManager.require.bind(this.packageManager);

        this.event = new EventDispatcher();
        this.call = this.event.call.bind(this.event);
        this.on = this.event.on.bind(this.event);

        this.editor = null;

        this.renderer = null;

        const root = createRoot(container);
        this.ui = React.createElement(options.ai16z ? Ai16zAppContainer : AppContainer);
        this.event.start();
        root.render(this.ui);
    }

    createElement(type: React.ComponentType<any>, props: any, children: any): React.ReactElement {
        return this.editor!.createElement(type, props, children);
    }

    addElement(element: React.ReactElement): void {
        this.editor && this.editor.addElement(element, () => {});
    }

    removeElement(element: React.ReactElement): void {
        this.editor && this.editor.removeElement(element, () => {});
    }

    undo(): void {
        this.editor && this.editor.undo();
    }
    redo(): void {
        this.editor && this.editor.redo();
    }
    copy(): void {
        this.editor && this.editor.copy();
    }
    paste(): void {
        this.editor && this.editor.paste();
    }
    duplicateObject(): void {
        this.editor && this.editor.duplicateObject();
    }

    toggleUI(): void {
        this.editor && this.editor.toggleUI();
    }

    toast(content: string, type?: "info" | "success" | "error" | "warn"): void {
        this.editor?.toast(t(content), type);
    }

    alert(
        options: {
            title?: string;
            content?: string;
            className?: string;
            style?: React.CSSProperties;
            onOK?: () => void;
            onClose?: () => void;
        } = {},
    ): {component: React.ReactElement; close: () => void} {
        let {title, content, className, style, onOK, onClose} = options;
        let component: React.ReactElement | null = null;

        let close = () => {
            component && this.removeElement(component);
        };

        if (onOK === undefined && onClose === undefined) {
            onOK = onClose = close;
        } else if (onClose === undefined) {
            onClose = onOK;
        }

        component = this.createElement(
            Alert,
            {
                title,
                okText: t("OK"),
                className,
                style,
                onOK,
                onClose,
            },
            content,
        );

        this.addElement(component);

        return {
            component,
            close,
        };
    }

    confirm(
        options: {
            title?: string;
            content?: string;
            okText?: string;
            cancelText?: string;
            className?: string;
            style?: React.CSSProperties;
            onOK?: () => void;
            onCancel?: () => void;
        } = {},
    ): {component: React.ReactElement; close: () => void} {
        const {title, content, okText, cancelText, className, style, onOK, onCancel} = options;

        let component: React.ReactElement | null = null;

        let close = () => {
            component && this.removeElement(component);
        };

        let handleOK = () => {
            if (onOK) {
                onOK();
                close();
            }
        };

        let handleCancel = () => {
            onCancel && onCancel();
            close();
        };

        component = this.createElement(
            Confirm,
            {
                title,
                okText: okText || t("Confirm"),
                cancelText: cancelText || t("Cancel"),
                className,
                style,
                onOK: handleOK,
                onCancel: handleCancel,
                onClose: handleCancel,
            },
            content,
        );
        this.addElement(component);
        return {
            component,
            close,
        };
    }

    prompt(
        options: {
            title?: string;
            content?: string;
            className?: string;
            style?: React.CSSProperties;
            value?: string;
            mask?: boolean;
            onOK?: (value: string) => void;
            onClose?: () => void;
        } = {},
    ): {component: React.ReactElement; close: () => void} {
        let {title, content, className, style, value, mask, onOK, onClose} = options;
        let component: React.ReactElement | null = null;

        let close = () => {
            component && this.removeElement(component);
        };

        let handleOK = (newValue: string) => {
            if (onOK && newValue) {
                onOK(newValue);
                close();
            }
        };

        let handleClose = () => {
            onClose && onClose();
            close();
        };

        component = this.createElement(
            Prompt,
            {
                title,
                content,
                className,
                style,
                value,
                okText: t("OK"),
                mask,
                onOK: handleOK,
                onClose: handleClose,
            },
            null,
        );

        this.addElement(component);

        return {
            component,
            close,
        };
    }

    mask(isAuto: boolean = true): void {
        this.call("showMask", this, true, isAuto);
    }

    unmask(): void {
        this.call("showMask", this, false);
    }

    photo(url: string): void {
        let component: React.ReactElement | null = null;

        let close = () => {
            if (component) {
                this.removeElement(component);
                component = null;
            }
        };

        component = this.createElement(
            Photo,
            {
                url,
                onClick: close,
            },
            null,
        );

        this.addElement(component);
    }

    video(url: string): void {
        let component: React.ReactElement | null = null;

        let close = () => {
            if (component) {
                this.removeElement(component);
                component = null;
            }
        };

        component = this.createElement(
            Video,
            {
                url,
                onClick: close,
            },
            null,
        );

        this.addElement(component);
    }

    upload(url: string, callback: (response: any) => void, size?: {minWidth: number; minHeight: number}): void {
        let input = this.input;
        if (!input) {
            input = document.createElement("input");
            input.type = "file";
            input.style.display = "none";
            document.body.appendChild(input);
        }
        this.input = input;
        input.value = "";
        input.onchange = async event => {
            input!.onchange = null;
            const inputEvent = event as unknown as ChangeEvent<HTMLInputElement>;
            if (url && inputEvent.target.files) {
                try {
                    const file = inputEvent.target.files[0];
                    if (size) {
                        const minHeight = size.minHeight;
                        const minWidth = size.minWidth;
                        const img = new Image();
                        img.src = URL.createObjectURL(file);

                        const checkImageDimensions = () => {
                            return new Promise<void>((resolve, reject) => {
                                img.onload = () => {
                                    if (img.width < minWidth || img.height < minHeight) {
                                        reject(
                                            new Error(
                                                `Image dimensions must be at least ${minWidth}x${minHeight} pixels.`,
                                            ),
                                        );
                                    } else {
                                        resolve();
                                    }
                                };
                                img.onerror = () => {
                                    reject(new Error("Invalid image file."));
                                };
                            });
                        };
                        await checkImageDimensions();
                    }
                    const response = await Ajax.post({
                        url: backendUrlFromPath(url),
                        data: {
                            file,
                        },
                    });
                    const obj = response?.data;
                    if (obj.Code === 200) {
                        callback(obj);
                    } else {
                        this.toast(obj.Msg, "warn");
                    }
                } catch (error: any) {
                    this.unmask();
                    this.toast(error.message || "Request failed.");
                }
            } else {
                callback(inputEvent.target.files?.[0]);
            }
        };
        input.click();
    }

    log(content: string): void {
        this.call("log", this, content);
    }

    warn(content: string): void {
        this.call("log", this, content, "warn");
    }

    error(content: string): void {
        this.call("log", this, content, "error");
    }

    setAutoSave(value: boolean): void {
        this.storage.autoSave = value;
        this.call("storageChanged", this, "autoSave", value);
    }

    saveScene(createThumbnail: boolean = false): void {
        const editor = this.editor;

        const canvas = editor?.renderer?.domElement;

        const bannerImage = editor?.scene?.userData?.game?.bannerImage;
        this.call(`sceneSaveStart`);

        if (bannerImage) {
            this.commitSaveScene(bannerImage);
            return;
        }

        if ((createThumbnail || !editor?.sceneThumbnail) && canvas) {
            const file = this.createSceneScreenShot();

            Ajax.post({
                url: backendUrlFromPath(`/api/Upload/Upload`),
                data: {
                    file,
                },
            })
                .then(obj => {
                    if (obj?.data.Code === 200) {
                        this.commitSaveScene(obj.data.Data.url);
                    } else {
                        this.toast("createScene did not respond with 200.");
                    }
                })
                .catch(e => {
                    this.call(`sceneSaveFailed`);
                    this.toast("Request failed.", "error");
                    console.log("Save scene error: ", e);
                });
        } else {
            this.commitSaveScene("");
        }
    }

    commitSaveScene(
        thumbnailUrl: string,
        options?: {
            isPublic?: boolean;
            isCloneable?: boolean;
            isPublished?: boolean;
            onError?: () => void;
            onSuccess?: () => void;
        },
    ): void {
        const editor = this.editor;
        if (!editor) {
            this.call(`sceneSaveFailed`);
            this.toast("Request failed.", "error");
            return;
        }
        const experience = new (Converter as any)().toJSON({
            options: this.options,
            camera: editor?.camera,
            renderer: editor?.renderer,
            scripts: editor?.scripts,
            animations: editor?.animations,
            scene: editor?.scene,
        });

        let payload: any = {
            ID: editor?.sceneID,
            Name: editor?.sceneName,
            Data: JSON.stringify(experience),
            LockedItems: editor?.sceneLockedItems ? editor?.sceneLockedItems.join(",") : "",
            Thumbnail: thumbnailUrl || editor?.sceneThumbnail,
            IsMultiplayer: editor?.isMultiplayer,
            ShowStats: editor?.showStats,
            UseInstancing: editor?.useInstancing,
            VoiceChatEnabled: editor?.voiceChatEnabled,
            UseWebGPU: editor?.useWebGPU,
            UseAvatar: editor?.useAvatar,
            IsPublic: options?.isPublic ?? !!editor?.isPublic,
            IsCloneable: options?.isCloneable ?? !!editor?.isCloneable,
            IsPublished: options?.isPublished ?? !!editor?.isPublished,
            Description: editor?.description,
            Tags: JSON.stringify(editor?.tags),
        };

        Ajax.post({
            url: backendUrlFromPath(`/api/Scene/Save`),
            data: payload,
        })
            .then(response => {
                if (response?.data.Code === 200) {
                    if (editor && editor.sceneID && editor.sceneID !== response?.data.ID) {
                        window.location.href = generateProjectLink(response?.data.ID);
                    } else if (!editor.sceneID) {
                        const id = response?.data.ID;
                        const newURL =
                            window.location.protocol +
                            "//" +
                            window.location.host +
                            window.location.pathname +
                            `/${id}`;
                        window.history.replaceState({path: newURL}, "", newURL);
                        editor.sceneID = id;
                    }
                    if (options?.onSuccess) {
                        options.onSuccess();
                    }
                } else {
                    throw Error(response?.data.Msg || "Request failed");
                }
                this.call(`sceneSaved`);
            })
            .catch((error: any) => {
                if (options?.onError) {
                    options.onError();
                }
                this.call(`sceneSaveFailed`);
                this.toast(error.message, "error");
            });
    }

    createSceneScreenShot(): File | undefined {
        try {
            const renderer = new WebGLRenderer({
                antialias: true,
                preserveDrawingBuffer: true,
            });

            if (this.editor) {
                let selectedIds: string[] = [];
                const selected = this.editor.selected;

                if (selected instanceof Object3D) {
                    selectedIds = [selected.uuid];
                } else if (Array.isArray(selected)) {
                    selectedIds = selected.map(obj => obj.uuid);
                }

                if (selectedIds?.length > 0) {
                    this.editor.select(null);
                }
                const camera = new PerspectiveCamera(60, 1, 0.1, 100);
                camera.position.set(20, 20, 20); // Adjust height for top-down view
                camera.lookAt(0, 0, 0); // Look at the center of the scene
                renderer.setPixelRatio(window.devicePixelRatio);
                const width = this.viewport?.clientWidth;
                const height = this.viewport?.clientHeight;
                renderer.setSize(width || 100, height || 100);
                renderer.render(this.editor.scene, camera);
                const dataUrl = renderer.domElement.toDataURL("image/jpeg", 1.0);
                const file = (UtilsConverter as any).dataURLtoFile(dataUrl, (TimeUtils as any).getDateTime());

                if (selectedIds?.length > 0) {
                    this.editor.selectByUuid(selectedIds.length === 1 ? selectedIds[0] : selectedIds);
                }

                return file;
            }
        } catch (e: any) {
            throw new Error(e);
        }
    }

    getScene(sceneId: string, callback: (data: any) => void): void {
        Ajax.get({
            url: backendUrlFromPath(`/api/Scene/Get?ID=${sceneId}`),
            needAuthorization: false,
        })
            .then(response => {
                callback(response?.data);
                if (response?.data.Code !== 200) {
                    this.toast(response?.data.Msg, "error");
                }
            })
            .catch(error => {
                console.error("Request failed.", error);
            });
    }

    saveSceneSettings(sceneId: string, isPublic: boolean, isCloneable: boolean, callback: (data: any) => void): void {
        Ajax.post({
            url: backendUrlFromPath(`/api/Scene/Publish`),
            data: {
                ID: sceneId,
                IsPublic: isPublic,
                IsCloneable: isCloneable,
            },
        })
            .then(response => {
                callback(response?.data);
                if (response?.data.Code !== 200) {
                    this.toast(response?.data.Msg, "error");
                }
            })
            .catch(error => {
                console.warn("Request failed.", error);
            });
    }

    editModel(
        payload: {
            ID: string;
            Name?: string;
            Image?: string;
            Url?: string;
            IsPublic?: boolean;
            IsAvatar?: boolean;
        },
        callback?: (data: any) => void,
    ) {
        Ajax.post({
            url: backendUrlFromPath(`/api/Mesh/Edit`),
            data: payload,
        })
            .then(response => {
                this.call("fetchModels");
                if (response?.data.Code !== 200 && response?.data.Msg) {
                    this.toast(response.data.Msg, "warn");
                }
                callback && callback(response?.data);
            })
            .catch(error => {
                console.warn("Request failed.", error);
            });
    }
}

export default Application;
