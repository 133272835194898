export enum PANEL_TYPES {
    MOVEMENT = "MOVEMENT",
    LIGHTING = "LIGHTING",
    MODEL_LIGHTING = "MODEL LIGHTING",
    COLLISION = "COLLISION",
    PHYSICS = "PHYSICS",
    RIGID_BODY = "RIGID_BODY",
    TEXTURE = "TEXTURE",
    MATERIAL_RENDERING = "MATERIAL",
}

export const ROWS = [
    {name: "Transform", type: PANEL_TYPES.MOVEMENT},
    {name: "Lighting", type: PANEL_TYPES.LIGHTING},
    {name: "Lighting", type: PANEL_TYPES.MODEL_LIGHTING},
    {name: "Collison", type: PANEL_TYPES.COLLISION},
    {name: "Physics", type: PANEL_TYPES.PHYSICS},
    {name: "Texture", type: PANEL_TYPES.TEXTURE},
    {name: "Material Rendering", type: PANEL_TYPES.MATERIAL_RENDERING},
];
