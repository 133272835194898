import {useAuthorizationContext} from "../../../context";
import {StyledButton} from "../../../editor/assets/v2/common/StyledButton";
import {Bottom, LoginBox, Top} from "./LoginPage.style";

export const Waitlist = ({setShowWaitlist}: {setShowWaitlist: React.Dispatch<React.SetStateAction<boolean>>}) => {
    const {handleLogOut} = useAuthorizationContext();
    return (
        <LoginBox>
            <Top>
                <div className="logo">Thanks for signing up!</div>
                <div className="pageName">You’re on the Waitlist</div>
                <div className="description">
                    We’ll email you when we are ready for more users on the platform. Thank you for your patience!
                </div>
            </Top>
            <Bottom>
                <StyledButton
                    width="100%"
                    isGreySecondary={true}
                    onClick={() => {
                        handleLogOut();
                        setShowWaitlist(false);
                    }}>
                    Logout
                </StyledButton>
            </Bottom>
        </LoginBox>
    );
};
