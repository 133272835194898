import {initializeApp} from "firebase/app";
import {getAnalytics} from "firebase/analytics";
import {getAuth} from "firebase/auth";
import {Firestore, getFirestore} from "firebase/firestore";

const firebaseConfig = {
    apiKey: process.env.REACT_APP_FIREBASE_API_KEY || "AIzaSyAK8NQXd76ivJBQ4PvPkVpVBt5BBMiCkpI",
    authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN || "shadow-editor.firebaseapp.com",
    projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID || "shadow-editor",
    storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET || "shadow-editor.appspot.com",
    messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID || "341675356851",
    appId: process.env.REACT_APP_FIREBASE_APP_ID || "1:341675356851:web:b5c74d56b7ce92d1df76c6",
    measurementId: process.env.REACT_APP_FIREBASE_MESUREMENT_ID || "G-Y9VPEZL25X",
};

const app = initializeApp(firebaseConfig);

export const analytics = getAnalytics(app);
export const auth = getAuth(app);
export const db: Firestore = getFirestore(app);
export default app;
