import BaseBehaviorConverter from "./BaseBehaviorConverter";
import * as THREE from "three";
import {BehaviorUpdater} from "../../behaviors/BehaviorManager";
import {PropAnimationBehaviorInterface, OBJECT_TYPES, PROP_ANIMATION_TYPES} from "../../types/editor";
import PropAnimationBehaviorUpdater from "./PropAnimationBehaviorUpdater";

class PropAnimationBehaviorConverter extends BaseBehaviorConverter<PropAnimationBehaviorInterface> {
    public static DEFAULT = new PropAnimationBehaviorConverter(null);
    constructor(obj: any) {
        super(obj);
    }

    convert(target: THREE.Object3D, behavior: PropAnimationBehaviorInterface): BehaviorUpdater {
        return new PropAnimationBehaviorUpdater(target, behavior);
    }

    getBehavior(target: THREE.Object3D, id: string): PropAnimationBehaviorInterface | null {
        if (!target) return null;

        let behavior = super.findBehavior(target, id) as PropAnimationBehaviorInterface;
        if (!behavior) {
            console.warn("Object doesn't have Prop Animation behavior: ", target);
            return null;
        }

        return behavior;
    }

    getDefaultBehavior(id: string): PropAnimationBehaviorInterface {
        return {
            enabled: true,
            id,
            type: OBJECT_TYPES.PROP_ANIMATION,
            propAnimation: "none",
            animationSpeed: 1,
            startOnTrigger: false,
            animationType: PROP_ANIMATION_TYPES.PLAY_ONCE,
        };
    }
}

export default PropAnimationBehaviorConverter;
