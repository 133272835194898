import React, {useCallback, useEffect, useState} from "react";
import {toast} from "react-toastify";
import moment from "moment";
import {UserInfo, getAuth} from "firebase/auth";
import {collection, doc, getDoc, setDoc, updateDoc, query, where, getDocs} from "firebase/firestore";
import {auth, db} from "../firebase";
import {IUser} from "../v2/pages/types";
import {getLikedGames} from "../api/updateUser";
import Ajax, {axiosTokenConfig} from "../utils/Ajax";
import global from "../global";
import {ROUTES} from "../AppRouter";
import {backendUrlFromPath} from "../utils/UrlUtils";
import {isUserOnWhitelist} from "../api/whitelist";

interface AuthorizationContextValue {
    isAuthorized: boolean;
    setIsAuthorized: React.Dispatch<React.SetStateAction<boolean>>;
    handleLogOut: () => void;
    googleUser: UserInfo | null;
    authToken: string | null;
    initLoading: boolean;
    dbUser: IUser | null;
    setDbUser: React.Dispatch<React.SetStateAction<IUser | null>>;
    likedGamesIds: string[];
    setLikedGamesIds: React.Dispatch<React.SetStateAction<string[]>>;
    handleGetLikedGames: () => Promise<void>;
    saveUser: (updatedUser: IUser) => Promise<void>;
    getUser: (userId?: string, userName?: string) => Promise<IUser | undefined>;
    validateUsername: (username: string) => Promise<boolean>;
    saveUsernameInFirebase: (username: string) => Promise<true | undefined>;
    isAdmin: boolean;
    isWhitelisted: boolean | undefined;
}

export const AuthorizationContext = React.createContext<AuthorizationContextValue>(null!);

export interface AuthorizationContextProviderProps {
    children: React.ReactNode;
}

const AuthorizationContextProvider: React.FC<AuthorizationContextProviderProps> = ({children}) => {
    const [isAuthorized, setIsAuthorized] = useState(false);
    const [isAdmin, setIsAdmin] = useState(false);
    const [googleUser, setGoogleUser] = useState<UserInfo | null>(null);
    const [authToken, setAuthToken] = useState<string | null>(null);
    const [initLoading, setInitloading] = useState(true);
    const [isWhitelisted, setIsWhitelisted] = useState<boolean>();
    const [dbUser, setDbUser] = useState<IUser | null>(null);
    const [likedGamesIds, setLikedGamesIds] = useState<string[]>([]);
    const [refreshInterval, setRefreshInterval] = useState<NodeJS.Timeout | null>(null);

    const onLogOut = () => {
        setIsAuthorized(false);
        setGoogleUser(null);
        setDbUser(null);
    };

    const handleLogOut = async () => {
        await auth.signOut();
        onLogOut();
    };

    // Function to handle token regeneration
    const regenerateToken = async (): Promise<string | null> => {
        const user = auth.currentUser;
        if (user) {
            try {
                return await user.getIdToken(true);
            } catch (error) {
                console.error("Error regenerating token:", error);
                return null;
            }
        }
        console.error("No user is currently signed in.");
        return null;
    };

    const handleWhitelist = async (email: string | null) => {
        if (!email) return;
        const isUserWhitelisted = await isUserOnWhitelist(email);
        setIsWhitelisted(isUserWhitelisted);
    };

    const setupUserAndToken = (userInfo: UserInfo | null, token: string | null) => {
        setGoogleUser(userInfo);
        setAuthToken(token);
        axiosTokenConfig(token === null ? undefined : token);
        setIsAuthorized(token !== null);
        setInitloading(false);
        userInfo && handleWhitelist(userInfo.email);
    };

    useEffect(() => {
        const currentPath = window.location.pathname;
        const sanitizedPath = currentPath.replace(/\/$/, "");

        if (
            isAuthorized &&
            dbUser &&
            !dbUser.username &&
            sanitizedPath !== ROUTES.LOGIN &&
            sanitizedPath !== ROUTES.SIGN_UP
        ) {
            window.location.href = ROUTES.LOGIN;
        }
    }, [isAuthorized, dbUser]);

    useEffect(() => {
        const container = document.getElementById("container");
        if (container) {
            const path = window.location.pathname;
            if (
                path === ROUTES.HOME ||
                path === ROUTES.SEARCH_RESULTS ||
                path === ROUTES.TERMS_OF_SERVICE ||
                path === ROUTES.PRIVACY_POLICY ||
                path.includes("/play/") ||
                path.includes("/user/") ||
                path.includes("/view-more/")
            ) {
                container.style.overflowY = "auto";
                container.style.position = "relative";
            } else {
                container.style.overflow = "hidden";
            }
            container.scrollTo(0, 0);
        }
    }, [window.location.pathname]);

    useEffect(() => {
        auth.onAuthStateChanged(async function (user) {
            if (user) {
                const token = await regenerateToken();
                if (token === null) {
                    setupUserAndToken(null, null);
                    return;
                }
                const idTokenResult = await user.getIdTokenResult();
                const tokenExpirationTime = idTokenResult.expirationTime;
                const expirationDate = new Date(tokenExpirationTime);
                const timeoutDuration = expirationDate.getTime() - Date.now() - 60000; // Refresh 1 minute before expiration
                if (refreshInterval !== null) {
                    clearInterval(refreshInterval);
                }
                const intervalId = setInterval(async () => {
                    const newToken = await regenerateToken();
                    if (newToken) {
                        setupUserAndToken(user.providerData[0], newToken);
                    } else {
                        setupUserAndToken(null, null);
                    }
                }, timeoutDuration);
                setRefreshInterval(intervalId);
                setupUserAndToken(user.providerData[0], token);
            } else {
                setupUserAndToken(null, null);
            }
        });

        global.app?.on("updateToken", (token: string) => {
            setAuthToken(token);
            axiosTokenConfig(token);
            setIsAuthorized(true);
        });

        return () => {
            if (refreshInterval !== null) {
                clearInterval(refreshInterval);
            }
            global.app?.on("updateToken", null);
        };
    }, []);

    const createNewUser = useCallback(async () => {
        try {
            const authUser = getAuth().currentUser;

            if (!authUser) {
                toast.error("Something went wrong when logging in");
                console.log("Cannot create user, Firebase authentication data is missing");
                return;
            }

            let user: IUser;

            user = {
                id: authUser.uid, // Use Firebase UID instead of Google UID
                name: authUser.displayName || "",
                email: authUser.email || "",
                avatar: authUser.photoURL || "",
                memberSince: moment().unix(),
            };

            const id = authUser.uid; // Firebase UID
            await setDoc(doc(db, "users", id), user);

            const docRef = doc(db, "users", id);
            const docSnap = await getDoc(docRef);
            setDbUser(docSnap.data() as IUser);
        } catch (e) {
            console.error("Error from adding document: ", e);
        }
    }, []);

    // returns true if username is available
    const validateUsername = useCallback(
        async (username: string) => {
            try {
                const usersRef = collection(db, "users");
                const userQuery = query(usersRef, where("username", "==", username));

                const querySnapshot = await getDocs(userQuery);
                const snap = querySnapshot.docs[querySnapshot.docs.length - 1];
                return !snap?.data();
            } catch (e) {
                console.error("Error from fetching document: ", e);
                toast.error("Something went wrong with validating username. Try again later.");
                return false;
            }
        },
        [createNewUser],
    );

    const saveUsernameInFirebase = useCallback(
        async (username: string) => {
            try {
                const authUser = getAuth().currentUser;

                if (!authUser) {
                    console.error("Cannot get user, Firebase authentication data is missing");
                    throw Error;
                }

                const id = authUser!.uid; // Use Firebase UID

                const docRef = doc(db, "users", id);
                const docSnap = await getDoc(docRef);

                if (docSnap.exists()) {
                    const user = docSnap.data() as IUser;
                    await updateDoc(docRef, {
                        username,
                    });
                    setDbUser({
                        ...user,
                        username,
                    });
                    return true;
                } else {
                    console.error("No such user in db!");
                    throw Error;
                }
            } catch (e) {
                console.error("Error from setting username: ", e);
                toast.error("Something went wrong when setting your username. Try again later.");
            }
        },
        [createNewUser],
    );

    const getUser = useCallback(
        async (userId?: string, username?: string) => {
            try {
                const authUser = getAuth().currentUser;

                if (!username && !userId && !authUser) {
                    console.error("Cannot get user, Firebase authentication data is missing");
                    return;
                }

                const safeUsername = decodeURIComponent(username || "").replaceAll("-", " ");

                if (safeUsername) {
                    const usersRef = collection(db, "users");
                    const userQuery = query(usersRef, where("username", "==", safeUsername));

                    const querySnapshot = await getDocs(userQuery);
                    const snap = querySnapshot.docs[querySnapshot.docs.length - 1];

                    if (!snap) {
                        const userQueryByName = query(usersRef, where("name", "==", safeUsername));

                        const querySnapshotRetry = await getDocs(userQueryByName);
                        const snapRetry = querySnapshotRetry.docs[querySnapshotRetry.docs.length - 1];
                        return snapRetry.data() as IUser;
                    }

                    return snap.data() as IUser;
                }

                const id = userId || authUser!.uid; // Use Firebase UID

                const docRef = doc(db, "users", id);
                const docSnap = await getDoc(docRef);

                if (userId) {
                    return docSnap.data() as IUser;
                }

                if (docSnap.exists()) {
                    const user = docSnap.data() as IUser;
                    if (!user.memberSince) {
                        await updateDoc(docRef, {
                            memberSince: moment().unix(),
                        });
                    }
                    setDbUser(user);
                } else {
                    console.log("No such user in db! Adding new one");
                    createNewUser();
                }
            } catch (e) {
                console.error("Error from fetching document: ", e);
                toast.error("Something went wrong with fetching user data");
            }
        },
        [createNewUser],
    );

    const fetchUser = async () => {
        const data = await Ajax.get({
            url: backendUrlFromPath("/api/User/Get"),
        });

        if (data?.data.Data) {
            return data.data.Data;
        }
        return null;
    };

    const checkIfUserIsAdmin = async () => {
        try {
            const auth = getAuth();
            const user = auth.currentUser;

            if (!user) {
                console.log("No user is currently logged in.");
                return false;
            }

            const userData = await fetchUser();

            const idTokenResult = await user.getIdTokenResult();
            setIsAdmin(idTokenResult.claims.isAdmin === true || userData?.isAdmin === true);
        } catch (error) {
            console.error("Error checking user claims:", error);
            return false;
        }
    };

    useEffect(() => {
        isAuthorized && checkIfUserIsAdmin();
    }, [isAuthorized]);

    const saveUser = async (updatedUser: IUser) => {
        try {
            if (!updatedUser || !updatedUser.id) {
                toast.error("User data is missing or incomplete");
                console.log("Cannot save user, user data is missing or incomplete");
                return;
            }

            const docRef = doc(db, "users", updatedUser.id);

            await setDoc(docRef, updatedUser);
            console.log("User data saved successfully:");
            setDbUser(updatedUser);
        } catch (e) {
            console.error("Error saving document: ", e);
            toast.error("Something went wrong with saving user data");
        }
    };

    useEffect(() => {
        googleUser && getUser();
    }, [getUser, googleUser]);

    const handleGetLikedGames = async () => {
        if (!googleUser) return;
        const res = await getLikedGames();
        res && setLikedGamesIds(res);
    };

    useEffect(() => {
        googleUser && handleGetLikedGames();
    }, [googleUser]);

    return (
        <AuthorizationContext.Provider
            value={{
                isAuthorized,
                setIsAuthorized,
                handleLogOut,
                googleUser,
                authToken,
                initLoading,
                dbUser,
                setDbUser,
                likedGamesIds,
                setLikedGamesIds,
                handleGetLikedGames,
                saveUser,
                getUser,
                validateUsername,
                saveUsernameInFirebase,
                isAdmin,
                isWhitelisted,
            }}>
            {children}
        </AuthorizationContext.Provider>
    );
};

export default AuthorizationContextProvider;
