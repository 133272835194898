import styled, {keyframes} from "styled-components";

const fadeOut = keyframes`
  0% {
    opacity: 1;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
`;

export const Background = styled.div<{$bgImage: string | null}>`
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background-color: #09090b;

    ${({$bgImage}) =>
        $bgImage &&
        `
    background-image: url('${$bgImage}');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    &:before {
        content: '';
        position: fixed;
        background: #000000DE;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        z-index:1;
    }

`}

    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
`;

export const LoadMaskWrapper = styled.div<{$show: boolean}>`
    width: 100%;
    height: calc(100% - 52px);
    bottom: 0;
    left: 0;
    position: fixed;
    display: flex;
    z-index: 9999;
    align-items: flex-end;
    justify-content: center;
    margin-top: 52px;
    animation: ${props => !props.$show && fadeOut} 1s forwards;
`;

export const LoadingSectionWrapper = styled.div`
    width: auto;
    margin-bottom: 40px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: column;
    row-gap: 8px;
    z-index: 2;

    .logoText {
        font-size: 48px;
        line-height: 120%;
        font-weight: var(--theme-font-bold);
        color: #fff;

        @media only screen and (max-width: 1024px) {
            font-size: 32px;
        }

        @media only screen and (max-width: 768px) {
            font-size: 24px;
        }
    }
`;

export const LoadingBarContainer = styled.div`
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 8px 0;
`;

export const LoadingTrack = styled.div`
    height: 8px;
    width: 100%;
    border-radius: 2px;
    background: var(--theme-grey-bg-secondary);
    position: relative;
`;

export const LoadingBar = styled.div<{width: number}>`
    height: 8px;
    width: ${props => props.width}%;
    transition: width 0.3s linear;
    background: #fafafa;
    border-radius: 2px;
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
`;
