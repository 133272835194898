import {IUser} from "../v2/pages/types";

const userLimits = {
    maxCoinsPerDay: 100,
};

// Middleware to check user ID and handle grant logic
export const grantMiddleware = (user: IUser) => {
    const currentTime = Date.now();
    console.log("grantMiddleware started");
    // If user data doesn't exist, initialize it
    if (!user.grantedCoins) {
        user.grantedCoins = 0;
    }
    if (!user.grantTime) {
        user.grantTime = currentTime;
    }

    // Check if the user has exceeded their daily limit
    if (user.grantedCoins >= userLimits.maxCoinsPerDay) {
        console.log("user has exceeded their daily limit");
        return;
    }

    // If it's been less than 10 seconds since the last grant, or more than 15 seconds, reset grant time
    if (currentTime - user.grantTime < 10000 || currentTime - user.grantTime > 15000) {
        user.grantTime = currentTime;
        console.log("it's been less than 10 or more that 15 seconds since the last grant, reset grant time");
    }

    // Calculate the number of coins to grant based on the time elapsed
    const elapsedTime = (currentTime - user.grantTime) / 10000;
    const additionalCoins = Math.floor(elapsedTime);
    user.grantedCoins += additionalCoins;
    console.log("Adding coins:", additionalCoins);
    return user;
};
