/*
 * Copyright 2017-2020 The ShadowEditor Authors. All rights reserved.
 *
 * Use of this source code is governed by a MIT-style
 * license that can be found in the LICENSE file.
 *
 * For more information, please visit: https://github.com/tengge1/ShadowEditor
 * You can also visit: https://gitee.com/tengge1/ShadowEditor
 */
import BaseSerializer from "../../BaseSerializer";

import LightShadowSerializer from "./LightShadowSerializer";
import DirectionalLightShadowSerializer from "./DirectionalLightShadowSerializer";
import SpotLightShadowSerializer from "./SpotLightShadowSerializer";
import PointLightShadowSerializer from "./PointLightShadowSerializer";

const Serializers = {
    LightShadow: LightShadowSerializer,
    DirectionalLightShadow: DirectionalLightShadowSerializer,
    SpotLightShadow: SpotLightShadowSerializer,
    PointLightShadow: PointLightShadowSerializer,
};

/**
 * LightShadowsSerializer
 *
 */
class LightShadowsSerializer extends BaseSerializer {
    toJSON(obj) {
        var serializer = Serializers[obj.constructor.name];

        if (serializer === undefined) {
            console.warn(`LightShadowsSerializer: No serializer with  ${obj.constructor.name}.`);
            return null;
        }

        return new serializer().toJSON(obj);
    }

    fromJSON(json, parent) {
        let generator = json.metadata.generator;

        let serializer = Serializers[generator.replace("Serializer", "")];

        if (serializer === undefined) {
            console.warn(`LightShadowsSerializer: No deserializer with ${generator}.`);
            return null;
        }

        new serializer().fromJSON(json, parent);
    }
}

export default LightShadowsSerializer;
