/*
 * Copyright 2017-2020 The ShadowEditor Authors. All rights reserved.
 *
 * Use of this source code is governed by a MIT-style
 * license that can be found in the LICENSE file.
 *
 * For more information, please visit: https://github.com/tengge1/ShadowEditor
 * You can also visit: https://gitee.com/tengge1/ShadowEditor
 */
import BaseSerializer from "../../BaseSerializer";
import CamerasSerializer from "../../camera/CamerasSerializer";
import WebGLRenderTargetSerializer from "../../core/WebGLRenderTargetSerializer";
import * as THREE from "three";

/**
 * LightShadowSerializer
 *
 */
class LightShadowSerializer extends BaseSerializer {
    toJSON(obj) {
        var json = BaseSerializer.prototype.toJSON.call(this, obj);

        json.bias = obj.bias;
        json.normalBias = obj.normalBias;
        json.camera = new CamerasSerializer().toJSON(obj.camera);
        json.map = !obj.map ? null : new WebGLRenderTargetSerializer().toJSON(obj.map);
        json.mapSize = obj.mapSize;
        json.radius = obj.radius;

        return json;
    }

    fromJSON(json, parent) {
        let camera = new CamerasSerializer().fromJSON(json.camera);

        parent.shadow.bias = json.bias;
        parent.shadow.normalBias = json.normalBias;
        parent.shadow.camera.copy(camera);
        parent.shadow.mapSize.copy(json.mapSize);
        parent.shadow.radius = json.radius;
    }
}

export default LightShadowSerializer;
