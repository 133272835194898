import {useState, useEffect} from "react";
import {ENEMY_TYPES, EnemyBehaviorInterface, WEAPON_TYPES} from "../../../../../types/editor";
import global from "../../../../../global";
import EnemyBehaviorConverter from "../../../../../serialization/behaviours/EnemyBehaviorConverter";
import {Separator} from "../common/Separator";
import {PanelCheckbox} from "../common/PanelCheckbox";
import {SelectionOfButtons} from "../common/SelectionOfButtons";
import {Heading} from "../common/Heading";
import {NumericInputRow} from "../common/NumericInputRow";
import {SelectRow} from "../common/SelectRow";
import {StyledButton} from "../../common/StyledButton";

const ANIMATIONS: {
    label: string;
    key: keyof EnemyBehaviorInterface;
}[] = [
    {label: "Idle", key: "idleAnimation"},
    {label: "Walking", key: "walkAnimation"},
    {label: "Running", key: "runAnimation"},
    {label: "Jumping", key: "jumpAnimation"},
    {label: "Crouching", key: "crouchAnimation"},
    {label: "Tripping", key: "trippingAnimation"},
    {label: "Falling", key: "fallAnimation"},
    {label: "Dying", key: "dieAnimation"},
    {label: "Attacking", key: "attackAnimation"},
];

type Props = {
    behavior: EnemyBehaviorInterface;
};

export const EnemyBehaviors = ({behavior: initialBehavior}: Props) => {
    const [behavior, setBehavior] = useState<EnemyBehaviorInterface>({
        ...initialBehavior,
        animationClips: [],
    });
    const [animationOptions, setAnimationOptions] = useState([{key: "0", value: "none"}]);
    const [weaponOptions, setWeaponOptions] = useState([{key: "0", value: "none"}]);
    const [animationsReady, setAnimationsReady] = useState(false);

    const app = (global as any).app;
    const editor = app.editor;
    const selected = editor.selected;

    useEffect(() => {
        const selected = editor.selected;
        if (selected && selected._obj && selected._obj.animations) {
            const loadGLTFAndExtractAnimationClips = () => {
                const animations = selected._obj.animations;
                if (animations && animations.length > 0) {
                    const animationClips = animations.map((clip: any) => clip.name);
                    setBehavior(prevState => ({...prevState, animationClips}));
                }
            };

            loadGLTFAndExtractAnimationClips();
        }
    }, [selected]);

    const targetBehavior = EnemyBehaviorConverter.DEFAULT.getBehavior(selected, behavior.id) || behavior;

    const handleInputChange = (value: number | string | boolean, name: string) => {
        const selected = editor.selected;
        if (selected && targetBehavior) {
            (targetBehavior as any)[name] = value;
            setBehavior({...behavior, [name]: value});

            if (name === "enemyType") {
                if (value === ENEMY_TYPES.AGGRESIVE) {
                    targetBehavior.attackDistance = 200;
                    targetBehavior.fightDistance = 500;
                    targetBehavior.attackSpeed = 100;
                    targetBehavior.roamDistance = 1000;
                    targetBehavior.rotationSpeed = 200;
                    targetBehavior.directionDuration = 50;
                } else if (value === ENEMY_TYPES.DEFENSIVE) {
                    targetBehavior.attackDistance = 100;
                    targetBehavior.fightDistance = 200;
                    targetBehavior.attackSpeed = 100;
                    targetBehavior.roamDistance = 300;
                    targetBehavior.rotationSpeed = 300;
                    targetBehavior.directionDuration = 10;
                } else if (value === ENEMY_TYPES.PATROLS) {
                    targetBehavior.attackDistance = 50;
                    targetBehavior.fightDistance = 100;
                    targetBehavior.attackSpeed = 20;
                    targetBehavior.roamDistance = 600;
                    targetBehavior.rotationSpeed = 50;
                    targetBehavior.directionDuration = 500;
                }
            }

            app.call(`objectChanged`, app.editor, app.editor.selected);
        }
    };

    const autoDetectAnimations = (mappedAnimations: any) => {
        const selected = editor.selected;

        if (selected && selected._obj && selected._obj.animations && selected._obj.animations.length > 0) {
            const findAnimationByKeyword = (keywords: string[], excludeKeywords: string[] = []) => {
                const cleanString = (str: string) => str.toLowerCase().replace(/[^a-z0-9]/g, "");

                for (const keyword of keywords) {
                    const cleanKeyword = cleanString(keyword);

                    const animation = mappedAnimations.find((animation: any) => {
                        const cleanValue = cleanString(animation.value);

                        const containsKeyword = cleanValue.includes(cleanKeyword);

                        const excludesUnwanted = excludeKeywords.every(exclude => {
                            const cleanExclude = cleanString(exclude);
                            const isExcluded = cleanValue.includes(cleanExclude);
                            return !isExcluded;
                        });

                        return containsKeyword && excludesUnwanted;
                    });

                    if (animation) {
                        return animation.value;
                    }
                }
                return "none";
            };

            const idleAnimation = findAnimationByKeyword(["idle"]);
            const walkAnimation = findAnimationByKeyword(["walk"], ["run"]);
            const runAnimation = findAnimationByKeyword(["run"]);
            const attackAnimation = findAnimationByKeyword(["attack"]);
            const jumpAnimation = findAnimationByKeyword(["jump"]);
            const fallAnimation = findAnimationByKeyword(["fall"]);
            const dieAnimation = findAnimationByKeyword(["die"]);
            const crouchAnimation = findAnimationByKeyword(["crouch"]);
            const trippingAnimation = findAnimationByKeyword(["trip"]);

            const obj = {
                idleAnimation,
                walkAnimation,
                runAnimation,
                attackAnimation,
                jumpAnimation,
                fallAnimation,
                dieAnimation,
                crouchAnimation,
                trippingAnimation,
            };
            setBehavior(prevState => ({
                ...prevState,
                idleAnimation: prevState.idleAnimation || obj.idleAnimation,
                walkAnimation: prevState.walkAnimation || obj.walkAnimation,
                runAnimation: prevState.runAnimation || obj.runAnimation,
                attackAnimation: prevState.attackAnimation || obj.attackAnimation,
                jumpAnimation: prevState.jumpAnimation || obj.jumpAnimation,
                fallAnimation: prevState.fallAnimation || obj.fallAnimation,
                dieAnimation: prevState.dieAnimation || obj.dieAnimation,
                crouchAnimation: prevState.crouchAnimation || obj.crouchAnimation,
                trippingAnimation: prevState.trippingAnimation || obj.trippingAnimation,
            }));

            Object.keys(obj).forEach(key => {
                if (obj[key as keyof typeof obj] !== "none") {
                    if ((targetBehavior as any)[key] && (targetBehavior as any)[key] !== "none") {
                        return;
                    }
                    (targetBehavior as any)[key] = obj[key as keyof typeof obj];
                }
            });
            app.call(`objectChanged`, app.editor, app.editor.selected);
        }
    };

    useEffect(() => {
        if (behavior.animationClips.length > 0 && !animationsReady) {
            const animations: {
                key: string;
                value: string;
            }[] = behavior.animationClips.map((el, index) => ({
                key: `${index + 1}`,
                value: el,
            }));
            const mappedAnimations = animationOptions.concat(animations);
            setAnimationOptions(mappedAnimations);
            autoDetectAnimations(mappedAnimations);
            setAnimationsReady(true);
        }
    }, [behavior.animationClips]);

    useEffect(() => {
        setWeaponOptions(
            weaponOptions.concat(
                Object.keys(WEAPON_TYPES).map((key, index) => ({
                    key: `${index}`,
                    value: WEAPON_TYPES[key as keyof typeof WEAPON_TYPES],
                })),
            ),
        );
    }, []);

    return (
        <>
            <PanelCheckbox
                text="Start on Trigger"
                checked={!!targetBehavior.enemyEnabled}
                onChange={() => handleInputChange(!targetBehavior.enemyEnabled, "enemyEnabled")}
            />
            <Separator margin="8px 0" />
            <Heading margin={"0 0 12px"}>Enemy Type</Heading>
            <SelectionOfButtons>
                {Object.keys(ENEMY_TYPES).map(key => {
                    const type = ENEMY_TYPES[key as unknown as keyof typeof ENEMY_TYPES];
                    return (
                        <StyledButton
                            width="100px"
                            isBlue={targetBehavior.enemyType === type}
                            isActive={targetBehavior.enemyType !== type}
                            onClick={() => handleInputChange(type, "enemyType")}
                            key={key}>
                            <span>{type}</span>
                        </StyledButton>
                    );
                })}
            </SelectionOfButtons>
            <Separator margin="12px 0" />
            <Heading margin={"0 0 12px"}>Enemy settings</Heading>
            <PanelCheckbox
                text="Show Roam Area in editor"
                checked={!!targetBehavior.showRoamArea}
                onChange={() => handleInputChange(!behavior.showRoamArea, "showRoamArea")}
            />
            <Separator margin="0 0 12px 0" invisible />
            <SelectRow
                $margin="0 0 8px 0"
                label="Type of weapon"
                data={weaponOptions}
                value={weaponOptions.find(el => el.value === behavior.weapon)}
                onChange={item => handleInputChange(item.value, "weapon")}
            />
            <NumericInputRow
                width="75px"
                label="Health"
                value={targetBehavior.health}
                setValue={value => handleInputChange(value, "health")}
            />
            <NumericInputRow
                width="75px"
                label="Movement Speed"
                value={targetBehavior.movementSpeed}
                setValue={value => handleInputChange(value, "movementSpeed")}
            />
            <NumericInputRow
                width="75px"
                label="Attack Damage"
                value={targetBehavior.attackDamage}
                setValue={value => handleInputChange(value, "attackDamage")}
            />
            <NumericInputRow
                width="75px"
                label="Respawn"
                value={targetBehavior.respawnAmount}
                setValue={value => handleInputChange(value, "respawnAmount")}
            />
            <NumericInputRow
                width="75px"
                label=" Fall Delay"
                value={targetBehavior.fallDelay}
                setValue={value => handleInputChange(value, "fallDelay")}
            />
            {targetBehavior.enemyType === ENEMY_TYPES.CUSTOM && (
                <>
                    <NumericInputRow
                        width="75px"
                        label="Attack Distance"
                        value={targetBehavior.attackDistance}
                        setValue={value => handleInputChange(value, "attackDistance")}
                    />
                    <NumericInputRow
                        width="75px"
                        label="Fight Distance"
                        value={targetBehavior.fightDistance}
                        setValue={value => handleInputChange(value, "fightDistance")}
                    />
                    <NumericInputRow
                        width="75px"
                        label="Attack Speed"
                        value={targetBehavior.attackSpeed}
                        setValue={value => handleInputChange(value, "attackSpeed")}
                    />
                    <NumericInputRow
                        width="75px"
                        label="Roam Distance"
                        value={targetBehavior.roamDistance}
                        setValue={value => handleInputChange(value, "roamDistance")}
                    />
                    <NumericInputRow
                        width="75px"
                        label=" Rotation Speed"
                        value={targetBehavior.rotationSpeed}
                        setValue={value => handleInputChange(value, "rotationSpeed")}
                    />
                    <NumericInputRow
                        width="75px"
                        label=" Direction Duration"
                        value={targetBehavior.directionDuration}
                        setValue={value => handleInputChange(value, "directionDuration")}
                    />
                </>
            )}
            <Heading margin={"12px 0"}>Animations</Heading>
            {ANIMATIONS.map(element => (
                <SelectRow
                    $margin="0 0 8px 0"
                    label={element.label}
                    data={animationOptions}
                    value={
                        animationOptions.find(item => item.value === behavior[element.key]) || {key: "0", value: "none"}
                    }
                    onChange={item => handleInputChange(item.value, element.key)}
                    key={element.label}
                />
            ))}
        </>
    );
};
