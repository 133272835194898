/*
 * Copyright 2017-2020 The ShadowEditor Authors. All rights reserved.
 *
 * Use of this source code is governed by a MIT-style
 * license that can be found in the LICENSE file.
 *
 * For more information, please visit: https://github.com/tengge1/ShadowEditor
 * You can also visit: https://gitee.com/tengge1/ShadowEditor
 */
import Ajax from "./Ajax";

/**
 * JS下载器
 *
 */
class JsLoader {
    constructor() {
        this.assets = [];
    }

    load(url) {
        const data = {
            url,
            script: null,
        };
        this.assets.push(data);
        return new Promise(resolve => {
            Ajax.get({url, needAuthorization: false})
                .then(response => {
                    data.script = response.data;
                    resolve(data);
                })
                .catch(() => {
                    console.warn(`JsLoader: ${url} loaded failed.`);
                    resolve(null);
                });
        });
    }

    eval() {
        const eval2 = eval;

        let script = "";

        this.assets.forEach(n => {
            if (n.script) {
                script += n.script + "\n";
            }
        });

        if (script) {
            eval2.call(window, script);
        }
    }
}

export default JsLoader;
