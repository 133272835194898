import BaseBehaviorConverter from "./BaseBehaviorConverter";
import * as THREE from "three";
import {BehaviorUpdater} from "../../behaviors/BehaviorManager";
import CharacterBehaviorUpdater from "./CharacterBehaviorUpdater";
import {
    CHARACTER_TYPES,
    CharacterBehaviorInterface,
    OBJECT_TYPES,
    SideScrollerOptionsInterface,
    ThirdPersonOptionsInterface,
    VehicleOptionsInterface,
} from "../../types/editor";
import {AnimationClip, Object3D} from "three";

class CharacterBehaviorConverter extends BaseBehaviorConverter<CharacterBehaviorInterface> {
    public static DEFAULT = new CharacterBehaviorConverter(null);
    constructor(obj: any) {
        super(obj);
    }

    private isModelAnimation(animations: AnimationClip[], animationName: string): boolean {
        return animations?.some(clip => clip.name === animationName);
    }

    convert(target: Object3D, behavior: any): BehaviorUpdater {
        target.userData.player = true;
        //TODO: we support both dynamic and kinematic objects, so physics is not required
        target.userData.physics = target.userData.physics || {
            enabled: true,
            shape: "btBoxShape",
            mass: 0,
            inertia: {x: 0, y: 0, z: 0},
            restitution: 0,
            type: "rigidBody",
            ctype: "Static",
        };
        this.obj.camera.userData.control = behavior.control;
        //TODO: refactor after Phase 2 demo
        if (behavior.control === CHARACTER_TYPES.THIRD_PERSON) {
            this.obj.camera.userData.thirdPersonOptions = this.obj.camera.userData.thirdPersonOptions || {
                walkSpeed: 15.0,
                rotationSpeed: 25.0,
                runSpeed: 35.0,
                movementSpeed: 10.0,
                lookSpeed: 0.05,
                lookVertical: true,
                autoForward: false,
                activeLook: true,
                heightSpeed: false,
                heightCoef: 1.0,
                heightMin: 0.0,
                heightMax: 1.0,
                constrainVertical: false,
                verticalMin: 0,
                verticalMax: Math.PI,
                initialXRotation: "none",
                idleAnimation: "Idle",
                walkAnimation: "Walk",
                runAnimation: "Run",
                jumpAnimation: "Jump",
                animationNames: {
                    Idle: "Idle",
                    Walk: "Walk",
                    Run: "Run",
                    Jump: "Jump",
                },
            };
            this.obj.camera.userData.thirdPersonOptions.selectedModel = target.name;
            this.obj.camera.userData.thirdPersonOptions.selectedModelUUID = target.uuid;
        } else if (behavior.control === "SideScroller" || behavior.control === CHARACTER_TYPES.SIDE_SCROLLER) {
            this.obj.camera.userData.sideScrollerOptions = this.obj.camera.userData.sideScrollerOptions || {
                acceleration: 0.003,
                boundary: 2000,
                jumpHeight: 4,
                lockMoveBackward: true,
                lockMoveForward: true,
                lockMoveLeft: false,
                lockMoveRight: false,
                margin: 0.1,
                maxMovementDistanceX: 5,
                maxSpeed: 0.3,
                minMovementDistanceX: -5,
                playerGravity: -75,
                player_mass: 1,
                player_head_height: 2,
                reticle_color: "#00ff00",
                reticle_size: 0.1,
                selected_model_name: "",
                forwardPosition: 0,
                backwardPosition: 0,
                weaponEnabled: true,
            };
            this.obj.camera.userData.sideScrollerOptions.selectedModel = target.name;
            this.obj.camera.userData.sideScrollerOptions.selectedModelUUID = target.uuid;
        } else if (behavior.control === CHARACTER_TYPES.VEHICLE || behavior.control === "VehicleControl") {
            this.obj.camera.userData.VehicleOptions = this.obj.camera.userData.VehicleOptions || {
                leftFrontWheel: "none",
                rightFrontWheel: "none",
                leftRearWheel: "none",
                rightRearWheel: "none",
                steeringWheel: "none",
                acceleration: 0.003,
                maxSpeed: 0.3,
            };
            this.obj.camera.userData.VehicleOptions.selectedModel = target.name;
            this.obj.camera.userData.VehicleOptions.selectedModelUUID = target.uuid;
        }

        return new CharacterBehaviorUpdater(target, behavior.control);
    }

    resetCameraControl() {
        this.obj.camera.userData.control = "OrbitControls";
    }
    getBehavior(target: Object3D): CharacterBehaviorInterface | null {
        if (!target) return null;

        let behavior = super.findBehavior(target, OBJECT_TYPES.CHARACTER) as CharacterBehaviorInterface;
        if (!behavior) {
            console.warn("Object doesn't have CHARACTER behavior: ", target);
            return null;
        }

        return behavior;
    }

    getDefaultBehavior(id: string): CharacterBehaviorInterface {
        return {
            id,
            type: OBJECT_TYPES.CHARACTER,
            control: CHARACTER_TYPES.THIRD_PERSON,
            health: 100,
            movementSpeed: 100,
            useDemoWeapon: false,
            autoForward: false,
            shield: 100,
            walkSpeed: 15,
            runSpeed: 35,
            lookSpeed: 50,
            jumpStrength: 10,
            age: 30,
        } as CharacterBehaviorInterface;
    }

    getDefaultThirdPersonOptions(sceneModels: any): ThirdPersonOptionsInterface {
        return {
            sceneModels: sceneModels || [],
            selectedModelUUID: "",
            selectedModel: "none",
            animationNames: {},
            walkAnimation: "none",
            runAnimation: "none",
            jumpAnimation: "none",
            idleAnimation: "none",
            fallDelay: 0,
            fallAnimation: "none",
            dieAnimation: "none",
            leftDirectionAnimation: "none",
            rightDirectionAnimation: "none",
            reverseDirectionAnimation: "none",
            shootIdleAnimation: "none",
            shootWalkAnimation: "none",
            shootRunAnimation: "none",
            reloadIdleAnimation: "none",
            reloadWalkAnimation: "none",
            reloadRunAnimation: "none",
            swordAnimation: "none",
            punchAnimation: "none",
            pushAnimation: "none",
            kickAnimation: "none",
            throwAnimation: "none",
            pickUpAnimation: "none",
            carryAnimation: "none",
            initialXRotation: "none",
            walkSpeed: 15,
            runSpeed: 35,
            turnSpeed: 25,
            jumpHeight: 5,
            playerGravity: -75,
            cameraMINDistance: 10,
            cameraMAXDistance: 10,
            cameraFov: 50,
            overTheShoulder: true,
            otsRightShoulderCamera: true,
            slopeTolerance: 0.15,
        };
    }

    updateThirdPersonOptions(model: Object3D, camera: any) {
        const thirdPersonOptions = camera.userData.thirdPersonOptions;
        if (model && thirdPersonOptions) {
            thirdPersonOptions.selectedModelUUID = model.uuid;
            thirdPersonOptions.selectedModel = model.name;

            const animations = model.animations?.length > 0 ? model.animations : model._obj?.animations;

            if (animations) {
                thirdPersonOptions.animationNames = {};

                for (let i = 0; i < animations.length; i++) {
                    thirdPersonOptions.animationNames[animations[i].name] = animations[i].name;
                }

                thirdPersonOptions.animationNames["none"] = "none";
            }

            if (thirdPersonOptions.animationNames) {
                if (
                    thirdPersonOptions.animationNames["Idle"] &&
                    (thirdPersonOptions.idleAnimation === "none" ||
                        !this.isModelAnimation(animations, thirdPersonOptions.idleAnimation))
                ) {
                    thirdPersonOptions.idleAnimation = "Idle";
                }

                if (
                    thirdPersonOptions.animationNames["Walk"] &&
                    (thirdPersonOptions.walkAnimation === "none" ||
                        !this.isModelAnimation(animations, thirdPersonOptions.walkAnimation))
                ) {
                    thirdPersonOptions.walkAnimation = "Walk";
                }

                if (
                    thirdPersonOptions.animationNames["Run"] &&
                    (thirdPersonOptions.runAnimation === "none" ||
                        !this.isModelAnimation(animations, thirdPersonOptions.runAnimation))
                ) {
                    thirdPersonOptions.runAnimation = "Run";
                }

                if (
                    thirdPersonOptions.animationNames["Shoot"] &&
                    (thirdPersonOptions.shootAnimation === "none" ||
                        !this.isModelAnimation(animations, thirdPersonOptions.shootAnimation))
                ) {
                    thirdPersonOptions.shootAnimation = "Shoot";
                }

                if (
                    thirdPersonOptions.animationNames["Reload"] &&
                    (thirdPersonOptions.reloadAnimation === "none" ||
                        !this.isModelAnimation(animations, thirdPersonOptions.reloadAnimation))
                ) {
                    thirdPersonOptions.reloadAnimation = "Reload";
                }

                if (
                    thirdPersonOptions.animationNames["StrafeLeft"] &&
                    (thirdPersonOptions.leftDirectionAnimation === "none" ||
                        !this.isModelAnimation(animations, thirdPersonOptions.leftDirectionAnimation))
                ) {
                    thirdPersonOptions.leftDirectionAnimation = "StrafeLeft";
                }

                if (
                    thirdPersonOptions.animationNames["StrafeRight"] &&
                    (thirdPersonOptions.rightDirectionAnimation === "none" ||
                        !this.isModelAnimation(animations, thirdPersonOptions.rightDirectionAnimation))
                ) {
                    thirdPersonOptions.rightDirectionAnimation = "StrafeRight";
                }

                if (
                    thirdPersonOptions.animationNames["Carry"] &&
                    (thirdPersonOptions.carryAnimation === "none" ||
                        !this.isModelAnimation(animations, thirdPersonOptions.carryAnimation))
                ) {
                    thirdPersonOptions.carryAnimation = "Carry";
                }

                if (
                    thirdPersonOptions.animationNames["Push"] &&
                    (thirdPersonOptions.pushAnimation === "none" ||
                        !this.isModelAnimation(animations, thirdPersonOptions.pushAnimation))
                ) {
                    thirdPersonOptions.pushAnimation = "Push";
                }

                if (
                    thirdPersonOptions.animationNames["BackUp"] &&
                    (thirdPersonOptions.reverseDirectionAnimation === "none" ||
                        !this.isModelAnimation(animations, thirdPersonOptions.reverseDirectionAnimation))
                ) {
                    thirdPersonOptions.reverseDirectionAnimation = "BackUp";
                }

                if (
                    thirdPersonOptions.animationNames["Punch"] &&
                    (thirdPersonOptions.punchAnimation === "none" ||
                        !this.isModelAnimation(animations, thirdPersonOptions.punchAnimation))
                ) {
                    thirdPersonOptions.punchAnimation = "Punch";
                }

                if (
                    thirdPersonOptions.animationNames["Sword"] &&
                    (thirdPersonOptions.swordAnimation === "none" ||
                        !this.isModelAnimation(animations, thirdPersonOptions.swordAnimation))
                ) {
                    thirdPersonOptions.swordAnimation = "Sword";
                }

                if (
                    thirdPersonOptions.animationNames["Jump"] &&
                    (thirdPersonOptions.jumpAnimation === "none" ||
                        !this.isModelAnimation(animations, thirdPersonOptions.jumpAnimation))
                ) {
                    thirdPersonOptions.jumpAnimation = "Jump";
                }

                if (
                    thirdPersonOptions.animationNames["Fall"] &&
                    (thirdPersonOptions.fallAnimation === "none" ||
                        !this.isModelAnimation(animations, thirdPersonOptions.fallAnimation))
                ) {
                    thirdPersonOptions.fallAnimation = "Fall";
                }

                if (
                    thirdPersonOptions.animationNames["Die"] &&
                    (thirdPersonOptions.dieAnimation === "none" ||
                        !this.isModelAnimation(animations, thirdPersonOptions.dieAnimation))
                ) {
                    thirdPersonOptions.dieAnimation = "Die";
                }
            }
        }
    }

    getDefaultSideScrollerOptions(sceneModels: any): {
        throwAnimation: string;
        otsRightShoulderCamera: boolean;
        runSpeed: number;
        reloadIdleAnimation: string;
        pickUpAnimation: string;
        reloadRunAnimation: string;
        fallAnimation: string;
        shootWalkAnimation: string;
        jumpHeight: number;
        reloadIdledAnimation: string;
        selectedModelUUID: string;
        punchAnimation: string;
        leftDirectionAnimation: string;
        reverseDirectionAnimation: string;
        carryAnimation: string;
        cameraMAXDistance: number;
        reloadWalkAnimation: string;
        initialXRotation: string;
        slopeTolerance: number;
        selectedModel: string;
        overTheShoulder: boolean;
        walkSpeed: number;
        animationNames: {};
        idleAnimation: string;
        jumpAnimation: string;
        turnSpeed: number;
        dieAnimation: string;
        shootRunAnimation: string;
        cameraMINDistance: number;
        cameraFov: number;
        shootIdleAnimation: string;
        swordAnimation: string;
        runAnimation: string;
        rightDirectionAnimation: string;
        playerGravity: number;
        fallDelay: number;
        pushAnimation: string;
        walkAnimation: string;
        sceneModels: any;
        kickAnimation: string;
    } {
        return {
            reloadIdledAnimation: "",
            sceneModels: sceneModels || [],
            selectedModelUUID: "",
            selectedModel: "none",
            animationNames: {},
            walkAnimation: "none",
            runAnimation: "none",
            jumpAnimation: "none",
            idleAnimation: "none",
            fallDelay: 0,
            fallAnimation: "none",
            dieAnimation: "none",
            leftDirectionAnimation: "none",
            rightDirectionAnimation: "none",
            reverseDirectionAnimation: "none",
            shootIdleAnimation: "none",
            shootWalkAnimation: "none",
            shootRunAnimation: "none",
            reloadIdleAnimation: "none",
            reloadWalkAnimation: "none",
            reloadRunAnimation: "none",
            swordAnimation: "none",
            punchAnimation: "none",
            pushAnimation: "none",
            kickAnimation: "none",
            throwAnimation: "none",
            pickUpAnimation: "none",
            carryAnimation: "none",
            initialXRotation: "none",
            walkSpeed: 15,
            runSpeed: 35,
            turnSpeed: 25,
            jumpHeight: 5,
            playerGravity: -75,
            cameraMINDistance: 10,
            cameraMAXDistance: 10,
            cameraFov: 50,
            overTheShoulder: true,
            otsRightShoulderCamera: true,
            slopeTolerance: 0.15,
        };
    }

    updateSideScrollerOptions(model: THREE.Object3D, camera: any) {
        const sideScrollerOptions = camera.userData.sideScrollerOptions;
        if (model && sideScrollerOptions) {
            sideScrollerOptions.selectedModelUUID = model.uuid;
            sideScrollerOptions.selectedModel = model.name;

            const animations = model.animations?.length > 0 ? model.animations : model._obj?.animations;

            if (animations) {
                sideScrollerOptions.animationNames = {};

                for (let i = 0; i < animations.length; i++) {
                    sideScrollerOptions.animationNames[animations[i].name] = animations[i].name;
                }

                sideScrollerOptions.animationNames["none"] = "none";
            }

            if (sideScrollerOptions.animationNames) {
                if (
                    sideScrollerOptions.animationNames["Idle"] &&
                    (sideScrollerOptions.idleAnimation === "none" ||
                        !this.isModelAnimation(animations, sideScrollerOptions.idleAnimation))
                ) {
                    sideScrollerOptions.idleAnimation = "Idle";
                }

                if (
                    sideScrollerOptions.animationNames["Walk"] &&
                    (sideScrollerOptions.walkAnimation === "none" ||
                        !this.isModelAnimation(animations, sideScrollerOptions.walkAnimation))
                ) {
                    sideScrollerOptions.walkAnimation = "Walk";
                }

                if (
                    sideScrollerOptions.animationNames["Run"] &&
                    (sideScrollerOptions.runAnimation === "none" ||
                        !this.isModelAnimation(animations, sideScrollerOptions.runAnimation))
                ) {
                    sideScrollerOptions.runAnimation = "Run";
                }

                if (
                    sideScrollerOptions.animationNames["Shoot"] &&
                    (sideScrollerOptions.shootAnimation === "none" ||
                        !this.isModelAnimation(animations, sideScrollerOptions.shootAnimation))
                ) {
                    sideScrollerOptions.shootAnimation = "Shoot";
                }

                if (
                    sideScrollerOptions.animationNames["Reload"] &&
                    (sideScrollerOptions.reloadAnimation === "none" ||
                        !this.isModelAnimation(animations, sideScrollerOptions.reloadAnimation))
                ) {
                    sideScrollerOptions.reloadAnimation = "Reload";
                }

                if (
                    sideScrollerOptions.animationNames["walk"] &&
                    (sideScrollerOptions.leftDirectionAnimation === "none" ||
                        !this.isModelAnimation(animations, sideScrollerOptions.leftDirectionAnimation))
                ) {
                    sideScrollerOptions.leftDirectionAnimation = "walk";
                }

                if (
                    sideScrollerOptions.animationNames["walk"] &&
                    (sideScrollerOptions.rightDirectionAnimation === "none" ||
                        !this.isModelAnimation(animations, sideScrollerOptions.rightDirectionAnimation))
                ) {
                    sideScrollerOptions.rightDirectionAnimation = "walk";
                }

                if (
                    sideScrollerOptions.animationNames["Carry"] &&
                    (sideScrollerOptions.carryAnimation === "none" ||
                        !this.isModelAnimation(animations, sideScrollerOptions.carryAnimation))
                ) {
                    sideScrollerOptions.carryAnimation = "Carry";
                }

                if (
                    sideScrollerOptions.animationNames["walk"] &&
                    (sideScrollerOptions.reverseDirectionAnimation === "none" ||
                        !this.isModelAnimation(animations, sideScrollerOptions.reverseDirectionAnimation))
                ) {
                    sideScrollerOptions.reverseDirectionAnimation = "walk";
                }

                if (
                    sideScrollerOptions.animationNames["Punch"] &&
                    (sideScrollerOptions.punchAnimation === "none" ||
                        !this.isModelAnimation(animations, sideScrollerOptions.punchAnimation))
                ) {
                    sideScrollerOptions.punchAnimation = "Punch";
                }

                if (
                    sideScrollerOptions.animationNames["Sword"] &&
                    (sideScrollerOptions.swordAnimation === "none" ||
                        !this.isModelAnimation(animations, sideScrollerOptions.swordAnimation))
                ) {
                    sideScrollerOptions.swordAnimation = "Sword";
                }

                if (
                    sideScrollerOptions.animationNames["Jump"] &&
                    (sideScrollerOptions.jumpAnimation === "none" ||
                        !this.isModelAnimation(animations, sideScrollerOptions.jumpAnimation))
                ) {
                    sideScrollerOptions.jumpAnimation = "Jump";
                }

                if (
                    sideScrollerOptions.animationNames["Fall"] &&
                    (sideScrollerOptions.fallAnimation === "none" ||
                        !this.isModelAnimation(animations, sideScrollerOptions.fallAnimation))
                ) {
                    sideScrollerOptions.fallAnimation = "Fall";
                }

                if (
                    sideScrollerOptions.animationNames["Die"] &&
                    (sideScrollerOptions.dieAnimation === "none" ||
                        !this.isModelAnimation(animations, sideScrollerOptions.dieAnimation))
                ) {
                    sideScrollerOptions.dieAnimation = "Die";
                }
            }
        }
    }

    getDefaultVehicleOptions(): VehicleOptionsInterface {
        return {
            acceleration: 0.003,
            maxSpeed: 0.3,
            leftFrontWheel: "none",
            rightFrontWheel: "none",
            leftRearWheel: "none",
            rightRearWheel: "none",
            steeringWheel: "none",
            trackModel: "none",
            trackSurface: "none",
            trackBoundary: "none",
            engineHorsepower: 4000,
            tireFriction: 1000,
            brakeForce: 100,
            cameraMINDistance: 10,
            cameraMAXDistance: 10,
            cameraFov: 50,
        };
    }
}

export default CharacterBehaviorConverter;
