import * as THREE from "three";
import {CSS3DObject, CSS3DSprite} from "three/examples/jsm/renderers/CSS3DRenderer";

class WebElement {
    url: string;
    width: string;
    height: string;
    backgroundColor: string;
    borderRadius: string;
    object: THREE.Group | null = null;
    isSprite: boolean = false;
    isLoop: boolean = false;

    constructor(
        url: string,
        isSprite: boolean,
        isLoop: boolean,
        width: number,
        height: number,
        backgroundColor: string,
        borderRadius: string,
    ) {
        this.url = url;
        this.isSprite = isSprite;
        this.isLoop = isLoop;
        this.width = `${width}px`;
        this.height = `${height}px`;
        this.backgroundColor = backgroundColor;
        this.borderRadius = borderRadius;
        this.object = this.create();
    }

    create = () => {
        const div = document.createElement("div");
        div.style.width = this.width;
        div.style.height = this.height;
        div.style.backgroundColor = this.backgroundColor;

        const iframe = document.createElement("iframe");
        iframe.style.width = this.width;
        iframe.style.height = this.height;
        iframe.style.border = this.borderRadius;
        iframe.src = this.isLoop ? this.url + "?version=3&loop=1" : this.url;
        div.appendChild(iframe);
        div.style.pointerEvents = "none";
        iframe.style.pointerEvents = "all";
        iframe.allow = "autoplay";

        const object = this.isSprite ? new CSS3DSprite(div) : new CSS3DObject(div);
        object.scale.set(0.01, 0.01, 0.01);

        const group = new THREE.Group();
        group.add(object);
        return group;
    };
}

export default WebElement;
