/* eslint-disable react/no-multi-comp */
import React, { useEffect, useState } from "react";
import * as THREE from "three";
import { DefaultWrapper } from "../../styles/Behaviors.style";
import global from "../../../../../../global";
import CharacterBehaviorConverter from "../../../../../../serialization/behaviours/CharacterBehaviorConverter";
import { NumericInputRow } from "../../common/NumericInputRow";
import { SelectRow } from "../../common/SelectRow";
import { OBJECT_TYPES, ANIMATION_ACTION_TYPES, WEAPON_TYPES, ThirdPersonOptionsInterface } from "../../../../../../types/editor";



const weaponOptions = Object.values(WEAPON_TYPES).map((type) => ({
    key: type,
    value: type,
}));

const initialXRotations = [
    {
        key: "none",
        value: "None",
    },
    {
        key: "-x",
        value: "Negative X",
    },
    {
        key: "+x",
        value: "Positive X",
    },
];


type AnimationOption = {
    key: string;
    value: string;
};

const getSceneModels = () => {
    const editor = (global as any).app.editor;
    const sceneModels = {};

    editor.scene.traverse((obj: any) => {
        if (obj.type === "Group" && obj.parent === editor.scene) {
            //@ts-ignore
            sceneModels[obj.name] = obj.name;
        }
    });

    return sceneModels;
};

export const ThirdPersonOptions = () => {
    const app = (global as any).app;
    const editor = app.editor;
    const model = editor.selected;
    const thisModelCharacterBehavior = model.userData.behaviors.find((el: any) => el.type === "Character");
    const targetBehaviorthirdPersonOptions = thisModelCharacterBehavior?.thirdPersonOptions;

    const [selectedWeaponType, setSelectedWeaponType] = useState("none");
    const [animations, setAnimations] = useState([{ key: "0", value: "none" }]);
    const camera = editor.camera;

    // OpenAI question and answer states
    const [question, setQuestion] = useState("");
    const [answer, setAnswer] = useState("");
    const [loading, setLoading] = useState(false);

    const [targetBehavior, setTargetBehavior] = useState(() => {
        return targetBehaviorthirdPersonOptions
            ? { thirdPersonOptions: targetBehaviorthirdPersonOptions }
            : {
                thirdPersonOptions: {
                    ...CharacterBehaviorConverter.DEFAULT.getDefaultThirdPersonOptions(getSceneModels()),
                    initialXRotation: "-x"
                },
            };
    });

    const handleInputChange = (value: string | boolean | number, name: string) => {
        if (camera) {
            setTargetBehavior((prevState: any) => {
                const newState = { ...prevState };
                newState.thirdPersonOptions[name] = value;
                return newState;
            });
        }
    };

    const handleSelectChange = (item: { key: string; value: string }, name: string) => {
        setTargetBehavior((prevState: any) => {
            const newState = { ...prevState };
            newState.thirdPersonOptions[name] = item.value;
            return newState;
        });

    };

    const handleUpdate = () => {
        if (!model) return;
        const characterBehaviorConverter = CharacterBehaviorConverter.DEFAULT;

        if (!camera.userData.thirdPersonOptions || Object.keys(camera.userData.thirdPersonOptions).length === 0) {
            camera.userData.thirdPersonOptions =
                characterBehaviorConverter.getDefaultThirdPersonOptions(getSceneModels());
        }

        characterBehaviorConverter.updateThirdPersonOptions(model, camera);

        if (model) {
            if (model._obj && model._obj.animations && model._obj.animations.length > 0) {
                const mappedAnimations = model._obj.animations.map((anim: any) => {
                    return { key: `${anim.name}`, value: anim.name };
                });
                setAnimations([{ key: "none", value: "none" }, ...mappedAnimations]);
            }
        }
    };

    const updateScene = () => {
        if (camera && targetBehavior && thisModelCharacterBehavior.enabled) {
            camera.userData.thirdPersonOptions = targetBehavior.thirdPersonOptions;

            app.call(`objectChanged`, app.editor, app.editor.selected);
            app.call(`objectUpdated`, app.editor, app.editor.selected);
        }
    };


    //TODO future AI assistant
    const askQuestion = async (question: string) => {
        const apiKey = '';  //enter the api key for Open AI sk-!@#$%@##$

        if (!question) {
            console.log('No question provided.');
            return;
        }

        const requestBody = {
            model: 'gpt-3.5-turbo',
            messages: [
                { role: 'system', content: 'You are a helpful assistant.' },
                { role: 'user', content: question }
            ],
            max_tokens: 1000, 
            temperature: 0.7 
        };

        try {
            const response = await fetch('https://api.openai.com/v1/chat/completions', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${apiKey}`
                },
                body: JSON.stringify(requestBody)
            });

            if (!response.ok) {
                throw new Error('Request failed with status ' + response.status);
            }

            const data = await response.json();
            const answer = data.choices[0].message.content; 

            const stripHtml = (htmlString: any) => {
                return htmlString.replace(/<[^>]*>/g, '');
            };

            const strippedAnswer = stripHtml(answer);
            console.log('ChatGPT says:', strippedAnswer);

            const keywords: string[] = strippedAnswer
                .split('\n')
                .map((line: string) => line.trim())
                .filter((line: string) => line.toLowerCase().includes("animation name:"))
                .map((line: string) => {
                    const parts: string[] = line.split("animation name:");
                    return parts[1]?.trim() || "";
                })
                .filter((keyword: string) => keyword.length > 0);

            keywords.forEach((keyword: string) => {
                console.log(keyword);
                autoDetectAnimations();
            });

            setAnswer(answer);  
        } catch (error) {
            console.error('Error:', error);
            setAnswer('Sorry, something went wrong.');  
        } finally {
            setLoading(false); 
        }
    };

    const handleAskQuestion = async () => {
        if (!question) return; 
        setLoading(true);
        await askQuestion(question); 
    };


    //TODO this is being tested for AI response to suggest animations
    //it is not finished but trains the Chatgpt to suggest animations to use for the character
    const AiSuggestAnimoitions = async (weaponType: string) => {
        setLoading(true);

        const thirdPersonOptions = camera.userData.thirdPersonOptions;
        const animationProperties: string[] = [];
        Object.keys(thirdPersonOptions).forEach((key) => {
            if (key.includes('Animation')) {
                animationProperties.push(key);
            }
        });

        const animationList = animationProperties.join(', ');

        const availableAnimations = model._obj.animations.map((anim: any) => anim.name).join(', ');
        console.log("Available Animations for ChatGPT:", availableAnimations);

        const question = `What are the best animations for this character's existing animation clips if it was holding 
        this weapon type: ${weaponType}? Here is the list of available animations from the model: ${availableAnimations}.
        Here is the list of animation properties to set from the model: ${availableAnimations}.
        Can you return the recommended animation names as a numbered list with a short sentence for each? Make sure the next clip starts on a new line with a line break like this <br>
        Send the response as HTML, and don't say "sure" or "certainly." Start the response with "Recommend animation clips to use from your model with a ${weaponType} new line in response.
        Make sure to suggest each animation name from ${availableAnimations} that has ${weaponType}.
        Sometimes the list is not returned as a numbered list with <br> after each animation name please make sure add numbered items.
        Please make sure all ${availableAnimations} are returned in the response if a weapon from this list ${weaponType} is found in the animation name
        Make sure that each one of the animation objects below have recommended values to be set from the animations list
        ${animationList} remove any syntax at start and end that is not human readable
        left, right and reverse directions animations should be the same as the walkAnimation
        response should read similar to this below
        Response template start
        
        That looks better but it not returning all of the available animation clip names on seperate lines
        Example please make each link look like this each 
       
        The returned response must be formated like this always like below example
        
        <div>1. idleAnimaton (idle): animation name: rifle_idle<br></div>
        <div>2. walkAnimaton (walk): animation name: rifle_walk<br></div>
    
        Please make human readable if none is selected do not add that to the response
        there should be separate line for each item in the ${animationList} 
        remove any syntax from start and end of response that is not readable

         `;

        const suggestedAnimations = await askQuestion(question);
    
        setLoading(false);
    };


    const autoDetectAnimations = () => {
        if (model && model._obj && model._obj.animations && model._obj.animations.length > 0) {
            const mappedAnimations = model._obj.animations.map((anim: any) => ({
                key: `${anim.name}`,
                value: anim.name
            }));
            setAnimations([{ key: "none", value: "none" }, ...mappedAnimations]);

            let weaponAnimationPrefix = ""
            editor.scene!.traverse((object: THREE.Object3D) => {
                if (object.userData && object.userData.behaviors) {
                    const weaponBehavior = object.userData.behaviors.find(
                        (behavior: any) => behavior.type === OBJECT_TYPES.WEAPON,
                    );
                    if (weaponBehavior && weaponBehavior.weaponStarting) {
                        switch (weaponBehavior.weaponType) {
                            case WEAPON_TYPES.SNIPER_RIFLE:
                                weaponAnimationPrefix = WEAPON_TYPES.RIFLE;
                                break;
                            case WEAPON_TYPES.SCIFI_SNIPER_RIFLE:
                                weaponAnimationPrefix = WEAPON_TYPES.RIFLE;
                                break;
                            case WEAPON_TYPES.RIFLE:
                                weaponAnimationPrefix = WEAPON_TYPES.RIFLE;
                                break;
                            case WEAPON_TYPES.MACHINE_GUN:
                                weaponAnimationPrefix = WEAPON_TYPES.RIFLE;
                                break;
                            case WEAPON_TYPES.SUB_MACHINE_GUN:
                                weaponAnimationPrefix = WEAPON_TYPES.RIFLE;
                                break;
                            case WEAPON_TYPES.SHOT_GUN:
                                weaponAnimationPrefix = WEAPON_TYPES.RIFLE;
                                break;
                        }
                    }
                }
            });


            const findAnimationByKeyword = (keywords: string[], excludeKeywords: string[] = []) => {
                const cleanString = (str: string) => str.toLowerCase().replace(/[^a-z0-9]/g, "");
                const prefix = cleanString(weaponAnimationPrefix);

                for (const keyword of keywords) {
                    const cleanKeyword = cleanString(keyword);

                    const animation = mappedAnimations.find((animation: AnimationOption) => {
                        const cleanValue = cleanString(animation.value);

                        const containsKeyword = cleanValue.includes(cleanKeyword);
                        const containsPrefix = prefix === "" || cleanValue.includes(prefix);

                        const excludesUnwanted = excludeKeywords.every((exclude) => {
                            const cleanExclude = cleanString(exclude);
                            const isExcluded = cleanValue.includes(cleanExclude);
                            return !isExcluded;
                        });

                        return containsKeyword && containsPrefix && excludesUnwanted;
                    });

                    if (animation) {
                        return animation.value;
                    }
                }
                return "none";
            };


            setTargetBehavior((prevState) => ({
                ...prevState,
                thirdPersonOptions: {
                    ...prevState.thirdPersonOptions,
                    idleAnimation: findAnimationByKeyword(
                        ANIMATION_ACTION_TYPES.IDLE.INCLUDES,
                        ANIMATION_ACTION_TYPES.IDLE.EXCLUDES
                    ),
                    walkAnimation: findAnimationByKeyword(
                        ANIMATION_ACTION_TYPES.WALK.INCLUDES,
                        ANIMATION_ACTION_TYPES.WALK.EXCLUDES
                    ),
                    runAnimation: findAnimationByKeyword(
                        ANIMATION_ACTION_TYPES.RUN.INCLUDES,
                        ANIMATION_ACTION_TYPES.JOG.EXCLUDES
                    ),
                    jumpAnimation: findAnimationByKeyword(
                        ANIMATION_ACTION_TYPES.JUMP.INCLUDES,
                        ANIMATION_ACTION_TYPES.RUN.EXCLUDES
                    ),
                    shootIdleAnimation: findAnimationByKeyword(
                        ANIMATION_ACTION_TYPES.SHOOT_IDLE.INCLUDES,
                        ANIMATION_ACTION_TYPES.SHOOT_IDLE.EXCLUDES
                    ),
                    shootWalkAnimation: findAnimationByKeyword(
                        ANIMATION_ACTION_TYPES.SHOOT_WALK.INCLUDES,
                        ANIMATION_ACTION_TYPES.SHOOT_WALK.EXCLUDES
                    ),
                    shootRunAnimation: findAnimationByKeyword(
                        ANIMATION_ACTION_TYPES.SHOOT_RUN.INCLUDES,
                        ANIMATION_ACTION_TYPES.SHOOT_RUN.EXCLUDES
                    ),
                    reloadIdleAnimation: findAnimationByKeyword(
                        ANIMATION_ACTION_TYPES.RELOAD_IDLE.INCLUDES,
                        ANIMATION_ACTION_TYPES.RELOAD_IDLE.EXCLUDES
                    ),
                    reloadWalkAnimation: findAnimationByKeyword(
                        ANIMATION_ACTION_TYPES.RELOAD_WALK.INCLUDES,
                        ANIMATION_ACTION_TYPES.RELOAD_WALK.EXCLUDES
                    ),
                    reloadRunAnimation: findAnimationByKeyword(
                        ANIMATION_ACTION_TYPES.RELOAD_RUN.INCLUDES,
                        ANIMATION_ACTION_TYPES.RELOAD_RUN.EXCLUDES
                    ),
                    swordAnimation: findAnimationByKeyword(
                        ANIMATION_ACTION_TYPES.SWORD.INCLUDES,
                        ANIMATION_ACTION_TYPES.SWORD.EXCLUDES
                    ),
                    punchAnimation: findAnimationByKeyword(
                        ANIMATION_ACTION_TYPES.PUNCH.INCLUDES,
                        ANIMATION_ACTION_TYPES.PUNCH.EXCLUDES
                    ),
                    kickAnimation: findAnimationByKeyword(
                        ANIMATION_ACTION_TYPES.KICK.INCLUDES,
                        ANIMATION_ACTION_TYPES.KICK.EXCLUDES
                    ),
                    throwAnimation: findAnimationByKeyword(
                        ANIMATION_ACTION_TYPES.THROW.INCLUDES,
                        ANIMATION_ACTION_TYPES.THROW.EXCLUDES
                    ),
                    pickUpAnimation: findAnimationByKeyword(
                        ANIMATION_ACTION_TYPES.PICK_UP.INCLUDES,
                        ANIMATION_ACTION_TYPES.PICK_UP.EXCLUDES
                    ),
                    carryAnimation: findAnimationByKeyword(
                        ANIMATION_ACTION_TYPES.CARRY.INCLUDES,
                        ANIMATION_ACTION_TYPES.CARRY.EXCLUDES
                    ),
                    leftDirectionAnimation: findAnimationByKeyword(
                        ANIMATION_ACTION_TYPES.LEFT_DIRECTION.INCLUDES,
                        ANIMATION_ACTION_TYPES.LEFT_DIRECTION.EXCLUDES
                    ),
                    rightDirectionAnimation: findAnimationByKeyword(
                        ANIMATION_ACTION_TYPES.RIGHT_DIRECTION.INCLUDES,
                        ANIMATION_ACTION_TYPES.RIGHT_DIRECTION.EXCLUDES
                    ),
                    reverseDirectionAnimation: findAnimationByKeyword(
                        ANIMATION_ACTION_TYPES.REVERSE_DIRECTION.INCLUDES,
                        ANIMATION_ACTION_TYPES.REVERSE_DIRECTION.EXCLUDES
                    ),
                    fallAnimation: findAnimationByKeyword(
                        ANIMATION_ACTION_TYPES.FALL.INCLUDES,
                        ANIMATION_ACTION_TYPES.FALL.EXCLUDES
                    ),
                    dieAnimation: findAnimationByKeyword(
                        ANIMATION_ACTION_TYPES.DIE.INCLUDES,
                        ANIMATION_ACTION_TYPES.DIE.EXCLUDES
                    ),
                    pushAnimation: findAnimationByKeyword(
                        ANIMATION_ACTION_TYPES.PUSH.INCLUDES,
                        ANIMATION_ACTION_TYPES.PUSH.EXCLUDES
                    ),
                },
            }));
        }
    };


 
    useEffect(() => {
        updateScene();
        if (!!thisModelCharacterBehavior) {
            thisModelCharacterBehavior.thirdPersonOptions = targetBehavior.thirdPersonOptions;
        }
    }, [targetBehavior]);

    useEffect(() => {
        handleUpdate();
        app.on(`objectSelected.thirdPersonnOptions`, handleUpdate);
        app.on(`objectChanged.thirdPersonOptions`, handleUpdate);
    }, []);

    useEffect(() => {
        if (model) {

            const boundingBox = new THREE.Box3().setFromObject(model);
            const modelHeight = boundingBox.max.y - boundingBox.min.y;


            if (!model.userData.autoDetectAnimations) {
                model.userData.autoDetectAnimations = false;
                autoDetectAnimations();
                //AiSuggestAnimoitions("none");
                model.userData.autoDetectAnimations = true;
            }

            if (!model.userData.playerJumpHeightSet) {
                const jumpHeight = Math.round((modelHeight * 2) * 1000) / 1000;

                setTargetBehavior((prevState) => ({
                    ...prevState,
                    thirdPersonOptions: {
                        ...prevState.thirdPersonOptions,
                        jumpHeight: jumpHeight,
                    },
                }));

                model.userData.playerJumpHeightSet = true;
            }

            if (!model.userData.characterDistanceSet) {
                const minDistance = Math.round((modelHeight * 1.5) * 1000) / 1000;
                const maxDistance = Math.round((modelHeight * 2) * 1000) / 1000;

                setTargetBehavior((prevState) => ({
                    ...prevState,
                    thirdPersonOptions: {
                        ...prevState.thirdPersonOptions,
                        cameraMINDistance: minDistance,
                        cameraMAXDistance: maxDistance,
                        cameraFov: 50,
                    },
                }));

                model.userData.characterDistanceSet = true;
            }
        }
    }, [model]);


    return (

        <DefaultWrapper>
            Movement Speed
            <NumericInputRow
                label="Walk Speed"
                value={targetBehavior.thirdPersonOptions.walkSpeed}
                setValue={value => handleInputChange(value, "walkSpeed")}
                min={0}
            />
            <NumericInputRow
                label="Run Speed"
                value={targetBehavior.thirdPersonOptions.runSpeed}
                setValue={value => handleInputChange(value, "runSpeed")}
                min={0}
            />
            <NumericInputRow
                label="Turn Speed"
                value={targetBehavior.thirdPersonOptions.turnSpeed}
                setValue={value => handleInputChange(value, "turnSpeed")}
                min={0}
            />
            <SelectRow
                label="Initial X Rotation"
                data={initialXRotations}
                value={initialXRotations.find(
                    rotation => rotation.key === targetBehavior.thirdPersonOptions.initialXRotation,
                )}
                onChange={item => handleInputChange(item.key, "initialXRotation")}
            />
            <DefaultWrapper>
            
               {/* //For future AI assistanct*/}
                {/*<DefaultWrapper>*/}
                {/*    <DefaultWrapper>*/}
                {/*        <div>*/}
                {/*            <input*/}
                {/*                type="text"*/}
                {/*                placeholder="Ask OpenAI something..."*/}
                {/*                value={question}*/}
                {/*                onChange={(e) => setQuestion(e.target.value)}*/}
                {/*            />*/}
                {/*            <button onClick={handleAskQuestion} disabled={loading}>Ask</button>*/}
                {/*            {loading ? (*/}
                {/*                <p className="small-font">Loading...</p>*/}
                {/*            ) : (*/}
                {/*                answer && (*/}
                {/*                    <div>*/}
                {/*                        <p className="small-font">AI Assistant:</p>*/}
                {/*                        <div className="small-font" dangerouslySetInnerHTML={{ __html: answer }} />*/}
                {/*                    </div>*/}
                {/*                )*/}
                {/*            )}*/}
                {/*        </div>*/}
                {/*    </DefaultWrapper>*/}
                {/*</DefaultWrapper>*/}

                Animation Clip Control
                <SelectRow
                    label="Idle"
                    data={animations}
                    value={animations.find(
                        animation => animation.value === targetBehavior.thirdPersonOptions.idleAnimation,
                    )}
                    onChange={value => handleSelectChange(value, "idleAnimation")}
                />
                <SelectRow
                    label="Walk"
                    data={animations}
                    value={animations.find(
                        animation => animation.value === targetBehavior.thirdPersonOptions.walkAnimation,
                    )}
                    onChange={value => handleSelectChange(value, "walkAnimation")}
                />
                <SelectRow
                    label="Run"
                    data={animations}
                    value={animations.find(
                        animation => animation.value === targetBehavior.thirdPersonOptions.runAnimation,
                    )}
                    onChange={value => handleSelectChange(value, "runAnimation")}
                />
                <SelectRow
                    label="Shoot Stand"
                    data={animations}
                    value={animations.find(
                        animation => animation.value === targetBehavior.thirdPersonOptions.shootStandAnimation,
                    )}
                    onChange={value => handleSelectChange(value, "shootStandAnimation")}
                />
                <SelectRow
                    label="Shoot Walk"
                    data={animations}
                    value={animations.find(
                        animation => animation.value === targetBehavior.thirdPersonOptions.shootWalkAnimation,
                    )}
                    onChange={value => handleSelectChange(value, "shootWalkAnimation")}
                />
                <SelectRow
                    label="Shoot Run"
                    data={animations}
                    value={animations.find(
                        animation => animation.value === targetBehavior.thirdPersonOptions.shootRunAnimation,
                    )}
                    onChange={value => handleSelectChange(value, "shootRunAnimation")}
                />
                <SelectRow
                    label="Reload Stand"
                    data={animations}
                    value={animations.find(
                        animation => animation.value === targetBehavior.thirdPersonOptions.reloadStandAnimation,
                    )}
                    onChange={value => handleSelectChange(value, "reloadStandAnimation")}
                />
                <SelectRow
                    label="Reload Walk"
                    data={animations}
                    value={animations.find(
                        animation => animation.value === targetBehavior.thirdPersonOptions.reloadWalkAnimation,
                    )}
                    onChange={value => handleSelectChange(value, "reloadWalkAnimation")}
                />
                <SelectRow
                    label="Reload Run"
                    data={animations}
                    value={animations.find(
                        animation => animation.value === targetBehavior.thirdPersonOptions.reloadRunAnimation,
                    )}
                    onChange={value => handleSelectChange(value, "reloadRunAnimation")}
                />
                <SelectRow
                    label="Sword"
                    data={animations}
                    value={animations.find(
                        animation => animation.value === targetBehavior.thirdPersonOptions.swordAnimation,
                    )}
                    onChange={value => handleSelectChange(value, "swordAnimation")}
                />
                <SelectRow
                    label="Punch"
                    data={animations}
                    value={animations.find(
                        animation => animation.value === targetBehavior.thirdPersonOptions.punchAnimation,
                    )}
                    onChange={value => handleSelectChange(value, "punchAnimation")}
                />
                <SelectRow
                    label="Kick"
                    data={animations}
                    value={animations.find(
                        animation => animation.value === targetBehavior.thirdPersonOptions.kickAnimation,
                    )}
                    onChange={value => handleSelectChange(value, "kickAnimation")}
                />
                <SelectRow
                    label="Throw"
                    data={animations}
                    value={animations.find(
                        animation => animation.value === targetBehavior.thirdPersonOptions.throwAnimation,
                    )}
                    onChange={value => handleSelectChange(value, "throwAnimation")}
                />
                <SelectRow
                    label="Pick Up"
                    data={animations}
                    value={animations.find(
                        animation => animation.value === targetBehavior.thirdPersonOptions.pickUpAnimation,
                    )}
                    onChange={value => handleSelectChange(value, "pickUpAnimation")}
                />
                <SelectRow
                    label="Carry"
                    data={animations}
                    value={animations.find(
                        animation => animation.value === targetBehavior.thirdPersonOptions.carryAnimation,
                    )}
                    onChange={value => handleSelectChange(value, "carryAnimation")}
                />
                <SelectRow
                    label="Push"
                    data={animations}
                    value={animations.find(
                        animation => animation.value === targetBehavior.thirdPersonOptions.pushAnimation,
                    )}
                    onChange={value => handleSelectChange(value, "pushAnimation")}
                />
                <SelectRow
                    label="Jump"
                    data={animations}
                    value={animations.find(
                        animation => animation.value === targetBehavior.thirdPersonOptions.jumpAnimation,
                    )}
                    onChange={value => handleSelectChange(value, "jumpAnimation")}
                />
                <SelectRow
                    label="Left"
                    data={animations}
                    value={animations.find(
                        animation => animation.value === targetBehavior.thirdPersonOptions.leftDirectionAnimation,
                    )}
                    onChange={value => handleSelectChange(value, "leftDirectionAnimation")}
                />
                <SelectRow
                    label="Right"
                    data={animations}
                    value={animations.find(
                        animation => animation.value === targetBehavior.thirdPersonOptions.rightDirectionAnimation,
                    )}
                    onChange={value => handleSelectChange(value, "rightDirectionAnimation")}
                />
                <SelectRow
                    label="Reverse"
                    data={animations}
                    value={animations.find(
                        animation => animation.value === targetBehavior.thirdPersonOptions.reverseDirectionAnimation,
                    )}
                    onChange={value => handleSelectChange(value, "reverseDirectionAnimation")}
                />
                <SelectRow
                    label="Fall"
                    data={animations}
                    value={animations.find(
                        animation => animation.value === targetBehavior.thirdPersonOptions.fallAnimation,
                    )}
                    onChange={value => handleSelectChange(value, "fallAnimation")}
                />
                <NumericInputRow
                    label="Fall Delay (Seconds)"
                    value={targetBehavior.thirdPersonOptions.fallDelay}
                    setValue={value => handleInputChange(value, "fallDelay")}
                />
                <SelectRow
                    label="Die"
                    data={animations}
                    value={animations.find(
                        animation => animation.value === targetBehavior.thirdPersonOptions.dieAnimation,
                    )}
                    onChange={value => handleSelectChange(value, "dieAnimation")}
                />
                <NumericInputRow
                    label="Slope Tolerance"
                    value={targetBehavior.thirdPersonOptions.slopeTolerance}
                    setValue={value => handleInputChange(value, "slopeTolerance")}
                />
            </DefaultWrapper>
            <DefaultWrapper>
                Character Physics
                <NumericInputRow
                    label="Jump Height"
                    value={targetBehavior.thirdPersonOptions.jumpHeight}
                    setValue={value => handleInputChange(value, "jumpHeight")}
                    min={0}
                />
                <NumericInputRow
                    label="Player Gravity"
                    value={targetBehavior.thirdPersonOptions.playerGravity}
                    setValue={value => handleInputChange(value, "playerGravity")}
                />
            </DefaultWrapper>
            <DefaultWrapper>
                Camera Interaction
                <NumericInputRow
                    label="Camera Min Distance"
                    value={targetBehavior.thirdPersonOptions.cameraMINDistance}
                    setValue={value => handleInputChange(value, "cameraMINDistance")}
                />
                <NumericInputRow
                    label="Camera Max Distance"
                    value={targetBehavior.thirdPersonOptions.cameraMAXDistance}
                    setValue={value => handleInputChange(value, "cameraMAXDistance")}
                />
                <NumericInputRow
                    label="Camera Fov"
                    value={targetBehavior.thirdPersonOptions.cameraFov}
                    setValue={value => handleInputChange(value, "cameraFov")}
                />
            </DefaultWrapper>
        </DefaultWrapper>
    );
};