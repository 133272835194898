/*
 * Copyright 2017-2020 The ShadowEditor Authors. All rights reserved.
 *
 * Use of this source code is governed by a MIT-style
 * license that can be found in the LICENSE file.
 *
 * For more information, please visit: https://github.com/tengge1/ShadowEditor
 * You can also visit: https://gitee.com/tengge1/ShadowEditor
 */
import BaseSerializer from "../../BaseSerializer";
import LightShadowSerializer from "./LightShadowSerializer";
import * as THREE from "three";
import BaseLightShadowSerializer from "./BaseLightShadowSerializer";

/**
 * SpotLightShadowSerializer
 *
 */
class SpotLightShadowSerializer extends BaseLightShadowSerializer {
    toJSON(obj) {
        var json = LightShadowSerializer.prototype.toJSON.call(this, obj);

        json.isSpotLightShadow = obj.isSpotLightShadow;

        return json;
    }
}

export default SpotLightShadowSerializer;
