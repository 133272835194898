import {toast} from "react-toastify";
import {BILLBOARD_TYPES} from "../types/editor";
import * as THREE from "three";
import {THREE_GetGifTexture} from "threejs-gif-texture";
import WebElement from "./WebElement";

class Billboard {
    public mesh: THREE.Mesh | THREE.Sprite | THREE.Group | null = null;
    public texture: THREE.Texture | null = null;
    private billboardMode: BILLBOARD_TYPES | null = null;
    private file: string = "";
    private url: string = "";
    private width: number = 0;
    private height: number = 0;
    private faceCamera: boolean = false;
    private type: "image" | "gif" | "video" | "unknown" = "image";
    private loop: boolean = false;
    private twoSided: boolean = false;
    private transparent: boolean = false;
    private color: string = "#ffffff";

    constructor() {}

    create = async (
        billboardMode: BILLBOARD_TYPES,
        file: string,
        url: string,
        width: number,
        height: number,
        faceCamera: boolean,
        type: "image" | "gif" | "video" | "unknown",
        loop: boolean,
        twoSided: boolean,
        transparent: boolean,
        color: string,
    ) => {
        this.billboardMode = billboardMode;
        this.file = file;
        this.url = url;
        this.width = width;
        this.height = height;
        this.faceCamera = faceCamera;
        this.type = type;
        this.loop = billboardMode === BILLBOARD_TYPES.WEB ? false : loop;
        this.twoSided = twoSided;
        this.transparent = transparent;
        this.color = color;

        try {
            this.mesh = await this.createBillboard();
        } catch (error) {
            console.error(error);
        }
    };

    private async createBillboard(): Promise<THREE.Mesh | THREE.Sprite | THREE.Group | null> {
        switch (this.billboardMode) {
            case BILLBOARD_TYPES.IMAGE:
                switch (this.type) {
                    case "image":
                        return this.createImageBillboard();
                    case "gif":
                        return this.createAnimatedBillboard();
                    default:
                        return this.createImageBillboard();
                }
            case BILLBOARD_TYPES.WEB:
                return this.createWebBillboard();
            case BILLBOARD_TYPES.YT_VIDEO:
                return this.createWebBillboard();
            default:
                throw new Error("Unsupported billboard mode");
        }
    }

    private createFaceCameraSprite(): THREE.Sprite {
        const material = new THREE.SpriteMaterial({map: this.texture});
        const sprite = new THREE.Sprite(material);

        sprite.scale.set(this.width, this.height, 1);
        return sprite;
    }

    private createFaceCameraSpriteFromColor(color: string): THREE.Sprite {
        const material = new THREE.SpriteMaterial({color: color});
        const sprite = new THREE.Sprite(material);

        sprite.scale.set(this.width, this.height, 1);
        return sprite;
    }

    private createMeshFromColor(color: string): THREE.Mesh {
        const material = new THREE.MeshBasicMaterial({
            color: new THREE.Color(color),
            side: this.twoSided ? THREE.DoubleSide : THREE.FrontSide,
            transparent: this.transparent,
        });
        const geometry = new THREE.BoxGeometry(this.width, this.height, 0.001);
        return new THREE.Mesh(geometry, material);
    }

    private createMeshFromTexture(): THREE.Mesh {
        const isArray = !this.transparent && !this.twoSided;

        let materialArray: THREE.Material[] = [];
        const material = new THREE.MeshBasicMaterial({
            map: this.texture,
            side: this.twoSided ? THREE.DoubleSide : THREE.FrontSide,
            transparent: this.transparent,
            color: 0xffffff,
        });

        if (isArray) {
            const colorMaterial = new THREE.MeshBasicMaterial({color: "lightgray"});
            materialArray = [
                colorMaterial.clone(),
                colorMaterial.clone(),
                colorMaterial.clone(),
                colorMaterial.clone(),
                material.clone(),
                colorMaterial.clone(),
            ];
        }
        const geometry = new THREE.BoxGeometry(this.width, this.height, 0.001);
        return new THREE.Mesh(geometry, !isArray ? material : materialArray);
    }

    private createSimpleBillboard(): THREE.Mesh | THREE.Sprite {
        /*if (this.faceCamera) {
            return this.createFaceCameraSpriteFromColor(this.color);
        }*/

        return this.createMeshFromColor(this.color);
    }

    private createImageBillboard(): THREE.Mesh | THREE.Sprite {
        if (!this.file) {
            return this.createSimpleBillboard();
        }

        this.texture = new THREE.TextureLoader().load(this.file);

        if (!this.texture) {
            toast.error("Failed to load texture");
            return this.createSimpleBillboard();
        }

        /*if (this.faceCamera) {
            return this.createFaceCameraSprite();
        }*/

        return this.createMeshFromTexture();
    }

    private async createAnimatedBillboard(): Promise<THREE.Mesh | THREE.Sprite | null> {
        if (!this.file) {
            return this.createSimpleBillboard();
        }
        try {
            this.texture = await THREE_GetGifTexture(this.file);

            if (!this.texture) {
                toast.error("Failed to load texture");
                return this.createSimpleBillboard();
            }

            /*if (this.faceCamera) {
                return this.createFaceCameraSprite();
            }*/

            const plane = this.createMeshFromTexture();
            plane.onBeforeRender = () => {
                if (this.texture) {
                    this.texture.needsUpdate = true;
                }
            };
            return plane;
        } catch (error) {
            toast.error("Failed to load texture");
            console.error(error);
            return null;
        }
    }

    private createVideoBillboard(): THREE.Mesh | THREE.Sprite {
        const video = document.createElement("video");
        video.src = this.file;
        video.loop = this.loop;
        video.muted = true;
        video.play();

        this.texture = new THREE.VideoTexture(video);

        if (!this.texture) {
            toast.error("Failed to load texture");
            return this.createSimpleBillboard();
        }

        this.texture.minFilter = THREE.LinearFilter;
        this.texture.magFilter = THREE.LinearFilter;
        this.texture.format = THREE.RGBAFormat;

        if (this.faceCamera) {
            return this.createFaceCameraSprite();
        }

        return this.createMeshFromTexture();
    }

    private createWebBillboard(): THREE.Group | THREE.Mesh | THREE.Sprite {
        if (!this.url) {
            return this.createSimpleBillboard();
        }
        return new WebElement(this.url, false, this.loop, 1270, 720, this.color, "10px").object!;
    }
}

export default Billboard;
